const GoogleMapPlugin = {
  install (Vue) {
    let googlemap = {
      isLoaded: undefined,
      google: null,
      loadScript: null
    }

    googlemap.loadScript = function () {
      return new Promise((resolve, reject) => {
        if (googlemap.isLoaded === undefined) {
          googlemap.isLoaded = false
          let script = document.createElement('script')
          script.src = `https://maps.googleapis.com/maps/api/js?language=en&libraries=places&key=${Vue.env.googleMapAPIKey}`
          script.onload = function () {
            googlemap.isLoaded = true
            googlemap.google = window.google
            resolve()
          }
          script.onerror = function () {
            googlemap.isLoaded = false
            reject(new Error(false))
            // console.error('Error loading: ' + this.src)
          }
          document.head.appendChild(script)
        }
      })
    }

    Vue.prototype.$googlemap = googlemap
    Vue.googlemap = googlemap
  }
}

export default GoogleMapPlugin
