import Vue from 'vue'
import {
  RESET_STATE,
  INIT_ALL_SUPPORT_VERSION,
} from '@/store/mutation-types'

let initialState = { 
  supportWebrtc: false,
  supportNewScreenOrientation: false,
  supportNetwork: false,
  supportClipboard: false,
  supportVoiceRecorder: false,
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  supportWebrtc: function (state) {
    return state.supportWebrtc
  },
  supportNewScreenOrientation: function (state) {
    return state.supportNewScreenOrientation
  },
  supportNetwork: function (state) {
    return state.supportNetwork
  },
  supportClipboard: function (state) {
    return state.supportClipboard
  },
  supportVoiceRecorder: function (state) {
    return state.supportVoiceRecorder
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [INIT_ALL_SUPPORT_VERSION]: function (state) {
    try {
      // APP 버전 1.5.3 이후 버전에서 동작하는 기능들... !VERSION
      if (Vue.tool.isAppVersionHigherThan("1.5.3")) {
        state.supportWebrtc = true
        state.supportNewScreenOrientation = true
        state.supportNetwork = true
        state.supportClipboard = true
        state.supportVoiceRecorder = true
      }
    }
    catch (err) {console.log(err)}
  },

}

export default {
  state,
  getters,
  actions,
  mutations
}