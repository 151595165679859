import Vue from 'vue'
import api from '@/apis/apiUser'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_MYPROFILE_REQUEST,
  GET_MYPROFILE_SUCCESS,
  GET_MYPROFILE_FAILURE,
  UPDATE_MYPROFILE_REQUEST,
  UPDATE_MYPROFILE_SUCCESS,
  UPDATE_MYPROFILE_FAILURE,
  SEND_EMAILCHANGE_EMAIL_REQUEST,
  SEND_EMAILCHANGE_EMAIL_SUCCESS,
  SEND_EMAILCHANGE_EMAIL_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  myProfile: {
    userId: null,
    mqttAccessToken: null,
    email: null,
    firstName: null,
    lastName: null,
    created: null,
    isEmailVerified: null,
    permissions: null,
    permissionSummary: null,
    profilePictureUrl: null,
    isAdmin: null,
  },
  status: {
    getMyProfile: null,
    updateMyProfile: null,
    sendEmailChangeEmail: null
  }
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  myName: function (state) {
    return state.myProfile.firstName ? state.myProfile.firstName + ' ' + state.myProfile.lastName : null
  },
  firstName: function (state) {
    return state.myProfile.firstName
  },
  lastName: function (state) {
    return state.myProfile.lastName
  },
  isValidAccount: function (state) {
    return state.myProfile.isEmailVerified
  },
  myMqttAccessToken: function (state) {
    return state.myProfile.mqttAccessToken
  },
  amICHeKTAdmin: function (state) {
    return state.myProfile.isAdmin
  },
  myEmail: function (state) {
    return state.myProfile.email
  },
  myUserId: function (state) {
    return state.myProfile.userId
  },
  myPictureUrl: function (state) {
    return state.myProfile.profilePictureUrl
  },
  statusGetMyProfile: function (state) {
    return state.status.getMyProfile
  },
  statusUpdateMyProfile: function (state) {
    return state.status.updateMyProfile
  },
  statusSendEmailChangeEmail: function () {
    return state.status.sendEmailChangeEmail
  }
}

// actions
const actions = {
  getMyProfile: function ({commit}) {
    commit(GET_MYPROFILE_REQUEST)
    return new Promise((resolve, reject) => {
      api.getMyProfile().then(
        res => {
          commit(GET_MYPROFILE_SUCCESS, res.body[0])
          commit(PROCESS_API_SUCCESS)
          resolve()
        },
        err => {
          commit(GET_MYPROFILE_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  updateMyProfile: function ({commit}, {firstName, lastName}) {
    commit(UPDATE_MYPROFILE_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateMyProfile({firstName, lastName}).then(
        () => {
          commit(UPDATE_MYPROFILE_SUCCESS)
          resolve()
        },
        err => {
          commit(UPDATE_MYPROFILE_FAILURE)
          reject(new Error(err.statusText))
        }
      )
    })
  },
  changeMyEmail: function ({commit}, {email}) {
    commit(SEND_EMAILCHANGE_EMAIL_REQUEST)
    return new Promise((resolve, reject) => {
      api.changeMyEmail({email}).then(
        res => {
          commit(SEND_EMAILCHANGE_EMAIL_SUCCESS)
          // console.log(res)
          resolve(res.body.message)
        },
        err => {
          commit(SEND_EMAILCHANGE_EMAIL_FAILURE)
          // console.log(err)
          reject(new Error(err.body.message))
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_MYPROFILE_REQUEST]: function (state) {
    state.status = {...state.status, getMyProfile: 'requested'}
  },
  [GET_MYPROFILE_SUCCESS]: function (state, data) {
    try {
      let profileInfo = {
        userId: data.user_id,
        mqttAccessToken: data.access_token,
        email: data.email,
        firstName: data.firstname,
        lastName: data.lastname,
        created: data.created,
        isEmailVerified: data.is_verify,
        profilePictureUrl: data.provider_picture,
        isAdmin: data.role === 'admin'
      }
      state.myProfile = profileInfo
      state.status = {...state.status, getMyProfile: 'successful'}
      // Vue.logger.debug(profileInfo)
    } catch (err) {
      let statusText = err.message
      Vue.logger.error(statusText)
    }
  },
  [GET_MYPROFILE_FAILURE]: function (state) {
    state.status = {...state.status, getMyProfile: 'failed'}
  },
  [UPDATE_MYPROFILE_REQUEST]: function (state) {
    state.status = {...state.status, 'updateMyProfile': 'requested'}
  },
  [UPDATE_MYPROFILE_SUCCESS]: function (state) {
    state.status = {...state.status, 'updateMyProfile': 'successful'}
  },
  [UPDATE_MYPROFILE_FAILURE]: function (state) {
    state.status = {...state.status, 'updateMyProfile': 'failed'}
  },
  [SEND_EMAILCHANGE_EMAIL_REQUEST]: function (state) {
    state.status = {...state.status, 'sendEmailChangeEmail': 'requested'}
  },
  [SEND_EMAILCHANGE_EMAIL_SUCCESS]: function (state) {
    state.status = {...state.status, 'sendEmailChangeEmail': 'successful'}
  },
  [SEND_EMAILCHANGE_EMAIL_FAILURE]: function (state) {
    state.status = {...state.status, 'sendEmailChangeEmail': 'failed'}
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
