const LoggerPlugin = {
  install (Vue) {
    let logs = {
      'debug': [],
      'info': [],
      'warn': [],
      'error': []
    }
    let logger = {
      logs: logs,
      _addLog: function (level, log, queueSize) {
        Vue.logger.logs[level].unshift({
          time: (new Date()).toLocaleString(),
          log: log
        })
        if (Vue.logger.logs[level].length > queueSize) {
          Vue.logger.logs[level].pop()
        }
      },
      debug: function (log) {
        Vue.logger._addLog('debug', log, 100)
        console.log(log)
      },
      info: function (log) {
        Vue.logger.debug(log)
        Vue.logger._addLog('info', log, 100)
      },
      warn: function (log) {
        Vue.logger.debug(log)
        Vue.logger.info(log)
        Vue.logger._addLog('warn', log, 100)
      },
      error: function (log) {
        Vue.logger.debug(log)
        Vue.logger.info(log)
        Vue.logger.warn(log)
        Vue.logger._addLog('error', log, 100)
      }
    }
    window.chektlog = logger.logs
    Vue.prototype.$logger = logger
    Vue.logger = logger
  }
}

export default LoggerPlugin
