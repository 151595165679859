const baseRoutes = [
  {
    path: '/settings/arming',
    component: () => import("@/_routes/settings/arming/Root"),
  },
  {
    path: '/settings/arming/home',
    component: () => import("@/_routes/settings/arming/Home")
  },
  {
    path: '/settings/arming/away',
    component: () => import("@/_routes/settings/arming/Away")
  },
  {
    path: '/settings/arming/armed',
    component: () => import("@/_routes/settings/arming/Armed")
  },
  {
    path: '/settings/arming/entry',
    component: () => import("@/_routes/settings/arming/EntryDelay")
  },
  {
    path: '/settings/arming/exit',
    component: () => import("@/_routes/settings/arming/ExitDelay")
  },
  {
    path: '/settings/arming/bell',
    component: () => import("@/_routes/settings/arming/BellDuration")
  },
]

const routes = baseRoutes

export default routes
