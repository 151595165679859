export default {
  parseDelayData: function (data) {
    return {
      entryDelay: data.entry_delay,
      exitDelay: data.exit_delay,
      bellDuration: data.bell_duration,
      eventDelay: data.event_delay,
    }
  },
}
