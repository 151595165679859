import Vue from 'vue'
import helper from '../models/siteCameraTool'

export default {
  isNumber(value) {
    return typeof value === 'number' && !isNaN(value) && isFinite(value);
  },

  apiPrefix: '/api/v2/sites',
  getOnvifPtzConfigurationsFromProxy: function ({siteId, bridgeId, channel, brand='ONVIF'}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZConfigurationQuery(brand)}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  getOnvifPtzStatusFromProxy: function ({siteId, bridgeId, channel, profileToken, brand='ONVIF'}) {
    if (channel < 10) channel = '0' + channel

    const bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZStatusQuery(brand)}&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },



  // SETs
  setOnvifRelativePtzFromProxy: function ({siteId, bridgeId, channel, profileToken, p, t, z}) {
    // p - 좌우
    // t - 상하
    // z - 줌
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
    if (this.isNumber(p)) bridgeApiPathQueryOptions += `&p=${p}`;
    if (this.isNumber(t)) bridgeApiPathQueryOptions += `&t=${t}`;
    if (this.isNumber(z)) bridgeApiPathQueryOptions += `&z=${z}`;

    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=ptz/RelativeMove&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  setOnvifContinuousPtzFromProxy: function ({siteId, bridgeId, channel, profileToken, p, t, z, brand='ONVIF'}) {
    // p - 음수 좌      ||  양수 우     ||  숫자 크기 스피드
    // t - 음수 하      ||  양수 상     ||  숫자 크기 스피드
    // z - 음수 줌 아웃  ||  양수 줌 인   ||  숫자 크기 스피드
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
    if (this.isNumber(p)) bridgeApiPathQueryOptions += `&p=${p}`;
    if (this.isNumber(t)) bridgeApiPathQueryOptions += `&t=${t}`;
    if (this.isNumber(z)) bridgeApiPathQueryOptions += `&z=${z}`;

    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZContinuousMoveQuery(brand)}&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  setOnvifStopPtzFromProxy: function ({siteId, bridgeId, channel, profileToken, brand='ONVIF'}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZStopQuery(brand)}&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  setOnvifAbsolutePtzFromProxy: function ({siteId, bridgeId, channel, profileToken, p, t, z}) {
    // p - 좌우
    // t - 상하
    // z - 줌
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
    if (this.isNumber(p)) bridgeApiPathQueryOptions += `&p=${p}`;
    if (this.isNumber(t)) bridgeApiPathQueryOptions += `&t=${t}`;
    if (this.isNumber(z)) bridgeApiPathQueryOptions += `&z=${z}`;

    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=ptz/AbsoluteMove&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  setOnvifPtzGoToHomeFromProxy: function ({siteId, bridgeId, channel, profileToken, brand='ONVIF'}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZGoToHomeQuery(brand)}&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  setOnvifPtzSetToHomeFromProxy: function ({siteId, bridgeId, channel, profileToken}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=ptz/SetHomePosition&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  getOnvifPtzPresetsFromProxy: function ({siteId, bridgeId, channel, profileToken, brand='ONVIF'}) {
    if (channel < 10) channel = '0' + channel

    const bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZPresets(brand)}&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, { headers: {'Authorization': 'Bearer ' + Vue.auth.idToken} })
  },
  setOnvifPtzGoToPresetFromProxy: function ({siteId, bridgeId, channel, profileToken, presetToken, brand='ONVIF'}) {
    if (channel < 10) channel = '0' + channel

    const bridgeApiPathQueryOptions = `profile_token=${profileToken}&${helper.getPTZGoToPresetQueryOption(brand)}=${presetToken}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZGoToPresetQuery(brand)}&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, { headers: {'Authorization': 'Bearer ' + Vue.auth.idToken} })
  },
  setCameraPtzAreaZoomFromProxy: function ({siteId, bridgeId, channel, p, t, z, brand}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let bridgeApiPathQueryOptions = helper.getPTZAreaZoomQueryOption({ brand, p, t, z });
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZAreaZoomQuery(brand)}&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, { headers: {'Authorization': 'Bearer ' + Vue.auth.idToken} })
  },
}