<template>
  <ion-app>
    <!-- AuthManger always needs to be loaded first -->

    <template v-if="initialized && isPlatformIdentified">
      <div v-if="!isNetworkConnected && supportNetwork" class="network">Check your network connection</div>
      <AuthManager v-if="!isWeb"/>
      <WebAuthManager v-else/>
      <StoreUpdateManager />
      <PushMessageManager />
      <EventVideoViewerManager />
      <AgreementManager />
      <WebrtcManager v-if="isOkaytoLoadWebrtc" />

    <!-- Logging in - Loading -->
    <!-- <template v-if="
      statusGetMyProfile !== 'successful' &&
      $router.currentRoute.path !== '/callback'
    ">

    </template> -->

    <!-- Logged in -->
    <!-- <template v-else> -->

      <ion-vue-router :animated="env.deviceInfo.platform === 'web' ? false : true"></ion-vue-router>

    <!-- </template> -->
    </template>

  </ion-app>
</template>

<script>
import AuthManager from '@/components/managers/AuthManager'
import WebAuthManager from '@/components/managers/WebAuthManager'
import StoreUpdateManager from '@/components/managers/StoreUpdateManager'
import PushMessageManager from '@/components/managers/PushMessageManager'
import EventVideoViewerManager from '@/components/managers/EventVideoViewerManager'
import AgreementManager from '@/components/managers/AgreementManager'
import WebrtcManager from '@/components/managers/WebrtcManager'

export default {
  name: 'app',
  components: {
    AuthManager,
    WebAuthManager,
    StoreUpdateManager,
    PushMessageManager,
    EventVideoViewerManager,
    AgreementManager,
    WebrtcManager,
  },
  computed: {
    logoutRequest: function () {
      return this.$store.getters.logoutRequest
    },
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated
    },
    selectedSiteId: function () {
      return this.$store.getters.selectedSiteId
    },
    statusGetMyProfile: function () {
      return this.$store.getters.statusGetMyProfile
    },
    isPlatformIdentified: function () {
      return this.env.deviceInfo.platform !== undefined
    },
    devices: function () {
      return this.$store.getters.siteDevices
    },
    supportWebrtc: function () {
      return this.$store.getters.supportWebrtc
    },
    supportNetwork: function () {
      return this.$store.getters.supportNetwork
    },
    isNetworkConnected: function () {
      return this.$store.getters.isNetworkConnected
    },
    isWeb: function () {
      return this.$env.deviceInfo.platform === 'web'
    },
    isOkaytoLoadWebrtc: function () {
      if (!(Object.keys(this.devices).length)) return false
      if (!this.selectedSiteId) return false
      if (!this.supportWebrtc) return false
      return true
    },
  },
  data: function () {
    return {
      env: this.$env,
      initialized: false,
      lastBackButtonClickedAt: new Date(),
    }
  },
  watch: {
    logoutRequest: function () {
      this.onLogoutRequested()
    },
  },
  mounted: function () {
    document.addEventListener('ionBackButton', this.onIonBackButton)
  },
  beforeDestroy: function () {
    document.removeEventListener('ionBackButton', this.onIonBackButton)
  },
  created: async function () {
    await this.init()
  },
  methods: {
    init: async function () {
      await this.$deploy.updateEnvironment()
      this.initAllSupportVersion()
      this.$tool.addAppStatusListener()
      this.$tool.addNetworkListener()
      this.$tool.lockScreenOrientation('portrait')
      this.$tool.subscribeOrientationChange()
      await this.$tool.setStatusBarColor('light')
      await this.$tool.hideSplashScreen()
      this.initialized = true
    },
    initAllSupportVersion: function () {
      this.$store.commit('INIT_ALL_SUPPORT_VERSION')
    },
    onIonBackButton: function (e) {
      e.detail.register(0, async() => {
        if (document.getElementById('chekt-event-video')) return
        if (this.$route.path == '/tabs/security') {
          navigator.app.exitApp();
        }
        let timeNow = new Date()
        
        let thisTime = timeNow.getTime()
        let lastTime = this.lastBackButtonClickedAt.getTime()
        if (lastTime + 500 > thisTime) return

        this.lastBackButtonClickedAt = timeNow
        // alert('handler0 was called!: ' + timeNow.toString())
        await this.$router.go(-1)
      })

      // e.detail.register(9999, (processNextHandler) => {
      //   if (document.getElementById('chekt-event-video')) {
      //     let timeNow = new Date()
          
      //     let thisTime = timeNow.getTime()
      //     let lastTime = this.lastBackButtonClickedAt.getTime()
      //     if (lastTime + 1000 > thisTime) return

      //     this.lastBackButtonClickedAt = timeNow
      //     this.$tool.closeVideo()
      //   }
      //   else {
      //     processNextHandler();
      //   }
      // });



    },
    onLogoutRequested: async function () {
      // RESET
      this.$tool.showSpinnerDialog()
      this.initialized = false
      this.$router.push({path: '/'})
      try {
        // LOGOUT - from auth0
        if(this.env.deviceInfo.platform === 'web') {
          await this.$store.dispatch('logout')
        } else {
          await this.$store.dispatch('revokeRefreshToken')
        }
      } catch(err) {
        console.log(err)
      }
      // RESET - all STATE
      await this.$tool.wait(1000)
      this.$store.commit('RESET_STATE')
      // INIT - everything local
      await this.init()
      // RESET - FCM Instance
      if (!this.isWeb) {
        this.$push.deleteFCMInstance()
      }
      this.$tool.hideSpinnerDialog()
    },
  },
}
</script>

<style>

/* iPhone & Android Safe area */
:root {
  --ion-safe-area-top: constant(safe-area-inset-top);
  --ion-safe-area-bottom: constant(safe-area-inset-bottom);
  --ion-safe-area-left: constant(safe-area-inset-left);
  --ion-safe-area-right: constant(safe-area-inset-right);

  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  --ion-safe-area-left: env(safe-area-inset-left);
  --ion-safe-area-right: env(safe-area-inset-right);
}

/* CHeKT default - common */
:root {
  /* Set the font family of the entire app */
  /* --ion-font-family: 'Montserrat', sans-serif; */
  /* Set the background of the entire app */
  --ion-background-color: var(--ion-color-dark);
  --ion-background-color-low: #F5F7F9;

  /* iocon */
  --ion-icon-disable-color: rgba(0,0,0, 0.38);
  --ion-icon-normal-color: rgba(0,0,0, 0.6);
  --ion-icon-tint-color: rgba(0,0,0, 0.87);

  /* Border */
  --ion-border-color: rgba(0,0,0, 0.1);
  /* Backdrop */
  --ion-backdrop-color: #000;
  --ion-backdrop-opacity: 0.5;
  /* Text */
  --ion-text-color: #222;
  --ion-text-high: #212121;
  --ion-text-medium: #666666;
  --ion-text-low: #9E9E9E;
  /* Tabbar */
  --ion-tab-bar-background: var(--ion-color-medium);
  --ion-tab-bar-border-color: rgba(var(--ion-color-medium-contrast-rgb), 0.3);
  --ion-tab-bar-color: rgba(var(--ion-color-medium-contrast-rgb), 0.5);
  --ion-tab-bar-color-selected: var(--ion-color-medium-contrast);
  /* Toolbar */
  --ion-toolbar-color: var(--ion-color-light-contrast);
  --ion-toolbar-background: var(--ion-color-light);
  /* Item */
  --ion-item-color: var(--ion-color-light-contrast);
  --ion-item-background: var(--ion-color-light);
}

/* CHeKT default - color */
:root {
  --ion-color-primary: #1E88E5;
  --ion-color-primary-rgb: 30,136,229;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1a78ca;
  --ion-color-primary-tint: #3594e8;

  --ion-color-secondary: #FF7F16;
  --ion-color-secondary-rgb: 255,127,22;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e07013;
  --ion-color-secondary-tint: #ff8c2d;
  
  --ion-color-tertiary: #1EA675;
  --ion-color-tertiary-rgb: 30,166,117;
  --ion-color-tertiary-contrast: #FFFFFF;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #1A9267;
  --ion-color-tertiary-tint: #35AF83;

  --ion-color-success: #7ED321;
  --ion-color-success-rgb: 126,211,33;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #6fba1d;
  --ion-color-success-tint: #8bd737;

  --ion-color-warning: #EB6E00;
  --ion-color-warning-rgb: 235,110,0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #cf6100;
  --ion-color-warning-tint: #ed7d1a;

  --ion-color-danger: #CD3D64;
  --ion-color-danger-rgb: 205,61,100;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #b43658;
  --ion-color-danger-tint: #d25074;

  --ion-color-dark: #EBEBEB;
  --ion-color-dark-rgb: 235,235,235;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #cfcfcf;
  --ion-color-dark-tint: #ededed;

  --ion-color-medium: #F1F1F1;
  --ion-color-medium-rgb: 241,241,241;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 128,128,128;
  --ion-color-medium-shade: #d4d4d4;
  --ion-color-medium-tint: #f2f2f2;

  --ion-color-light: #F9F9F9;
  --ion-color-light-rgb: 249,249,249;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #dbdbdb;
  --ion-color-light-tint: #fafafa;

  --ion-color-blue-gray-higher: #55647B;
  --ion-color-blue-gray-high: #8792A2;
  --ion-color-blue-gray-medium: #E3E8EE;
  --ion-color-blue-gray-low: #F7FAFC;

	--ion-color-partially: #f3ae00;
	--ion-color-partially-rgb: 243,174,0;
	--ion-color-partially-contrast: #ffffff;
	--ion-color-partially-contrast-rgb: 255,255,255;
	--ion-color-partially-shade: #d69900;
	--ion-color-partially-tint: #f4b61a;

	--ion-color-gray: #a0a0a0;
	--ion-color-gray-rgb: 160,160,160;
	--ion-color-gray-contrast: #ffffff;
	--ion-color-gray-contrast-rgb: 0,0,0;
	--ion-color-gray-shade: #8d8d8d;
	--ion-color-gray-tint: #aaaaaa;
}

/* Custom Ionic Color */
.ion-color-partially {
	--ion-color-base: var(--ion-color-partially);
	--ion-color-base-rgb: var(--ion-color-partially-rgb);
	--ion-color-contrast: var(--ion-color-partially-contrast);
	--ion-color-contrast-rgb: var(--ion-color-partially-contrast-rgb);
	--ion-color-shade: var(--ion-color-partially-shade);
	--ion-color-tint: var(--ion-color-partially-tint);
}
.ion-color-gray {
	--ion-color-base: var(--ion-color-gray);
	--ion-color-base-rgb: var(--ion-color-gray-rgb);
	--ion-color-contrast: var(--ion-color-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-shade);
	--ion-color-tint: var(--ion-color-gray-tint);
}
/* Custom Ionic Color */

header {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin-bottom: 1.25em;
  text-transform: uppercase;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

/* -------- */
/* My style */
.sub-title {
  /* size */
  font-size: 12px;
  padding-bottom: .5em;
  /* remove bottom border of ion-list */
  /* margin-top: -1px; */
  /* background-color: var(--ion-color-dark); */
  /* position */
  position: relative;
  z-index: 1;
  /* color */
  color:hsla(0, 100%, 0%, 0.7);
  /* font */
  font-weight:500;
}
.item-note {
   max-width: 40%;
   font-size: 14px;
   text-align:right;
}
.ion-content-with-safe-area-bottom {
  --padding-bottom: var(--ion-safe-area-bottom, 0);
}
.with-safe-area-bottom {
  padding-bottom: var(--ion-safe-area-bottom, 0);
}
ion-list {
  border-top: 1px solid var(--ion-border-color);
  border-bottom: 1px solid var(--ion-border-color);
}
ion-list > ion-item:last-child {
  --inner-border-width: 0;
}
ion-tab-button {
  --ripple-color: rgba(0,0,0,0.1);
}
/* -------- */

/* Animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.darkmode-toast {
  --color: white;
  --background: rgba(0, 10, 20, .78);
  --border-radius: 0%;
}

/* for modals > EditRelay */
#chekt-relays .relay .relay-wrapper { 
  margin-right: 0px !important;
  cursor: pointer;
}

/* for ionic action sheet modal */
/* .action-sheet-group.sc-ion-action-sheet-md:last-child {
  padding-bottom: var(--ion-safe-area-bottom);
} */

/* for ionic alertController remove button*/
.custom-alert-remove-button span{
  color: var(--ion-color-danger);
}
.custom-alert-arm-button span{
  color: var(--ion-color-secondary);
}
.custom-alert-disarm-button span{
  color: var(--ion-color-tertiary);
}

/* for ionic actionSheetController custom*/
.action-sheet-wrapper.sc-ion-action-sheet-md {
  margin-bottom: 0 !important;
}
.action-sheet-container.sc-ion-action-sheet-md .action-sheet-group:last-child  {
  padding-bottom: var(--ion-safe-area-bottom);
}



.network {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 9999;
  color: var(--ion-color-light);
  font-size: 12px;
  width: 100%;
  padding-top: calc(5px + var(--ion-safe-area-top));
  padding-bottom: 5px;
  background-color: #000000b0;
}

@media screen and (min-width: 1024px) {
  .sc-ion-modal-ios.ios.backdrop-hide.hydrated {
    background-color: black;
  }
  .modal-shadow.sc-ion-modal-ios {
    background: transparent
  }
  .sc-ion-modal-ios-h {
    --width: 100vh;
    --height: 90%;
    padding-top: 32px;
  }
  .list[data-v-02a1f34f] {
    overflow:hidden;
  }
  .view[data-v-5fb2ae83]:not(.fullscreen) {
    width: 100%;
  }
  video {
    aspect-ratio: 16 / 9;
  }
  ion-content:not(.special){
    max-width: 1024px;
    left: 50%;
    transform: translateX(-50%);
  }
  ion-header {
    background-color: #F9F9F9;
  }
  ion-header ion-toolbar:not(.special) {
    max-width: 1024px;
    left: 50%;
    transform: translateX(-50%);
  }
  .modal-wrapper {
    max-width: 1024px;
  }
}
</style>