<template>
  <div></div>
</template>

<script>
import { modalController } from '@modus/ionic-vue'
import AgreementModal from '@/components/modals/Agreement'
export default {
  name: 'agreement-manager',
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated
    },
    isAgreementAccepted: function () {
      return this.$store.getters.isAgreementAccepted
    },
    statusGetLastAgreementInfo: function () {
      return this.$store.getters.statusGetLastAgreementInfo
    },
    statusLogin: function () {
      return this.$store.getters.statusLogin
    },
    statusLoadLastAuthSession: function () {
      return this.$store.getters.statusLoadLastAuthSession
    },
    isAppActive: function () {
      return this.$store.getters.isAppActive
    },
  },
  // data: function () {
  //   return {
  //   }
  // },
  watch: {
    // WHEN - last id_token is retrieved from localStorage
    statusLoadLastAuthSession: function () {
      if (this.statusLoadLastAuthSession !== 'successful') return
      this.getLastAgreementInfo()
    },
    // WHEN - login successful - got refresh_token and id_token
    statusLogin: function () {
      if (this.statusLogin !== 'successful') return
      this.getLastAgreementInfo()
    },
    statusGetLastAgreementInfo: function () {
      if (this.statusGetLastAgreementInfo === 'successful') {

        // Check agreement Status
        this.checkAgreementStatus()
        
      }
      else if (this.statusGetLastAgreementInfo === 'failed') {
        // go to agreement error page
        this.$tool.presentToast('Failed to load the last agreement information.')
      }
    },
    isAppActive: function () {
      // WHEN - App is active
      if (this.isAppActive) this.getLastAgreementInfo()
    }
  },
  methods: {
    getLastAgreementInfo: async function () {
      if (this.statusGetLastAgreementInfo === 'requested') return
      if (!(await this.isAuthenticated())) return

      this.$store.dispatch("getLastAgreementInfo")
    },
    checkAgreementStatus: function () {
      // CHECK - Already show agreement modal
      let agreementModal = document.getElementsByClassName("--chekt-agreement-modal")
      if (agreementModal.length > 0 ) return

      // ACTION - Show agreement modal if not accepted.
      if (!this.isAgreementAccepted) this.showAgreementModal()
    },
    showAgreementModal: function () {
      modalController.create({
        component: AgreementModal,
        cssClass: '--chekt-agreement-modal',
        backdropDismiss: false,
        componentProps: {
          propsData: {
            store: this.$store,
          },
        },
      }).then(m => m.present())
    },
    showErrorAgreementModal: function () {
      modalController.create({
        component: ErrorAgreementModal,
        backdropDismiss: false,
        componentProps: {
          propsData: {
            store: this.$store,
          },
        },
      }).then(m => m.present())
    },
   
  }
}
</script>

<style scoped>
</style>
