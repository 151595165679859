import Vue from 'vue'

export default {
  downloadImage: function (url) {
    return Vue.http.get(url, {responseType: 'blob'})
  },
  downloadImageArrayBuffer: function (url) {
    return Vue.http.get(url, {responseType: 'arraybuffer'})
  },
  get: function (apiPath) {
    return Vue.http.get(apiPath)
  },
  put: function (apiPath, apiBody) {
    return Vue.http.post(apiPath, apiBody)
  },
  post: function (apiPath, apiBody) {
    return Vue.http.post(apiPath, apiBody)
  },
}
