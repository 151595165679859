import Vue from 'vue'
import {
  RESET_STATE,
  REQUEST_PUSH_INIT,
  REQUEST_RESET_PUSH_INSTANCE,
} from '@/store/mutation-types'

let initialState = {
  initRequest: 0, // just increase counter to notify PushMessageManager
  resetRequest: 0, // just increase counter to notify PushMessageManager
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  pushInitRequest: function (state) {
    return state.initRequest
  },
  pushResetRequest: function (state) {
    return state.resetRequest
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [REQUEST_PUSH_INIT]: function (state) {
    state.initRequest++
  },
  [REQUEST_RESET_PUSH_INSTANCE]: function (state) {
    state.resetRequest++
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
