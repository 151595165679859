import Vue from 'vue'
import { IonicVueRouter } from "@modus/ionic-vue"
Vue.use(IonicVueRouter)

const baseRoutes = [{
    path: '/',
    redirect: '/tabs'
  },
  {
    path: '/callback',
    component: () => import("@/components/auth/AuthCallback")
  },
  {
    path: "/login",
    component: () => import("@/_routes/Login")
  },
  {
    path: "/dashboard",
    component: () => import("@/_routes/Dashboard")
  },
  {
    path: "/reset_password",
    component: () => import("@/_routes/ResetPassword")
  },
  {
    path: "/errors/trial_expired",
    component: () => import("@/_routes/errors/TrialExpired")
  }
]

import tabsRoutes from '@/_routes/tabs/_router'
import settingsRoutes from '@/_routes/settings/_router'
import cameraRoutes from '@/_routes/camera/_router'
const routes = baseRoutes.concat(tabsRoutes)
                         .concat(settingsRoutes)
                         .concat(cameraRoutes)

export default new IonicVueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})