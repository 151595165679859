export default {
  parseArmingModeData: function (data) {
    let modes = {
      disarmed: [],
      home: [],
      away: [],
      armed: [],
    }
    for (let i=0; i<data.length; i++) {
      const deviceData = data[i]
      // CHECK - deviceType
      let deviceTypeName = 'unknown'
      switch (deviceData.type) {
      case 11:
        deviceTypeName = 'bridge'
        break
      case 12:
        deviceTypeName = 'camera'
        break
      }
      if (deviceTypeName !== 'camera') continue
      // CHECK - zoneType
      let zoneTypeName = 'unknown'
      switch (deviceData.zone_type) {
      case 0:
        zoneTypeName = 'entry'
        break
      case 1:
        zoneTypeName = 'follower'
        break
      case 2:
        zoneTypeName = 'instant'
        break
      case 3:
        zoneTypeName = '24hour'
        break
      case 4:
        zoneTypeName = 'instant' // exterior
        break
      case 5:
        zoneTypeName = 'entry' // exterior
        break
      }
      // UPDATE
      if (zoneTypeName != '24hour') {
        // UPDATE - disarmed mode
        if (deviceData.is_onvif_event_enabled) {
          modes['disarmed'].push(deviceData.device_id)
        }
        // UPDATE - armed mode - voltage arming
        if (deviceData.arming_type == 0) {
          modes['armed'].push(deviceData.device_id)
          continue
        }
        // UPDATE - home and away mode
        switch (zoneTypeName) {
        case 'entry':
          modes['away'].push(deviceData.device_id)
          break
        case 'follower':
          modes['away'].push(deviceData.device_id)
          break
        case 'instant':
          modes['away'].push(deviceData.device_id)
          modes['home'].push(deviceData.device_id)
          break
        }
      }
    }
    // DONE
    return modes
  },
}
