<template>

  <div id="--chekt-video-player" style="background-color:black;" @click="toggleOverlay()" ref="page">

    <div v-show="isFullscreen && showOverlay" ref="overlay" class="overlay"></div>

    <!-- header -->
    <ion-header v-show="!isFullscreen || (isFullscreen && showOverlay)" ref="header" class="header" :style="[{position: isFullscreen ? 'absolute' : ''}, {backgroundColor: isFullscreen ? '' : 'black'}]">
      <ion-toolbar color="none" style="color:white;">
        <ion-buttons slot="start">
          <ion-button v-on:click="onClickClose" v-if="!isFullscreen">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          <div class="header-title-main">{{timeStr ?? 'Unknown'}}</div>
          <div class="header-title-sub">{{deviceName ?? 'No Name'}}</div>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button v-on:click="onClickShare($event)" :disabled="!hasVideo" v-if="!isWeb">
            <ion-icon name="arrow-redo-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button v-on:click="onClickDownload($event)" :disabled="!hasVideo" v-if="isWeb">
            <ion-icon name="download-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <!-- video -->
    <div class="container" ref="container">
      
      <!-- zooming -->
      <div :class="{ 'animate': zoomChangeToOriginal }" class="zoom original">Original</div>
      <div v-if="zooming" class="zoom scale">{{zooming}}X</div>
      
      <div class="view-container" ref="viewContainer">

        <div ref="viewWrapper" class="viewWrapper">
          <div ref="view" >

            <!-- video -->
            <video 
            v-show="hasVideo"
            @play="updatePlayButton"
            @pause="updatePlayButton" 
            @loadedmetadata="initializeVideo" 
            @input="skipAhead"
            :src="eventVideoUrl"
            :class="{ 'fullscreen': isFullscreen}" 
            class="view" ref="video" preload="metadata" playsinline autoplay type="video/mp4"/>

            <!-- snapshots -->
            <div v-show="!hasVideo && hasSnapshot" class="snapshot no-event">
              <img ref="snapshot">
            </div>

            <!-- failed video or snapshot -->
            <div v-if="!hasVideo && !hasSnapshot" class="no-event">
              <ion-spinner name="circular"></ion-spinner>
            </div>
            <!-- <div v-else-if="!hasVideo && !hasSnapshot && isFinishStoreEventVideoAndSnapshotRoutine" class="no-event">
              Failed to load
            </div> -->
          </div>
        </div>

      </div>
    </div>

    <!-- controls -->
    <ion-footer v-show="!isFullscreen || (isFullscreen && showOverlay)" :style="[{position: isFullscreen ? 'absolute' : ''}, {bottom: isFullscreen ? '0' : ''}, {backgroundColor: isFullscreen ? '' : 'black'}]" class="view-controls" ref="viewControls">
      
      <!-- progress infos -->
      <ion-toolbar color="none" style="height: 30px !important;">
        <ion-buttons slot="start" ref="fullscreenIconsStart" style="--min-height: 0px !important; gap:10px;">
          <div class="time" v-show="hasVideo">
            <time>{{timeElapsed}}</time>
            <span> / </span>
            <time>{{duration}}</time>
          </div>
          <div class="time" v-show="!hasVideo && hasSnapshot">
            <time>{{currentSnapshotIndex}}</time>
            <span> / </span>
            <time>{{totalSnapshotIndex}}</time>
          </div>
        </ion-buttons>
        <ion-buttons slot="end" ref="fullscreenIconsEnd" style="--min-height: 0px !important; gap:10px;">
        </ion-buttons>
      </ion-toolbar>

      <!-- progress bar -->
      <div class="progress">
        <div v-show="hasVideo" class="video-progress">
          <progress ref="progressBar" min="0" step="0.1"></progress>
          <input @input="skipAhead" @click="onClickProgressBar($event)" @touchstart="progressChangeStart()" @touchmove="progressChangeMove($event)"  @touchend="progressChangeEnd()" class="seek" ref="seek" value="0" min="0" type="range" step="0.1">
        </div>

        <div v-show="!hasVideo && hasSnapshot" class="snapshot-progress">
          <progress :value="currentSnapshotIndex" min="0" :max="totalSnapshotIndex" step="1"></progress>
        </div>
      </div>

      <!-- play/pause/previous/next -->
      <ion-toolbar v-show="!isFullscreen || (isFullscreen && showOverlay)" color="none" ref="playControls" style="color:white;">

        <ion-buttons slot="start" ref="noFullscreenIconsLeft">
          <ion-button @click="onClickPlaySpeed()" ref="playSpeedButton" :disabled="!hasVideo">
            <div style="color:white;">×{{playSpeed}}</div>
          </ion-button>
        </ion-buttons>

        <ion-title>
          <ion-buttons style="gap:20px; justify-content:center; align-items:center;">
            <ion-button @click="onClickViewPreviousVideo()" :disabled="isLoadingVideo || !isShowPreNexButton">
              <ion-icon name="play-skip-back"></ion-icon>
            </ion-button>

            <ion-button @click="togglePlay($event)" style="height:auto;">
              <ion-icon v-if="isPlaying" slot="icon-only" name="pause" style="font-size:48px;"></ion-icon>
              <ion-icon v-else slot="icon-only" name="play" style="font-size:48px;"></ion-icon>
            </ion-button>

            <ion-button @click="onClickViewNextVideo()" :disabled="isLoadingVideo || !isShowPreNexButton">
              <ion-icon name="play-skip-forward"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-title>

        <ion-buttons slot="end" ref="noFullscreenIconsRight">
          <ion-button @click="toggleFullscreen()" ref="fullscreenButton" v-if="!isWeb">
            <MyIcon v-if="isFullscreen" :icon="'fullscreen-exit'" v-bind:width="28" style="color:white;" />
            <MyIcon v-else :icon="'fullscreen'" v-bind:width="28" style="color:white;" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

    </ion-footer>

  <transition name="slide">
    <VideoViewerPlaySpeed
    id="--chekt-video-viewer-play-speed-dialog"
    v-if="showPlaySpeedModal"
    :playSpeed="playSpeed"
    @changeSpeed="onEmitChangeSpeed"
    @closeSpeedModal="onEmitCloseSpeedModal"/>
  </transition>

  </div>

</template>

<script>
var moment = require('moment')
import MyIcon from '@/components/MyIcon'
import VideoViewerPlaySpeed from '@/components/modals/VideoViewerPlaySpeed'

export default {
  components: {
    MyIcon,
    VideoViewerPlaySpeed,
  },
  props: {
    store: { type: Object, default: function () { return {} } },
    tool: { type: Object, default: function () { return {} } },
    timeStr: { type: String, default: "" },
    deviceName: { type: String, default: "" },
    eventId: { type: String, default: "" },
  },
  data: function () {
    return {
      video: null,
      min: 0,
      max: 0,
      value: null,
      isPlaying: false,
      lastIsPlayingStatus: false,
      timeElapsed: '00:00',
      duration: '00:00',
      zooming: null,
      zoomChangeToOriginal: false,
      originalAnimation$t: null,
      playingSnapshotUrl$ani: null,
      currentSnapshotIndex: 0,
      totalSnapshotIndex: 0,
      showOverlay: false,
      lastSnapahotTime: 0,
      loadedSnapshotImageCount: 0,
      snapshotInterval: 500,

      page: null,
      overlay: null,
      header: null,
      viewControls: null,
      playControls: null,
      fullscreenButton: null,
      fullscreenIconsStart: null,
      fullscreenIconsEnd: null,
      noFullscreenIconsLeft: null,
      noFullscreenIconsRight: null,
      snapshot: null,

      hasVideo: false,
      hasSnapshot: false,

      showPlaySpeedModal: false,
      playSpeed: 1,

      isLoadingVideo: false,
      isShowPreNexButton: false,

      eventList: null,
      eventListItems: null,
      eventType: null,
    }
  },
  computed: {
    selectedSiteId: function () {
      return this.store.getters.selectedSiteId
    },
    site: function () {
      return this.store.getters.site(this.selectedSiteId)
    },
    eventVideoUrl: function () {
      return this.store.getters.eventVideoUrl
    },
    eventSnapshotUrls: function () {
      return this.store.getters.eventSnapshotUrls
    },
    stautsEventVideoUrl: function () {
      return this.store.getters.stautsEventVideoUrl
    },
    statusEventSnapshotUrls: function () {
      return this.store.getters.statusEventSnapshotUrls
    },
    isFullscreen: function () {
      return this.store.getters.isFullscreen
    },
    currentAppOrientationType: function () {
      return this.store.getters.currentAppOrientationType
    },
    isScreenOrientationLock: function () {
      return this.store.getters.isScreenOrientationLock
    },
    isFinishStoreEventVideoAndSnapshotRoutine: function () {
      return this.store.getters.isFinishStoreEventVideoAndSnapshotRoutine
    },
    siteActivityLog: function () {
      return this.store.getters.siteActivityLog
    },
    siteDisplayTimezone: function () {
      return this.store.getters.siteDisplayTimezone(this.selectedSiteId)
    },
    deviceEvents: function () {
      return this.store.getters.deviceEvents
    },
    siteEvents: function () {
      return this.store.getters.siteEvents
    },
    isWeb: function () {
      return this.$env.deviceInfo.platform === 'web'
    },
  },
  watch: {
    eventVideoUrl: async function (now, old) {
      if (now) {
        await this.beforehasVideo()
        this.isLoadingVideo = false
        this.stopPlaySnapshotUrl()
      }
    },
    eventSnapshotUrls: function (now, old) {
      if (now) {
        this.checkEventSnapshotUrlsLoadedAll()
      }
    },
    isFullscreen: function () {
      this.changeDomPosition()

      if (this.isFullscreen) {
        this.$tool.hideStatusBar()
      } 
      else {
        this.$tool.showStatusBar()
      }
    },
    currentAppOrientationType: function () {
      if (this.isScreenOrientationLock) this.$tool.unlockScreenOrientation()
    }
  },
  created: function () {
    this.init()
  },
  mounted: async function () {
    await this.$nextTick()
    this.initVideoConfig()
  },
  beforeDestroy: function () {
    this.stopPlaySnapshotUrl()
    this.removeVideoListers()
    this.$tool.lockScreenOrientation()
  },
  methods: {
    init: function() {
      this.$tool.unlockScreenOrientation()
      this.isLoadingVideo = true
      this.beforehasSnapshot()
      this.beforehasVideo()
      this.beforeEnablePreNexButton()
    },
    beforehasSnapshot: function () {
      if (this.statusEventSnapshotUrls === 'successful') this.hasSnapshot = true
      else this.hasSnapshot = false
    },
    beforehasVideo: async function () {
      if (this.stautsEventVideoUrl === 'successful') {
        this.hasVideo = true
        if (this.video) {
          await this.$nextTick() // wait render
          this.video.playbackRate = this.playSpeed
        }
      }
      else {
        if (this.video) this.video.src = ''
        this.hasVideo = false
      }
    },
    beforeEnablePreNexButton: function () {
      let activityLogList = document.getElementById('--chekt-activity-log-list')
      let cameraEventList = document.getElementById('--chekt-camera-event-list')
      
      // actity log를 우선시 한다.
      if (activityLogList) {
        this.eventList = activityLogList
        this.eventType = 'log'
      }
      else if (cameraEventList) {
        this.eventList = cameraEventList
        this.eventType = 'event'
      }

      if (this.eventList) {
        this.isShowPreNexButton = true
        this.eventListItems = Array.from(this.eventList.querySelectorAll('ion-item'));
        return 
      }
      else this.isShowPreNexButton = false
    },
    // snapshots ------------------------------------------------
    startPlaySnapshotUrl: function (images) {

      const showNextImage = (timestamp) => {
        if (timestamp - this.lastSnapahotTime >= this.snapshotInterval) {
          if (this.currentSnapshotIndex < images.length) {
            try {
              this.$refs.snapshot.src = images[this.currentSnapshotIndex]?.currentSrc
              this.currentSnapshotIndex++
            }
            catch (err) {
              console.log(err)
            }
          } 
          else this.currentSnapshotIndex = 0

          this.lastSnapahotTime = timestamp
        }

        this.playingSnapshotUrl$ani = requestAnimationFrame(showNextImage)
      }

      // 처음 이미지 표시를 시작하기 위해 requestAnimationFrame 호출
      requestAnimationFrame(showNextImage);
    },
    stopPlaySnapshotUrl: function () {
      if (!this.playingSnapshotUrl$ani) return 
      cancelAnimationFrame(this.playingSnapshotUrl$ani)
      this.playingSnapshotUrl$ani = null
    },
    checkEventSnapshotUrlsLoadedAll: function () {
      Promise.all(this.eventSnapshotUrls.map(this.snapshotImageLoaded))
      // SUCCESS
      .then(function(images) {
        this.totalSnapshotIndex = images?.length
        // ACTION - start snapshot
        this.startPlaySnapshotUrl(images)
        // 스냅샷으로 재생될 때, 재생버튼이 재생버튼으로 안되어 있다.
        this.isPlaying = true
        this.beforehasSnapshot()
      }.bind(this))
      // FAILED
      .catch(function(error) {
        console.error('이미지 로딩 중 오류 발생: ' + error);
        this.isPlaying = false
      });
    },
    snapshotImageLoaded: function (url) {
      return new Promise(function(resolve, reject) {
        let img = new Image();
        img.onload = function() {
          resolve(img);
        };
        img.onerror = function() {
          reject('이미지 로드 실패: ' + url);
        };
        img.src = url;
      });
    },

    // video     ------------------------------------------------
    initVideoConfig: function () {
      // Select elements here
      this.container = this.$refs.container
      this.viewWrapper = this.$refs.viewWrapper
      this.viewContainer = this.$refs.viewContainer
      this.viewControls = this.$refs.viewControls
      this.progressBar = this.$refs.progressBar
      this.seek = this.$refs.seek
      this.view = this.$refs.view
      this.overlay = this.$refs.overlay
      this.header = this.$refs.header
      this.playControls = this.$refs.playControls
      this.page = this.$refs.page
      this.video = this.$refs.video
      this.fullscreenButton = this.$refs.fullscreenButton
      this.playSpeedButton = this.$refs.playSpeedButton
      this.fullscreenIconsEnd = this.$refs.fullscreenIconsEnd
      this.fullscreenIconsStart = this.$refs.fullscreenIconsStart
      this.noFullscreenIconsLeft = this.$refs.noFullscreenIconsLeft
      this.noFullscreenIconsRight = this.$refs.noFullscreenIconsRight

      if (this.video) {
        // Add eventlisteners here
        this.video.addEventListener('timeupdate', this.updateTimeElapsed);
        this.video.addEventListener('timeupdate', this.updateProgress);

        this.playSpeed = this.video.playbackRate
      }

      // for pinch zooming
      this.$tool.pinchZoom(this.view, this.viewWrapper, this.container, this.getZommingScaleFromTool)

      let modal = this.page.closest('ion-modal')
      this.swipeToClose(modal)
    },
    changeDomPosition: function () {
      if (this.isFullscreen) {
        this.playControls.style.position = 'absolute'
        this.playControls.style.top = '50%'
        this.playControls.style.transform = 'translateY(-50%)'
        this.page.appendChild(this.playControls);
        let fullscreenIconsfirstChild = this.fullscreenIconsStart.firstChild;
        if (fullscreenIconsfirstChild) this.fullscreenIconsStart.insertBefore(this.playSpeedButton, fullscreenIconsfirstChild)
        else this.fullscreenIconsStart.appendChild(this.playSpeedButton)
        this.fullscreenIconsEnd.appendChild(this.fullscreenButton)
      }
      else {
        this.playControls.style.position = ''
        this.playControls.style.top = ''
        this.playControls.style.transform = ''
        this.viewControls.appendChild(this.playControls);
        this.noFullscreenIconsLeft.appendChild(this.playSpeedButton)
        this.noFullscreenIconsRight.appendChild(this.fullscreenButton)
      }
    },
    removeVideoListers: function () {
      if (!this.video) return
      this.video.removeEventListener('timeupdate', this.updateTimeElapsed);
      this.video.removeEventListener('timeupdate', this.updateProgress);
    },
    getZommingScaleFromTool: function (info) {
      if (info?.isTouchend && info?.scale < 1.1) {
        this.zoomChangeToOriginal = true
        this.originalAnimation$t = setTimeout(()=> {this.zoomChangeToOriginal = false}, 1200)
      }
      else {
        this.zoomChangeToOriginal = false
        clearTimeout(this.originalAnimation$t)
        this.originalAnimation$t = null
      }

      if (info?.scale < 1.1) this.zooming = null
      else this.zooming = info?.scale.toFixed(1)
    },
    progressChangeStart: function () {
      this.lastIsPlayingStatus = this.isPlaying ? true : false
      this.video.pause()

      if (this.isFullscreen) {
        this.playControls.style.display = 'none'
        this.header.style.display = 'none'
        this.fullscreenButton.style.display = 'none'
        this.playSpeedButton.style.display = 'none'
        this.overlay.style.opacity = '0'
      }
    },
    progressChangeMove: function (e) {
      e.stopPropagation();
      e.preventDefault();

      var touch = e.touches[0]; // 현재 터치의 첫 번째 터치를 가져옵니다.

      // 터치 포인트의 뷰포트 내 위치
      var clientX = touch.clientX;

      // 이벤트 대상 요소의 뷰포트 내 위치
      var rect = e.target.getBoundingClientRect();

      // 대상 요소 내에서의 터치 위치를 계산
      var offsetX = clientX - rect.left;

      if (offsetX/e.target.clientWidth > 1) return
      if (offsetX/e.target.clientWidth < 0) return

      let skipTo = (offsetX/e.target.clientWidth)*this.video.duration
      this.video.currentTime = skipTo;
      this.progressBar.value = skipTo;
      this.seek.value = skipTo;
    },
    progressChangeEnd: function () {
      if (this.lastIsPlayingStatus) this.video.play()
      this.playControls.style.display = ''
      this.header.style.display = ''
      this.overlay.style.opacity = ''
      this.fullscreenButton.style.display = ''
      this.playSpeedButton.style.display = ''
    },
    onClickProgressBar: function (e) {
      // progress bar 움직일때 overlay 사라짐 방지.
      e.stopPropagation();
      e.preventDefault();
    },
    updatePlayButton: function () {
      if (this.video.paused) this.isPlaying = false
      else this.isPlaying = true
    },
    toggleFullscreen: function () {
      if (!this.isFullscreen) {
        this.$tool.lockScreenOrientation('landscape')
      }
      else this.$tool.lockScreenOrientation('portrait')
    },
    toggleOverlay: async function () {
      if (!this.isFullscreen) return
      let overlayAni, headerAni, viewControlsAni, playControlsAni

      if (this.showOverlay) {
        overlayAni = this.overlay.animate([{opacity: 1,}, {opacity: 0,},], {duration: 200,});
        headerAni = this.header.animate([{opacity: 1,}, {opacity: 0,},], {duration: 200,});
        viewControlsAni = this.viewControls.animate([{opacity: 1,}, {opacity: 0,},], {duration: 200,});
        playControlsAni = this.playControls.animate([{opacity: 1,}, {opacity: 0,},], {duration: 200,});
        await overlayAni.finished;
        await headerAni.finished;
        await viewControlsAni.finished;

        this.showOverlay = false
      }
      else {
        this.showOverlay = true
        if (overlayAni) overlayAni.reverse();
        if (headerAni) headerAni.reverse();
        if (viewControlsAni) viewControlsAni.reverse();
        if (playControlsAni) playControlsAni.reverse()
      }
    },
    togglePlay: function(e) {
      e.stopPropagation();
      e.preventDefault();

      if (this.eventVideoUrl) this.togglePlayVideo()
      else this.togglePlaySnapshot()
    },
    togglePlayVideo: function () {

      if (this.video.paused || this.video.ended) {
        this.video.play();
        this.isPlaying = true
      } 
      else {
        this.video.pause();
        this.isPlaying = false
      }
    },
    togglePlaySnapshot: function () {
      if (!this.isPlaying) this.isPlaying = true
      else this.isPlaying = false
    },
    formatTime: function(timeInSeconds) {
      const result = new Date(timeInSeconds * 1000).toISOString().substring(11, 19);

      return {
        minutes: result.substring(3, 5),
        seconds: result.substring(6, 8),
      };
    },
    initializeVideo: function() {
      const videoDuration = Math.floor(this.video.duration)
      this.seek.setAttribute('max', videoDuration);
      this.progressBar.setAttribute('max', videoDuration);
      const time = this.formatTime(videoDuration);
      this.duration = `${time.minutes}:${time.seconds}`;
    },
    updateTimeElapsed: function() {
      const time = this.formatTime(Math.floor(this.video.currentTime));
      this.timeElapsed = `${time.minutes}:${time.seconds}`;
    },
    updateProgress: function() {
      this.seek.value = this.video.currentTime;
      this.progressBar.value = this.video.currentTime;
    },
    skipAhead: function(event) {
      let skipTo = event.target.dataset.seek
        ? event.target.dataset.seek
        : event.target.value;
      this.video.currentTime = skipTo;
      this.progressBar.value = skipTo;
      this.seek.value = skipTo;
    },
    onClickDownload: async function (e) {
      e.stopPropagation();
      e.preventDefault();

      var deviceName = 'No Device Name'
      var siteName = 'No Site Name'
      if (!this.site) return
      if (this.deviceName) deviceName = this.deviceName
      if (this.site.siteName) siteName = this.site.siteName
      var shareTime = this.$tool.getTimeStringForIOS(this.time)
      var fileName = siteName + '-' + deviceName + '-' + shareTime

      this.tool.fileWrite(this.eventVideoUrl, fileName, 'video')
    },
    onClickShare: async function (e) {
      e.stopPropagation();
      e.preventDefault();

      var deviceName = 'No Device Name'
      var siteName = 'No Site Name'
      if (!this.site) return
      if (this.deviceName) deviceName = this.deviceName
      if (this.site.siteName) siteName = this.site.siteName
      var shareTime = this.$tool.getTimeStringForIOS(this.time)
      var fileName = siteName + '-' + deviceName + '-' + shareTime

      this.tool.shareMedia(this.eventVideoUrl, fileName, 'video')
    },
    onClickClose: function () {
      let modals = document.getElementsByTagName("ion-modal")
      if (modals.length < 1) {
        return
      }
      modals[modals.length - 1].dismiss()
    },
    onClickPlaySpeed: function () {
      this.showPlaySpeedModal = true
    },
    showPreNextVideo: function (id) {
      if (this.eventType === 'log') {
        let log = this.siteActivityLog(id)

        this.eventId = log.eventInfo.eventId
        this.deviceName = log.deviceInfo.deviceName

        let tsTimeStr = moment(log.statusInfo.timestamp* 1000).tz(this.siteDisplayTimezone).format('LTS') // e.g) 오전 7:28:14
        let cdTimeStr = moment(log.statusInfo.createdDate).tz(this.siteDisplayTimezone).format('LTS') // e.g) 오전 7:28:14

        this.timeStr = tsTimeStr ?? cdTimeStr
      }
      else if (this.eventType === 'event') {
        let event = this.deviceEvents[id]
        if (!event) event = this.siteEvents[id]

        this.eventId = event?.eventId
        this.deviceName = event?.deviceInfo?.deviceName

        let tsTimeStr = moment(event.eventTimestamp* 1000).tz(this.siteDisplayTimezone).format('LTS') // e.g) 오전 7:28:14
        let cdTimeStr = moment(event.statusInfo.createdDate).tz(this.siteDisplayTimezone).format('LTS') // e.g) 오전 7:28:14

        this.timeStr = tsTimeStr ?? cdTimeStr
      }

      this.store.commit('OPEN_EVENT_VIDEO_VIEWER', {
        eventId:this.eventId, 
        timeStr:this.timeStr, 
        deviceName:this.deviceName
      })
    },
    onClickViewPreviousVideo: function () {
      this.isLoadingVideo = true
      this.hasVideo = false
      this.hasSnapshot = false

      this.beforeEnablePreNexButton()

      if (!this.eventListItems) return
      let isPassingCurrentEvent = false;

      for (let i = this.eventListItems.length - 1; i >= 0; i--) {
        let element = this.eventListItems[i];
        
        if (element.dataset.eventId === this.eventId) {
          if (element.dataset.index === '0') {
            this.isLoadingVideo = false
            alert('This is the first event')
            return
          }
          isPassingCurrentEvent = true;
          continue
        }

        // 현재 event의 이전 event를 재생하는데, video가 있는 event를 재생한다. 
        if (isPassingCurrentEvent && element.dataset.hasVideo === 'true') {
          if (this.eventType === 'log') this.showPreNextVideo(element.dataset.logId);
          else if (this.eventType === 'event') this.showPreNextVideo(element.dataset.eventId);

          break; // 루프를 완전히 중단
        }
      }

    },
    onClickViewNextVideo: function () {
      this.isLoadingVideo = true
      this.hasVideo = false
      this.hasSnapshot = false

      this.beforeEnablePreNexButton()

      if (!this.eventListItems) return

      let isPassingCurrentEvent = false

      for (let i = 0; i < this.eventListItems.length; i++) {
        let element = this.eventListItems[i];
        
        if (element.dataset.eventId === this.eventId) {
          if (element.dataset.index === this.eventListItems.length-1 + '') {
            this.isLoadingVideo = false
            alert('This is the first event')
            return
          }
          isPassingCurrentEvent = true;
          continue
        }

        // 현재 event의 이전 event를 재생하는데, video가 있는 event를 재생한다. 
        if (isPassingCurrentEvent && element.dataset.hasVideo === 'true') {
          if (this.eventType === 'log') this.showPreNextVideo(element.dataset.logId);
          else if (this.eventType === 'event') this.showPreNextVideo(element.dataset.eventId);

          break; // 루프를 완전히 중단
        }
      }
    },
    onEmitChangeSpeed: function (playSpeed) {
      if (!this.video) return 

      this.video.playbackRate = playSpeed
      this.playSpeed = playSpeed
    },
    onEmitCloseSpeedModal: function () {
      this.showPlaySpeedModal = false
    },
    swipeToClose: function (targetEl) {
      if (!targetEl) return;

      let touchStartY = 0;
      let currentTouchY = 0;
      let touchStartTime = 0;
      let touchEndTime = 0;
      const sensitivityThreshold = 50; // 움직임 민감도 임계값
      const maxSpeedThreshold = 1.5; // 최대 속도 임계값 (px/ms)
      const minCloseThreshold = 0.1; // 최소 닫힘 비율 (화면 높이의 10%)
      const maxCloseThreshold = 0.33; // 최대 닫힘 비율 (화면 높이의 33%)

      const element = targetEl;

      const calculateSpeed = () => {
        const timeElapsed = touchEndTime - touchStartTime; // 시간 경과 계산 (ms)
        const deltaY = currentTouchY - touchStartY; // Y축 이동 거리 계산 (px)
        return Math.abs(deltaY / timeElapsed); // 속도 계산 (px/ms)
      };

      const checkMovement = () => {
        const elementRect = element.getBoundingClientRect();
        const speed = calculateSpeed();

        // 속도에 따라 닫힘 비율 조정 속도가 빠를스록 닫힘 비율이 줄어든다.
        const closeThreshold = Math.max(minCloseThreshold, maxCloseThreshold - (speed / maxSpeedThreshold) * (maxCloseThreshold - minCloseThreshold));

        if (elementRect.top > (window.innerHeight * closeThreshold)) {
          this.onClickClose();
        } else {
          resetElementPosition();
        }
      };

      const resetElementPosition = () => {
        element.style.transform = ``;
        targetEl.style.transition ="transform .1s"
      };

      const moveElement = () => {
        let deltaY = currentTouchY - touchStartY;
        if (deltaY > sensitivityThreshold) {
          element.style.transform = `translateY(${deltaY}px)`;
        }
      };

      const isOkayToAction = (e) => {
        if (e.touches.length > 1) return false;
        if (this.zooming > 1) return false;
        if (this.isFullscreen) return false;
        if (e.target.closest('#--chekt-video-viewer-play-speed-dialog')) return false;

        return true;
      };

      targetEl.addEventListener('touchstart', (e) => {
        if (!isOkayToAction(e)) return;
        touchStartY = e.changedTouches[0].screenY;
        touchStartTime = new Date().getTime(); // 터치 시작 시간 기록
      }, false);

      targetEl.addEventListener('touchmove', (e) => {
        if (!isOkayToAction(e)) return;
        currentTouchY = e.changedTouches[0].screenY;
        moveElement();
      }, false);

      targetEl.addEventListener('touchend', (e) => {
        if (!isOkayToAction(e)) return;
        touchEndTime = new Date().getTime(); // 터치 종료 시간 기록
        checkMovement();
      }, false);
    }

  }
}
</script>

<style scoped>

.overlay {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, .6);
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.header {
  transform: translate3d(0, 0, 0);
}

.header-title {
  text-align: center;
  padding-top: 5px;
}
.header-title-main {
  font-size: 17px;
  font-weight: 600;
}
.header-title-sub {
  font-size: 14px;
  font-weight: 400;
  opacity: .6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 20px;
}

.view-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.zoom {
  position: absolute;
  z-index: 999999;
  top: 10px;
  transform: translate3d(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom.scale {
  border: #fff solid 1px;
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: 600;
}
.zoom.original {
  color: #fff;
  background-color: rgb(21, 21, 21, .7);
  border: rgba(255, 255, 255, 0.1) solid 1px;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 500;
  visibility: hidden;
}
.zoom.original.animate {
  visibility: visible;
  animation: fadeIn .2s ease-in-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.video-info-viewer {
  position: absolute;
  z-index: 999999;
  color: var(--ion-text-medium);
  top: 5px;
  left: 50%;
  transform: translate3d(-50%, 0, 0) ;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
}

.view:not(.fullscreen) {
  width: 100%;
}
.view.fullscreen {
  height: 100vh;
}

.view-controls {
  padding-top: 14px;
  padding-bottom: calc(14px + var(--ion-safe-area-bottom));
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  transform: translate3d(0, 0, 0);
}

.view-controls.hide {
  opacity: 0;
  pointer-events: none;
}

.progress {
  position: relative;
  height: 8.4px;
  margin-bottom: 10px;
  margin-left: calc(var(--ion-safe-area-left));
  margin-right: calc(var(--ion-safe-area-right));
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  width: 100%;
  height: 5px;
  pointer-events: none;
  position: absolute;
  top: 0;
}

progress::-webkit-progress-bar {
  background-color: #9e9e9e6e;
  border-radius: 10px;
  overflow: hidden;
}

progress::-webkit-progress-value {
  background: var(--ion-color-light);
}

progress::-moz-progress-bar {
  border: 1px solid var(--ion-color-light);
  background: var(--ion-color-light);
}

.seek {
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
  margin: 0;
  user-select: none;
}

.time {
  font-size: 13px;
  color: var(--ion-text-low);
}
.toggle-icon {
  height: auto;
}

.playing-animation {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 5px;
  background: transparent;
  cursor: pointer;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border-radius: 50%;
  -webkit-appearance: none;
  transition: all 0.3s ease;
}

input[type=range]::-webkit-slider-thumb {
  height:18px;
  width: 18px;
  border-radius: 18px;
  background: var(--ion-color-light);
  cursor: pointer;
  -webkit-appearance: none;
  /* margin-left: -1px; */
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 1.3px;
}

input[type=range]::-moz-range-thumb {
  height: 14px;
  width: 14px;
  border-radius: 50px;
  border: 1px solid var(--ion-color-light);
  background: var(--ion-color-light);
  cursor: pointer;
  margin-top: 5px;
}

input[type=range]:focus::-moz-range-track {
  outline: none;
}

input[type=range].volume::-moz-range-thumb {
  border: 1px solid #fff;
  background: #fff;
}

.hidden {
  display: none;
}

.no-event {
  width: 100vw;
  color:var(--ion-text-medium);
  text-align: center;
}


.slide-enter-active {
  animation: slide-in .2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.slide-leave-active {
  animation: slide-in .2s reverse;
}
@keyframes slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@media screen and (min-width: 1024px) {
  .view-container {
    max-height: 755px;
  }
  .viewWrapper {
    width: 100%;
  }
  .no-event {
    width: 100%;
    color:var(--ion-text-medium);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 100%;
  }
}
</style>
