import Vue from 'vue'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
} from './mutation-types'

let initialState = {
  isOnline: true,
  onlineStatusChangeCounter: 0,
  isApiRateLimitLocked: false,
  apiRateLimitLockCounter: 0,
  connectionStatusText: ''
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  isOnline: function (state) {
    return state.isOnline
  },
  isApiRateLimitLocked: function (state) {
    return state.isApiRateLimitLocked
  },
  onlineStatusChangeCounter: function (state) {
    return state.onlineStatusChangeCounter
  },
  apiRateLimitLockCounter: function (state) {
    return state.apiRateLimitLockCounter
  },
  connectionStatusText: function (state) {
    return state.connectionStatusText
  },
}

// actions
const actions = {
  initializeData: function () {
    return new Promise((resolve) => {
      // dispatch('something')
      resolve()
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    state = Vue.util.extend({}, initialState)
  },
  [PROCESS_API_SUCCESS]: function (state) {
    if (state.isOnline === false) {
      state.onlineStatusChangeCounter++
      Vue.logger.info('PROCESS_API_SUCCESS: ONLINE')
    }
    if (state.isApiRateLimitLocked === true) {
      state.apiRateLimitLockCounter++
      Vue.logger.info('PROCESS_API_SUCCESS: API rate limit unlocked')
    }
    state.isOnline = true
    state.isApiRateLimitLocked = false
    state.connectionStatusText = ''
  },
  [PROCESS_API_FAILURE]: function (state, {status, statusText, origin, err}) {
    let isOnline = false
    let isApiRateLimitLocked = false
    switch(status) {
      case 0:
        isOnline = false
        isApiRateLimitLocked = false
        state.connectionStatusText = `[status code: ${status}] offline: ${origin}: ${err}`
        break
      case 401:
        isOnline = true
        isApiRateLimitLocked = false
        state.connectionStatusText = `[status code: ${status}]: ${origin}: ${err}: ` + Vue.tool.parseToStringify(statusText)
        break
      case 403:
        isOnline = true
        isApiRateLimitLocked = false
        state.connectionStatusText = `[status code: ${status}]: ${origin}: ${err}: ` + Vue.tool.parseToStringify(statusText)
        break
      case 429:
        isOnline = true
        isApiRateLimitLocked = true
        state.connectionStatusText = `[status code: ${status}]: ${origin}: ${err}: API rate limit lock`
        break
      default:
        isOnline = true
        isApiRateLimitLocked = false
        state.connectionStatusText = `[status code: ${status}]: ${origin}: ${err}: unknown API failure: ` + Vue.tool.parseToStringify(statusText)
    }
    if (state.isOnline !== isOnline) {
      state.onlineStatusChangeCounter++
    }
    if (state.isApiRateLimitLocked !== isApiRateLimitLocked) {
      state.apiRateLimitLockCounter++
    }
    state.isOnline = isOnline
    Vue.logger.warn('PROCESS_API_FAILURE: ' + state.connectionStatusText)
    // Vue.tool.presentToast(state.connectionStatusText) //DISABLE - Faid API Toast message 
  },
}

export default {
  state,
  actions,
  getters,
  mutations
}
