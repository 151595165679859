import Vue from 'vue'

export default {
  sendPasswordResetEmail: function ({email}) {
    let apiDetailPath = '/dbconnections/change_password'
    let data = {
      'client_id': Vue.env.authClientID,
      'connection': Vue.env.authConnection,
      'email': email,
      'device': Vue.tool.parseToStringify(Vue.env.deviceInfo)
    }
    return Vue.http.post(Vue.env.authApiPath + apiDetailPath, data, { 'headers': {'Content-Type': 'application/json'} })
  },
  // e.g. response for `loginLegacy`
  // {
  //   "id_token": "eyJ...MoQ",
  //   "scope": "openid offline_access",
  //   "refresh_token": "eyJ...MoQ",
  //   "access_token": "eyJ...MoQ",
  //   "token_type": "bearer"
  // }
  // 400: invalid json
  // 400: {"error":"invalid_request","error_description":"missing username parameter"}
  // 401: {"error":"invalid_user_password","error_description":"Wrong email or password."}
  loginLegacy: function ({email, password}) {
    let apiDetailPath = '/oauth/ro'
    let data = {
      client_id: Vue.env.authClientID,
      connection: Vue.env.authConnection,
      scope: 'openid offline_access profile email',
      username: email,
      password: password,
      device: `${Vue.env.deviceInfo.model}-${Vue.env.deviceInfo.operatingSystem}-${Vue.env.deviceInfo.osVersion}`
    }
    return Vue.http.post(Vue.env.authApiPathLegacy + apiDetailPath, data, { 'headers': { 'Content-Type': 'application/json' } })
  },
  // Be careful `Rate limit` for `refreshIDToken`
  // Only ask for a new token when the token is expired
  // e.g. response for `refreshIDToken`
  // {
  //   "access_token": "eyJ...MoQ",
  //   "expires_in": 86400,
  //   "scope": "openid offline_access",
  //   "id_token": "eyJ...0NE",
  //   "token_type": "Bearer"
  // }
  // 403: {"error":"invalid_grant","error_description":"Unknown or invalid refresh token."}
  refreshTokens: function ({refreshToken}) {
    let apiDetailPath = '/oauth/token'
    let client_id = Vue.env.authClientID
    let data = `grant_type=refresh_token&client_id=${client_id}&refresh_token=${refreshToken}`
    return Vue.http.post(Vue.env.authApiPath + apiDetailPath, data, { 'headers': {'content-type': 'application/x-www-form-urlencoded'} })
  },
  // e.g.
  // {
  //   "token_type": "Bearer",
  //   "expires_in": 86400,
  //   "id_token": "eyJh..."
  // }
  // 400: invalid json
  // 400: {"error":"unsupported_grant_type","error_description":"urn:ietf:params:oauth:grant-type:jwt- is not supported"}
  // 400: {"error":"invalid_request","error_description":"Missing grant_type parameter"}
  // 400: {"error":"invalid_request","error_description":"Missing client_id parameter"}
  // 400: {"error":"invalid_request","error_description":"Missing id_token or refresh_token parameters"}
  // 401: {"error":"invalid_client","error_description":"Unknown client","statusCode":401}
  // 401: {"error":"invalid_refresh_token","error_description":"specified refresh_token does not exist","statusCode":401}
  refreshTokensLegacy: function ({refreshToken}) {
    let apiDetailPath = '/delegation'
    let data = {
      'client_id': Vue.env.authClientID,
      'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
      'refresh_token': refreshToken,
      'scope': 'profile email'
    }
    return Vue.http.post(Vue.env.authApiPathLegacy + apiDetailPath, data, { 'headers': {'Content-Type': 'application/json'} })
  },
  // !! `revoke refresh token` doesn't mean `logout`.
  // To give complete `logout` experience, need to remove `id_token` and `refresh_token` in the local storage.
  // 200: The Refresh Token is revoked, does not exist, or was not issued to the application making the revocation request. The response body is empty.
  // 400: The required parameters were not sent in the request ("error": "invalid_request").
  // 401: The request is not authorized ("error": "invalid_client"). Check that the application credentials (client_id and client_secret) are present in the request and hold valid values.
  revokeRefreshToken: function ({refreshToken}) {
    let apiDetailPath = '/oauth/revoke'
    let data = {
      'client_id': Vue.env.authClientID,
      'token': refreshToken,
    }
    return Vue.http.post(Vue.env.authApiPathLegacy + apiDetailPath, data, { 'headers': {'Content-Type': 'application/json'} })
  },
  // https://community.auth0.com/t/how-to-renew-id-token-based-on-a-refresh-token/6268
  // Based on the information you provided, more specifically the inclusion of user_metadata as a scope, it seems that you’re using a client application that has the OIDC Conformant toggle disabled (available on the advanced OAuth settings of the client application in your Auth0 Dashboard).
  // When you’re not using the OIDC conformant authentication 33 the refresh token obtained as part of an authorization code grant is meant to be used against the /delegation endpoint 58 if you want to obtain an ID token and not an access token.
  // If you want to use the /oauth/token endpoint to perform a refresh token based request then you’ll need to update your client application configuration so that the OIDC Conformant toggle is enabled. Have in mind that enabling OIDC compliant authentication, although being the currently recommended approach, introduces breaking changes so you should review the available documentation (https://auth0.com/docs/api-auth/tutorials/adoption#compare-differences-between-the-two-pipelines 52) before choosing to go down that route.
  // One of the breaking changes that would immediately affect you would be that user_metadata or any other non-OIDC claim would no longer be automatically included in the generated token just because you included it as a scope. In order to include custom information/claim in the token you would have to do it explicitly through rules and using namespaces claims; see (https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims 12).
  // If at this time is not feasible for you to move to OIDC compliant authentication, then you’ll need to call the delegation endpoint with the refresh token you have. This should return to you an ID token instead of an access token.
}
