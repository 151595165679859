export default {
  parseRelayData: function (data) {
    return {

      //General Info
      deviceId: data.device_id + "",
      relayId: data.id + "",
      name: data.name,
      cameraId: data.camera_id,
      channel: data.channel + "",

      // Icons
      normalIcon: data.normal_icon,
      energizedIcon: data.energized_icon,

      // Enable/Disable
      showRelay: !!data.enabled,
      showEnduserApp: !!data.shows_end_user_app,
      latchEnabled: data.latch_enabled,
      momentaryEnabled: data.momentary_enabled,
      momentaryDelayTimes: data.momentary_delay_times,

      // Label
      labelType: data.label_type,
      defaultNormalLabel: data.default_normal_label,
      defaultEnergizedLabel: data.default_energized_label,
      ManualNormalLabel: data.manual_normal_label,
      ManualEnergizedLabel: data.manual_energized_label,
    }
  },
  parseRelayStatusData: function (data) {
    let relaysStatus = {}
    if (!(data instanceof Array)) return
    for(let i=0; i < data.length; i++) {
      const relayStatus = data[i]
      if (!(relayStatus instanceof Object)) continue
      if (!(relayStatus.status instanceof Object)) continue
      const deviceId = relayStatus['device_id'] + ""
      const isOnline = !!relayStatus['is_online']
      const relays = relayStatus['status']
      const relayKeys = Object.keys(relays)
      for (let ii=0; ii < relayKeys.length; ii++) {
        if (relayKeys[ii] === 'doTrouble') continue
        const key = relayKeys[ii]
        const relay = relays[key]
        const channel = (+relay.key) + "" // 숫자로 만든다음 다시 string으로 만들기
        const uid = `${deviceId}-${channel}`

        // CHECK - status 
        switch (('' + relay.status).toLowerCase()) {
          case "open":
          case "energize":
          case "energized":
            relay.status = "open"
            break
          case "close":
          case "closed":
          case "deenergize":
          case "deenergized":
            relay.status = "closed"
            break
          default:
            console.log('unknown status of ' + relay.status)
          break
        }
        // FW 3.0 ~
        // latch & timer 둘다 enable 되어있을때는 relay.mode는 ""로 옴.
        // latch & timer 둘다 enable 되어있는데 둘중 하나를 동작시키면 동작시킨 모드가 relay.mode안에 들어감
        // 예를 들어 timer를 동작시키면 timer가 끝날때까지 relay.mode에 "timer"가 들어가있음. 끝나면 다시 ""로 바뀜.

        // FW ~ 3.0
        // relay.mode와 relay.modeDuration은 마지막 실행값을 가지고있음. timer 끝나면 초기화 시켜주는 작업필요.

        // CHECK - mode 
        switch (('' + relay.mode).toLowerCase()) {
          case "switch":
          case "latch":
            relay.mode = "latch"
            break
          case "momentary":
          case "timer":
            relay.mode = "timer"
            break
          case "":
            relay.mode = ""
            break
          default:
            console.log('unknown status of ' + relay.mode)
          break
        }

        // PREPARE - timer 남은시간 계산
        let requestedAtTimeStamp = new Date(relay.requestedAt).getTime()
        let requestEndsAtTimeStamp = requestedAtTimeStamp + (relay.modeDuration * 1000)
        let updatedAtTimeStamp = new Date(relay.updatedAt).getTime()

        // FW ~ 3.0 버전에서는 마지막 값을 계속 가지고 있기 때문에 timer가 끝낱을때 값을 초기화 시켜줘야함.
        if (updatedAtTimeStamp > requestEndsAtTimeStamp) relay.modeDuration = 0
        if (relay.mode === 'timer' && relay.modeDuration === 0) relay.mode = ""

        // SET - relayInfoStatus
        relaysStatus[uid] = {
          isOnline,
          'status': relay.status,
          'mode': relay.mode,
          'modeDuration': relay.modeDuration,
          'request': relay.request,
          'requestedAt': relay.requestedAt,
          'requestEndsAt': relay.requestEndsAt,
          'updatedAt': relay.updatedAt,
        }
      }
    }
    return relaysStatus
  },
}
