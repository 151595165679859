import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getSiteAllTTS: function ({siteId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/tts`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  sendSiteTTS: function ({siteId, ttsData, ttsMode}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/audios/output`
    let data = { 
      mode :ttsMode
     }

    // Mode 4    Custom audio data from s3
    // Mode 3    Custom audio data 출력
    // Mode 2    Custom Message 
    // Mode 1    Predefined Message 
    // No Mode   Predefined Message
    switch (ttsMode) {
      case 1:
        data['tts_id'] = ttsData
        break;
      case 2:
        data['text'] = ttsData
        break;
      case 3:
        data['base64_data'] = ttsData
        break;
      case 4:
        let inputInfo = {}
        let outputOptions	 = {}

        // CHECK - Is Object? 
        if (!(ttsData instanceof Object)) return alert(`Can't play live talkdown. Please try again later.`)

        // PARSE - Input Info
        inputInfo['mime_type'] = ttsData.mimeType
        inputInfo['audio_upload_id'] = ttsData.audioUploadId
        data['input_info'] = inputInfo

        // PARSE - Output Settings
        outputOptions['mime_type'] = 'audio/mp3'
        outputOptions['channels'] = 1
        outputOptions['bitrate'] = '128k'
        outputOptions['sample_rate'] = '16000'
        data['output_options'] = outputOptions
        break;                         
      default:
        break;
    }

    return Vue.http.post(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader(1000*60*2))
  },
  createAudioUploadURL: function ({siteId, mimeType}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/audio_upload_url`
    let data = { mime_type :mimeType }

    return Vue.http.post(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  uploadAudioDataToCloud: function ({audioUploadURL, audioData, mimeType}) {
    let apiDetailPath = audioUploadURL
    let data = audioData

    return Vue.http.put(apiDetailPath, data, { 'headers': {'Content-Type': mimeType} }, Vue.auth.getBearerHeader())
  },
}
