var moment = require('moment')//for get time
import Vue from 'vue'
import api from '@/apis/siteActivityLog'
import parser from '@/models/siteActivityLog'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  INIT_SITE_ACTIVITY_LOGS,
  GET_SITE_ACTIVITY_LOGS_REQUEST,
  GET_SITE_ACTIVITY_LOGS_SUCCESS,
  GET_SITE_ACTIVITY_LOGS_FAILURE,
  GET_SITE_ACTIVITY_LOG_AUDIO_URL_REQUEST,
  GET_SITE_ACTIVITY_LOG_AUDIO_URL_SUCCESS,
  GET_SITE_ACTIVITY_LOG_AUDIO_URL_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  // only logs in the selected site will be stored
  siteId: null,
  logs: null,
  logIds: null,
  logsAudioUrl: null,
  status: {
    getLogs: null,
    getLogsAudioUrl: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  siteActivityLog: function (state, getters) {
    return function (logId) {
      return getters.siteActivityLogs[logId]
    }
  },
  siteActivityLogs: function (state) {
    return state.logs || {}
  },
  siteActivityLogIds: function (state) {
    return state.logIds || []
  },
  siteFirstActivityLogId: function (state, getters) {
    if (getters.siteActivityLogIds.length < 1) return ''
    return getters.siteActivityLogIds[0]
  },
  siteLastActivityLogId: function (state, getters) {
    if (getters.siteActivityLogIds.length < 1) return ''
    return getters.siteActivityLogIds[getters.siteActivityLogIds.length - 1]
  },
  statusGetSiteActivityLogs: function (state) {
    return state.status.getLogs
  },
  siteLogsAudioUrl: function (state) {
    return state.logsAudioUrl || {}
  },
  siteLogAudioUrl: function (state, getters) {
    return function (logId) {
      if (!getters.siteLogsAudioUrl[logId]) return ''
      return getters.siteLogsAudioUrl[logId]['url']
    }
  },
  statusGetLogAudioUrl: function (state) {
    return state.status.getLogsAudioUrl
  },
}

// actions
const actions = {
  getSiteActivityLogs: function ({commit, getters}, {siteId, stime, etime, toGetNewLogs, eventTypes, siteTimezone, logPeriod}) {
    commit(GET_SITE_ACTIVITY_LOGS_REQUEST, {siteId})
    // SET - targetLogId
    let targetLogId = ''
    if (toGetNewLogs) targetLogId = getters.siteLastActivityLogId
    else targetLogId = getters.siteFirstActivityLogId
    // API CALL
    return new Promise((resolve, reject) => {
      api.getSiteActivityLogs({siteId, stime, etime, targetLogId, toGetNewLogs, eventTypes, siteTimezone, logPeriod}).then(
        res => {
          let data = res.body
          commit(GET_SITE_ACTIVITY_LOGS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ACTIVITY_LOGS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSiteActivityLogAudioUrl: function ({commit, getters}, {siteId, logId}) {

    // FILTER - 한번 요청한 audio activity log라면 호출하지 말고 있는걸 써라!
    if (getters.siteLogsAudioUrl.hasOwnProperty(logId)) {
      const maxValidTime = 1000*60*50 // 50분
      let url = getters.siteLogsAudioUrl[logId]
      if (url) {
        // URL을 받은지 50분이 지났으면 다시 받아온다 * 21/11/09 현재시점 URL 유효기간이 1시간임.
        if (url.created.getTime() > new Date().getTime() - maxValidTime) return
      }
    }

    commit(GET_SITE_ACTIVITY_LOG_AUDIO_URL_REQUEST, {siteId})
    // API CALL
    return new Promise((resolve, reject) => {
      api.getSiteActivityLogAudioUrl({siteId, logId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_ACTIVITY_LOG_AUDIO_URL_SUCCESS, {data, logId},)
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ACTIVITY_LOG_AUDIO_URL_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [INIT_SITE_ACTIVITY_LOGS]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_ACTIVITY_LOGS_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - site or dateKey changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.getLogs = "requested"
  },
  [GET_SITE_ACTIVITY_LOGS_SUCCESS]: function (state, {data}) {
    // CHECK - data is array
    if (!(data instanceof Array)) return

    // INIT
    let logs = {}
    let logIds = []

    // 정렬 오름차순
    data.sort((a,b) => new Date(a.created) - new Date(b.created))

    // filter duplicated logs
    let noDuplicatedArr = []
    let duplicatedArr = []

    // 1. devide duplicated & not duplicated logs
    for (let i = 0; i < data.length; i++) {
      const log = data[i];
      if (!noDuplicatedArr.some(e => e.event_id === log.event_id && e.event_id)) {
        noDuplicatedArr.push(log)
      }
      else duplicatedArr.push(log)
    }

    // 2. do something.. from duplicated logs 
    for (let j = 0; j < duplicatedArr.length; j++) {
      const duplicated = duplicatedArr[j];

      // Only show AI event log
      if (duplicated.desc_id == 32041) {
        for (let jj = 0; jj < noDuplicatedArr.length; jj++) {
          const noDuplicated = noDuplicatedArr[jj];
          if (noDuplicated.event_id === duplicated.event_id) {
            noDuplicatedArr[jj] = duplicated
          }
        }
      }

    }

    // 3. push in data
    data = noDuplicatedArr

    // PARSE
    for (let k = 0; k < data.length; k++) {
      const logData = data[k];
      const logInfo = parser.parseActivityLogData(logData)
      const logId = logInfo.logId
      if (logId) {
        logs[logId] = logInfo
        logIds.push(logId)
      }
    }
      
    // MERGE - logIds with existing logIds
    if (state.logIds instanceof Array) {
      state.logIds.map(logId => {
        if (logIds.indexOf(logId) < 0) logIds.push(logId)
      })
    }

    // SORT - without affecting `state.logIds`
    logIds.sort((a,b) => a-b)

    // SET - state.logIds
    Vue.set(state, 'logIds', logIds)

    // COMBINE existing logs with newly retrieved logs
    // CASE 1,2,3,4 - To prlog duplicated log retrieval
    if (!(state.logs instanceof Object)) state.logs = {}
    Object.assign(state.logs, logs)
    state.status.getLogs = "successful"
  },
  [GET_SITE_ACTIVITY_LOGS_FAILURE]: function (state) {
    state.status.getLogs = "failed"
  },
  [GET_SITE_ACTIVITY_LOG_AUDIO_URL_REQUEST]: function (state) {
    state.status.getLogsAudioUrl = "requested"
  },
  [GET_SITE_ACTIVITY_LOG_AUDIO_URL_SUCCESS]: function (state, {data, logId},) {
    // CHECK - logsAudioUrl is object  & is get url
    if (!data.signed_url) return
    if (!(state.logsAudioUrl instanceof Object)) state.logsAudioUrl = {}
    if (!(state.logsAudioUrl[logId] instanceof Object)) state.logsAudioUrl[logId] = {}

    // ACTION - Get Url
    state.logsAudioUrl[logId]['url'] = data.signed_url
    state.logsAudioUrl[logId]['created'] = new Date()
    state.status.getLogsAudioUrl = "successful"
  },
  [GET_SITE_ACTIVITY_LOG_AUDIO_URL_FAILURE]: function (state) {
    state.status.getLogsAudioUrl = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
