import Vue from 'vue'
import {
  RESET_STATE,
  SET_MODAL_OVERLAID,
  UNSET_MODAL_OVERLAID,
} from '@/store/mutation-types'

let initialState = {
  isModalOverlaid: false,
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  isModalOverlaid: function (state) {
    return state.isModalOverlaid
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [SET_MODAL_OVERLAID]: function (state) {
    state.isModalOverlaid = true
  },
  [UNSET_MODAL_OVERLAID]: function (state) {
    state.isModalOverlaid = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
