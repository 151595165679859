import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getOnvifRelayOutputsFromProxy: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=devicemgmt/GetRelayOutputs`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  getOnvifRelayOutputsFromProxyOld: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=deviceio/GetRelayOutputs`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },

  getOnvifRelayOutputOptionsFromProxy: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 
    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=deviceio/GetRelayOutputOptions`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },

  // SETs
  setRelayOutputSettingsFromProxy: function ({siteId, bridgeId, channel, relayToken, delayTime}) {
    // Monostable = momentary
    // Bistable = latch
    
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    const bridgeApiPathQueryOptions = `relay_output_token=${relayToken}&delay_time=PT${delayTime}S&mode=Monostable`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=deviceio/SetRelayOutputSettings&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  setRelayOutputSettingsFromProxyOld: function ({siteId, bridgeId, channel, relayToken, delayTime}) {
    // Monostable = momentary
    // Bistable = latch
    
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    const bridgeApiPathQueryOptions = `relay_output_token=${relayToken}&delay_time=PT${delayTime}S&mode=Monostable`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=devicemgmt/SetRelayOutputSettings&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },

  setOnvifRelayOutputStateFromProxy: function ({siteId, bridgeId, channel, relayToken, state}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    const bridgeApiPathQueryOptions = `relay_output_token=${relayToken}&logical_state=${(state)}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=devicemgmt/SetRelayOutputState&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  setOnvifRelayOutputStateFromProxyOld: function ({siteId, bridgeId, channel, relayToken, state}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    const bridgeApiPathQueryOptions = `relay_output_token=${relayToken}&logical_state=${(state)}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=deviceio/SetRelayOutputState&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
}