import { Device } from '@capacitor/device'

const EnvironmentPlugin = {
  install (Vue) {
    let environment = {
      origin: null,
      authClientID: null,
      authDomain: null,
      authApiPath: null,
      authApiPathLegacy: null,
      authConnection: null,
      authAudience: null,
      authScope: null,
      authCookieDomain: null,
      authTargetPath: null,
      apiPath: null,
      googleMapAPIKey: 'AIzaSyD33CRqwF-fO7Z9NioI9z98h0eDZ1la3cw', // Web
      deviceInfo: {},
      isProduction: false,
      updateEnvironment: function (isProd) {
        this.isProduction = isProd
        // console.log('updateEnvironment: ' + isProd)
        // PROD
        if (isProd) {
          this.authClientID = 'bHzjpwmWpjwdOukz2h7FM2u2ND5BPKFa'
          this.authApiPath = 'https://auth.chekt.com'
          this.authApiPathLegacy = 'https://chektcloud.auth0.com'
          this.authDomain = 'auth.chekt.com'
          this.authConnection = 'Username-Password-Authentication'
          this.authScope = 'openid offline_access'
          this.authCookieDomain = '.chekt.com'
          this.authTargetPath = 'account.chekt.com'
          this.authAudience = 'https://api.chekt.com'
          this.apiPath = 'https://api.chekt.com'
          return
        }
        // DEV
        this.authClientID = 'yeJIohNks41mYAiaID29JoxsZESOpEQ8'
        this.authApiPath = 'https://auth.chektdev.com'
        this.authApiPathLegacy = 'https://chektdev.auth0.com'
        this.authDomain = 'auth.chektdev.com'
        this.authConnection = 'Username-Password-Authentication'
        this.authScope = 'openid offline_access'
        this.authCookieDomain = '.chektdev.com'
        this.authTargetPath = 'account.chektdev.com'
        this.authAudience = 'https://api.chektdev.com'
        this.apiPath = 'https://api.chektdev.com'
        return
      },
    }

    // UPDATE - ENV to default - DEV
    environment.updateEnvironment(false)
    // environment.updateEnvironment(true) // for testing

    // UPDATE - deviceInfo
    let updateDeviceInfo = async function () {
      let deviceInfo = await Device.getInfo()
      environment.deviceInfo = deviceInfo
      switch (deviceInfo.platform) {
      case 'web':
        // environment.googleMapAPIKey = 'AIzaSyD33CRqwF-fO7Z9NioI9z98h0eDZ1la3cw' // web
        // e.g. http://localhost:8100
        environment.origin = window.location.origin
        break
      case 'android':
        environment.googleMapAPIKey = 'AIzaSyC0-KFzE-EpdSWNNS8VsXxZhDQhXkjVkHs' // android
        // e.g. com.chekt.app.v3:/
        // ---->com.chekt.app.v3://callback
        // e.g. capacitor://localhost:8100/callback
        environment.origin = 'com.chekt.app.v3:/'
        environment.origin = 'capacitor://localhost'
        break
      case 'ios':
        environment.googleMapAPIKey = 'AIzaSyCye-2kL50E2zh_jbMU5ZbrP59ilk6XrF4' // ios
        // e.g. com.chekt.app.v3:/
        // ---->com.chekt.app.v3://callback
        // e.g. capacitor://localhost:8100/callback
        environment.origin = 'com.chekt.app.v3:/'
        environment.origin = 'capacitor://localhost'
        break
      default:
        // e.g. com.chekt.app.v3:/
        // ---->com.chekt.app.v3://callback
        // e.g. capacitor://localhost:8100/callback
        environment.origin = 'com.chekt.app.v3:/'
        environment.origin = 'capacitor://localhost'
        break
      }
    }
    updateDeviceInfo()
    
    Vue.prototype.$env = environment
    Vue.env = environment
  }
}

export default EnvironmentPlugin
