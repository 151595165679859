const baseRoutes = [
  {
    path: '/settings/notifications',
    component: () => import("@/_routes/settings/notifications/Root"),
  },
]

const routes = baseRoutes

export default routes
