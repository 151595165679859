import Vue from 'vue'
import api from '@/apis/siteDeviceEventRecord'
import commonApi from '@/apis/common'
import parser from '@/models/siteEventRecord'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_EVENT_RECORDS_REQUEST,
  GET_EVENT_RECORDS_SUCCESS,
  GET_EVENT_RECORDS_FAILURE,
  GET_RECORD_MEDIA_REQUEST,
  GET_RECORD_MEDIA_SUCCESS,
  GET_RECORD_MEDIA_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  eventsRecords: {},
  recordsMedia: {},
  status: {
    getEventRecords: null,
    getRecordMedia: {},
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  eventRecord: function (state) {
    return function (eventId, recordId) {
      if (state.eventsRecords[eventId] === undefined) return undefined
      return state.eventsRecords[eventId][recordId]
    }
  },
  eventRecords: function (state) {
    return function (eventId) {
      return state.eventsRecords[eventId]
    }
  },
  eventsRecords: function (state) {
    return state.eventsRecords
  },
  statusGetEventRecords: function (state) {
    return state.status.getEventRecords
  },
  recordMedia: function (state) {
    return function (recordId) {
      return state.recordsMedia[recordId]
    }
  },
  recordsMedia: function (state) {
    return state.recordsMedia
  },
  statusGetRecordMedia: function (state) {
    return function (recordId) {
      return state.status.getRecordMedia[recordId]
    }
  },
}

// actions
const actions = {
  getEventRecords: function ({commit}, {siteId, deviceId, eventId}) {
    commit(GET_EVENT_RECORDS_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteDeviceEventRecords({siteId, deviceId, eventId}).then(
        res => {
          let data = res.body
          commit(GET_EVENT_RECORDS_SUCCESS, {data, eventId})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_EVENT_RECORDS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getRecordMedia: async function ({commit, getters}, {eventId, recordIndex, recordId, url}) {
    commit(GET_RECORD_MEDIA_REQUEST, {recordId})
    try {
      let res = await commonApi.downloadImage(url)
      const blob = res.body
      let codec = ''
      if (('' + getters.eventsRecords[eventId][recordIndex].format).toLowerCase() === 'mp4') {
        codec = await Vue.tool.getCodecFromMP4(blob)
      }
      commit(GET_RECORD_MEDIA_SUCCESS, {eventId, recordIndex, recordId, blob, codec})
    } catch (err) {
      commit(GET_RECORD_MEDIA_FAILURE, {recordId})
    }
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_EVENT_RECORDS_REQUEST]: function (state) {
    state.status.getEventRecords = "requested"
  },
  [GET_EVENT_RECORDS_SUCCESS]: function (state, {data, eventId}) {
    let records = []
    for (let i = 0; i < data.length; i++) {
      const recordData = data[i]
      let recordInfo = parser.parseRecordData(recordData)
      // UPDATE previous status info
      // otherwise, it will erase `codec` to null
      if (state.eventsRecords[eventId] instanceof Array) {
        if (state.eventsRecords[eventId][i] instanceof Object) {
          recordInfo.statusInfo = state.eventsRecords[eventId][i].statusInfo
        }
      }
      records.push(recordInfo)
    }
    Vue.set(state.eventsRecords, [eventId], records)
    state.status.getEventRecords = "successful"
  },
  [GET_EVENT_RECORDS_FAILURE]: function (state) {
    state.status.getEventRecords = "failed"
  },
  [GET_RECORD_MEDIA_REQUEST]: function (state, {recordId}) {
    Vue.set(state.status.getRecordMedia, [recordId], "requested")
  },
  [GET_RECORD_MEDIA_SUCCESS]: function (state, {eventId, recordIndex, recordId, blob, codec}) {
    // UPDATE - recordMedia
    let urlCreator = window.URL || window.webkitURL
    let mediaUrl = urlCreator.createObjectURL(blob)
    state.recordsMedia = {...state.recordsMedia, [recordId]: mediaUrl}
    Vue.set(state.status.getRecordMedia, [recordId], "successful")
    
    // UPDATE - eventRecord
    state.eventsRecords[eventId][recordIndex].statusInfo.codec = codec
    state.eventsRecords[eventId][recordIndex].statusInfo.completed = true
    state.eventsRecords[eventId][recordIndex].statusInfo.downloaded = true
    state.eventsRecords[eventId][recordIndex].statusInfo.reallyUploaded = true
  },
  [GET_RECORD_MEDIA_FAILURE]: function (state, {recordId}) {
    Vue.set(state.status.getRecordMedia, [recordId], "failed")
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
