
import Vue from 'vue'
export default {
  parseActivityLogData: function (data) {
    // filter out 'upload' event
    if (data.action == 'upload' && data.trigger != 'Schedule') return {}
    return {
      logId: data.id,
      logType: data.desc_id ? Vue.actlog.getDescType(data.desc_id) : null, // Description Id로 logType 정의하기
      logMeta: data.meta,
      logInfo: {
        descriptionId: data.desc_id,
        description: data.description,
        trigger: data.trigger,    // Bridge | Schedule | OE trigger
        statusText: data.status_text,
        action: data.action,     // armed | disarmed | alarm | restoral | upload |
        category: data.category,
        email: data.email,
        aiThumbnail: data.detection_summary,
      },
      deviceInfo: {
        deviceId: data.device_id,
        deviceName: data.name,
      },
      eventInfo: {
        eventId: data.event_id,
        eventCode: data.event_code, // contactID, xml signal
      },
      statusInfo: {
        createdDate: data.created ? new Date(data.created) : null,
        timestamp: data.event_timestamp
      }
    }
  },
}

// {
//   "trigger": "Bridge",
//   "action": "upload",
//   "category": "Video event"
// }