import Vue from 'vue'

export default {
  apiPrefix: '/api/v1.1/users',
  getEmailUser: function ({email}) {
    let apiDetailPath = this.apiPrefix + '/find?email=' + encodeURIComponent(email)
    return Vue.http.get(Vue.env.apiPath + apiDetailPath)
  },
  pingCloud: function () {
    let apiDetailPath = this.apiPrefix + '/me'
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getMyProfile: function () {
    let apiDetailPath = this.apiPrefix + '/me'
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  updateMyProfile: function ({firstName, lastName}) {
    let apiDetailPath = this.apiPrefix + '/me'
    let data = {
      firstname: firstName,
      lastname: lastName
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  changeMyEmail: function ({email}) {
    let apiDetailPath = this.apiPrefix + '/me/change_email_verify'
    let data = {
      email: email
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  changeMyPassword: function ({password}) {
    let apiDetailPath = this.apiPrefix + '/me/change_password'
    let data = {
      password: password
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
}
