import Vue from 'vue'
import api from '@/apis/siteEvent'
import parser from '@/models/siteEvent'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  INIT_SITE_EVENTS,
  GET_SITE_EVENT_REQUEST,
  GET_SITE_EVENT_SUCCESS,
  GET_SITE_EVENT_FAILURE,
  GET_SITE_EVENTS_REQUEST,
  GET_SITE_EVENTS_SUCCESS,
  GET_SITE_EVENTS_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  // only events in the selected site will be stored
  siteId: null,
  events: null,
  eventIds: null,
  eventId: null,
  status: {
    getEvent: null,
    getEvents: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  siteEvent: function (state, getters) {
    return function (eventId) {
      return getters.siteEvents[eventId]
    }
  },
  siteEvents: function (state) {
    return state.events || {}
  },
  siteEventIds: function (state) {
    return state.eventIds || []
  },
  siteFirstEventId: function (state, getters) {
    if (getters.siteEventIds.length < 1) return ''
    return getters.siteEventIds[0]
  },
  siteLastEventId: function (state, getters) {
    if (getters.siteEventIds.length < 1) return ''
    return getters.siteEventIds[getters.siteEventIds.length - 1]
  },
  statusGetSiteEvent: function (state) {
    return state.status.getEvent
  },
  statusGetSiteEvents: function (state) {
    return state.status.getEvents
  },
}

// actions
const actions = {
  getSiteEvent: function ({commit}, {siteId, eventId}) {
    commit(GET_SITE_EVENT_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteEvent({siteId, eventId}).then(
        res => {
          let data = res.body[0]
          commit(GET_SITE_EVENT_SUCCESS, {data})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_EVENT_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSiteEvents: function ({commit, getters}, {siteId, deviceId, stime, etime, eventTypes, eventStatus, toGetNewEvents}) {
    commit(GET_SITE_EVENTS_REQUEST, {siteId})
    // SET - targetEventId
    let targetEventId = ''
    if (toGetNewEvents) targetEventId = getters.siteLastEventId
    else targetEventId = getters.siteFirstEventId
    // API CALL
    return new Promise((resolve, reject) => {
      api.getSiteEvents({siteId, deviceId, stime, etime, eventTypes, eventStatus, targetEventId, toGetNewEvents}).then(
        res => {
          let data = res.body
          commit(GET_SITE_EVENTS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_EVENTS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [INIT_SITE_EVENTS]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_EVENT_REQUEST]: function (state) {
    state.status.getEvent = "requested"
  },
  [GET_SITE_EVENT_SUCCESS]: function (state, {data}) {
    if (!(state.events instanceof Object)) state.events = {}
    let eventInfo = parser.parseEventData(data)
    if (eventInfo.eventId) {
      state.events = {...state.events, [eventInfo.eventId]: eventInfo}
    }
    state.status.getEvent = "successful"
  },
  [GET_SITE_EVENT_FAILURE]: function (state) {
    state.status.getEvent = "failed"
  },
  [GET_SITE_EVENTS_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - site or dateKey changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.getEvents = "requested"
  },
  [GET_SITE_EVENTS_SUCCESS]: function (state, {data}) {
    // INIT
    let events = {}
    let eventIds = []

    // PARSE
    data.map(eventData => {
      const eventInfo = parser.parseEventData(eventData)
      const eventId = eventInfo.eventId
      if (eventId) {
        events[eventId] = eventInfo
        eventIds.push(eventId)
      }
    })

    // MERGE - eventIds with existing eventIds
    if (state.eventIds instanceof Array) {
      state.eventIds.map(eventId => {
        if (eventIds.indexOf(eventId) < 0) eventIds.push(eventId)
      })
    }

    // SORT - without affecting `state.eventIds`
    eventIds.sort((a,b) => a-b)

    // SET - state.eventIds
    Vue.set(state, 'eventIds', eventIds)

    // COMBINE existing events with newly retrieved events
    // CASE 1,2,3,4 - To prevent duplicated event retrieval
    if (!(state.events instanceof Object)) state.events = {}
    Object.assign(state.events, events)
    state.status.getEvents = "successful"
  },
  [GET_SITE_EVENTS_FAILURE]: function (state) {
    state.status.getEvents = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
