const baseRoutes = [
  {
    path: '/settings/members',
    component: () => import("@/_routes/settings/members/Root"),
  },
  {
    path: '/settings/members/:memberId',
    component: () => import("@/_routes/settings/members/Member")
  },
  {
    path: '/settings/members/:memberId/permission',
    component: () => import("@/_routes/settings/members/Permission")
  },
]

const routes = baseRoutes

export default routes
