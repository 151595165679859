import jwtDecode from 'jwt-decode'

export default {
  parseIDTokenData: function (data) {
    let decodedToken = jwtDecode(data.id_token)
    return {
      idToken: data.id_token,
      userIDinAuth0: decodedToken.sub, // // who the token refers to (e.g. auth0|584...)
      expiresAt: decodedToken.exp * 1000,
      expiresIn: +decodedToken.exp - +decodedToken.iat,
      email: decodedToken.email,
      lastPasswordReset: decodedToken.last_password_reset,
    }
  },
}
