
const videoWorker = new Worker('/js/video-processor.js');
const audioWorker = new Worker('/js/audio-processor-helper.js');


const WorkerPlugin = {
  install(Vue) {
    // 웹 워커를 Vue의 전역 속성으로 추가
    Vue.prototype.$videoworker = videoWorker;
    Vue.videoworker = videoWorker

    Vue.prototype.$audioworker = audioWorker;
    Vue.audioworker = audioWorker
  }
}


export default WorkerPlugin