import Vue from 'vue'
import api from '@/apis/siteArming'
import parser from '@/models/siteSecurity'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  ARMING_BUTTON_CLICKED,
  GET_SITE_SECURITY_REQUEST,
  GET_SITE_SECURITY_SUCCESS,
  GET_SITE_SECURITY_FAILURE,
  GET_SITES_SECURITY_REQUEST,
  GET_SITES_SECURITY_SUCCESS,
  GET_SITES_SECURITY_FAILURE,
  UPDATE_SITE_SECURITY_REQUEST,
  UPDATE_SITE_SECURITY_SUCCESS,
  UPDATE_SITE_SECURITY_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  siteId: null,
  sitesArmingInfo: null,
  isArmingButtonClicked: false,
  status: {
    getSiteArmingStatus: null,
    getSitesArmingStatus: null,
    updateSiteArmingStatus: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  isArmingButtonClicked: function (state) {
    return state.isArmingButtonClicked
  },
  siteArmingInfo: function (state, getters) {
    return function (siteId) {
      if (!getters.sitesArmingInfo) return
      return state.sitesArmingInfo[siteId]
    }
  },
  sitesArmingInfo: function (state) {
    return state.sitesArmingInfo
  },
  statusGetSiteArmingStatus: function (state) {
    return state.status.getSiteArmingStatus
  },
  statusGetSitesArmingStatus: function (state) {
    return state.status.getSitesArmingStatus
  },
  statusUpdateSiteArmingStatus: function (state) {
    return state.status.updateSiteArmingStatus
  },
}

// actions
const actions = {
  getSiteArmingStatus: function ({commit}, {siteId}) {
    commit(GET_SITE_SECURITY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteArmingStatus({siteId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_SECURITY_SUCCESS, {data, siteId})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_SECURITY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSitesArmingStatus: function ({commit}, siteIds) {
    commit(GET_SITES_SECURITY_REQUEST)

    let loopArray = []

    for (let i = 0; i < siteIds.length; i++) {
      const siteId = siteIds[i];
      let getSiteArmingStatusPromise = null

      getSiteArmingStatusPromise = 
        new Promise((resolve, reject) => {
          api.getSiteArmingStatus({siteId}).then(
            res => {
              let data = res.data
              // Always data will comes array
              // CHECK - No bridge in site
              if (data.length < 1) resolve([{'site_id': siteId}])
              else resolve(data)
            },
            err => {
              reject([{'site_id': siteId}])
            }
          )
        })

      loopArray.push(getSiteArmingStatusPromise)
    }

    return Promise.allSettled(loopArray)
    .then((data) => {
      commit(GET_SITES_SECURITY_SUCCESS, data)
    })

  },
  updateSiteArmingStatus: async function ({dispatch, commit, getters}, {siteId, armingMode}) {
    
    try { 
      let updateArmingMode 

      // 1 - ALWAYS ARMED 2 - ALWAYS DISARMED
      if (armingMode === 1) updateArmingMode = 'armed' 
      else if (armingMode === 2) updateArmingMode = 'disarmed'
      
      // arming mode를 변경 하기전에 현재 amring 상태를 체크해서,
      // 현재 arming mode와 요청하려는 arming mode가 같다면 alert창을 띄운다. 
      await dispatch('getSiteArmingStatus', {siteId})

      const currentArmingInfo = getters.siteArmingInfo(siteId)

      // 현재 arming mode === 변경 하려는 arming mode 라면...
      if (currentArmingInfo?.armingMode === updateArmingMode) {
        let result = confirm(`site is already ${currentArmingInfo?.armingMode}. Would you still like to try to ${armingMode === 1 ? 'arm' : 'disarm'} the site?`)
        if (!result) return
      }
    }
    catch(error) {
      // 만약 현재 arming mode를 가져오는데 실패 했다면...
      let result = confirm("Failed to check the current arming status of the site. Would you still like to try to change the arming status?");
      if (!result) return 
    }

    commit(UPDATE_SITE_SECURITY_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateSiteArmingStatus({siteId, armingMode}).then(
        () => {
          commit(UPDATE_SITE_SECURITY_SUCCESS)
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(UPDATE_SITE_SECURITY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [ARMING_BUTTON_CLICKED]: function (state, isArmingButtonClicked) {
    state.isArmingButtonClicked = isArmingButtonClicked
  },
  [GET_SITE_SECURITY_REQUEST]: function (state) {
    state.status.getSiteArmingStatus = "requested"
  },
  [GET_SITE_SECURITY_SUCCESS]: function (state, {data, siteId}) { 
    let securityInfo = parser.parseSecurityData(data)
    
    // bridge가 없는 경우 빈 Array로 왔을때 sitId 넣어주기.
    if (!securityInfo.siteId) securityInfo.siteId = siteId

    if (!state.sitesArmingInfo) state.sitesArmingInfo = {}
    state.sitesArmingInfo = {...state.sitesArmingInfo, [securityInfo.siteId]: securityInfo}
    state.status.getSiteArmingStatus = "successful"
  },
  [GET_SITE_SECURITY_FAILURE]: function (state) {
    state.status.getSiteArmingStatus = "failed"
  },
  [GET_SITES_SECURITY_REQUEST]: function (state) {
    state.status.getSitesArmingStatus = "requested"
  },
  [GET_SITES_SECURITY_SUCCESS]: function (state, data) {
    let securityInfos = {}
    let securityInfo = null
    
    for (let i = 0; i < data.length; i++) {
      // If api failed, parse "reason" object
      if (data[i].status === 'rejected') {securityInfo = parser.parseSecurityData(data[i].reason)}
      else {securityInfo = parser.parseSecurityData(data[i].value)}

      // Push data
      securityInfos[securityInfo.siteId] = securityInfo
    }
    state.sitesArmingInfo = securityInfos
    state.status.getSitesArmingStatus = "successful"
  },
  [GET_SITES_SECURITY_FAILURE]: function () {
    // * No failed status this all promise actions
  },
  [UPDATE_SITE_SECURITY_REQUEST]: function (state) {
    state.status.updateSiteArmingStatus = "requested"
  },
  [UPDATE_SITE_SECURITY_SUCCESS]: function (state) { 
    state.status.updateSiteArmingStatus = "successful"
  },
  [UPDATE_SITE_SECURITY_FAILURE]: function (state) {
    state.status.updateSiteArmingStatus = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
