export default {
  parseMemberData: function (data) {
    let invitationStatusName = 'unknown'
    switch (data.invitation_status) {
    case 1:
      invitationStatusName = 'invited'
      break
    case 2:
      invitationStatusName = 'accepted'
      break
    }
    return {
      memberId: data.user_id,
      memberEmail: data.email,
      memberName: data.firstname + ' ' + data.lastname,
      nameInfo: {
        firstName: data.firstname,
        lastName: data.lastname
      },
      authProviderInfo: {
        profilePictureUrl: data.provider_picture
      },
      permissionInfo: {
        role: data.role,
      },
      invitationStatusInfo: {
        invitationStatus: data.invitation_status,
        invitationStatusName: invitationStatusName,
      },
      statusInfo: {
        // createdDate: data.created ? new Date(data.created) : null,
        isSignedUp: !!data.is_signedup,
        invitedDate: data.invitation_time ? new Date(data.invitation_time) : null,
      },
    }
  },
  parseMenberRolesData: function (data) {
    let roles =  []
    let actions = []
    let resourceInfos = data.resource_infos
    let rolesData = data.roles

    // make actions
    for (let key in resourceInfos) { 
      if (!resourceInfos.hasOwnProperty(key)) continue
      resourceInfos[key]['role'] = key
      actions.push(resourceInfos[key])
    }

    // make roles
    for (let i = 0; i < rolesData.length; i++) {
      const role = rolesData[i];
      const obj = {}

      obj.name = role.name
      obj.desc = role.description
      obj.id = role.id
      obj.table = []

      for (let ii = 0; ii < role.permission_infos.length; ii++) {
        const permission = role.permission_infos[ii];
        const rsObj = {}
        
        for (let key in resourceInfos) {
          if (!resourceInfos.hasOwnProperty(key)) continue

          if (key == permission.resource) {
            rsObj.name = resourceInfos[key].name
            rsObj.mode = permission.mode
            rsObj.resource = permission.resource

            if (permission.mode == 0) rsObj.permission = 'no'
            else if (permission.mode == 1) rsObj.permission = 'view only'
            else if (permission.mode == 2) rsObj.permission = 'yes'

            obj.table.push(rsObj)
            break
          }
        }
      }

      roles.push(obj)
    }

    return { roles, actions }
  },
}
