import Vue from 'vue'
import api from '@/apis/site'
import parser from '@/models/site'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  INIT_SELECTED_SITE,
  SELECT_SITE,
  GET_SITE_REQUEST,
  GET_SITE_SUCCESS,
  GET_SITE_FAILURE,
  GET_SITES_REQUEST,
  GET_SITES_SUCCESS,
  GET_SITES_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  sites: {},
  siteIds: [],
  selectedSiteId: '',
  status: {
    getSite: null,
    getSites: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  site: function (state) {
    return function (siteId) {
      return state.sites[siteId]
    }
  },
  sites: function (state) {
    return state.sites
  },
  siteIds: function (state) {
    return state.siteIds
  },
  statusGetSite: function (state) {
    return state.status.getSite
  },
  statusGetSites: function (state) {
    return state.status.getSites
  },
  selectedSiteId: function (state) {
    return state.selectedSiteId
  },
  isSiteArmingScheduleEnabled: function (state, getters) {
    return function (siteId) {
      const site = getters.site(siteId)
      if (!(site instanceof Object)) return false
      if (!(site.statusInfo instanceof Object)) return false
      const isEnabled = site.statusInfo.armingScheduleEnabled
      return isEnabled ? isEnabled : false
    }
  },
  siteTimezone: function (state, getters) {
    return function (siteId) {
      const site = getters.site(siteId)
      if (!(site instanceof Object)) return "UTC"
      if (!(site.locationInfo instanceof Object)) return "UTC"
      const timezone = site.locationInfo.timezone
      return timezone ? timezone : "UTC"
    }
  },
  // timezone기준을 locale로 할것인지 site로 할것인지 여기에 정의한다.
  siteDisplayTimezone: function (state, getters) {
    return function (siteId) {
      const site = getters.site(siteId)
      if (!(site instanceof Object)) return "UTC"
      if (!(site.locationInfo instanceof Object)) return "UTC"
      const timezone = site.locationInfo.timezone
      return timezone ? timezone : "UTC"
    }
  },
}

// actions
const actions = {
  getSite: function ({commit}, {siteId}) {
    commit(GET_SITE_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSite({siteId}).then(
        res => {
          let data = res.body[0]
          commit(GET_SITE_SUCCESS, {data})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSites: function ({commit, dispatch}) {
    commit(GET_SITES_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSites().then(
        res => {
          let data = res.body
          commit(GET_SITES_SUCCESS, {data})
          dispatch('initSelectedSite')
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITES_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  initSelectedSite: async function ({commit, state}) {
    const idInStorage = await Vue.tool.storageGetItem('selectedSiteId')
    if (state.sites[idInStorage]) {
      commit(INIT_SELECTED_SITE, {siteId: idInStorage})
      return
    }
    const siteIds = Object.keys(state.sites)
    if (siteIds.length < 1) {
      commit(INIT_SELECTED_SITE, {siteId: ''})
      return
    }
    commit(INIT_SELECTED_SITE, {siteId: siteIds[0]})
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [INIT_SELECTED_SITE]: function (state, {siteId}) {
    // if already selected same id before.. ignore
    // because.. prevent for extra selectedSiteId watch action
    if (state.selectedSiteId + '' === siteId + '') return

    // add id
    state.selectedSiteId = siteId
  },
  [SELECT_SITE]: function (state, {siteId}) {
    if (!state.sites[siteId]) return
    state.selectedSiteId = siteId
    Vue.tool.storageSetItem('selectedSiteId', siteId + '')
  },
  [GET_SITE_REQUEST]: function (state) {
    state.status.getSite = "requested"
  },
  [GET_SITE_SUCCESS]: function (state, {data}) {
    let siteInfo = parser.parseSiteData(data)

    if (siteInfo.siteId) {
      state.sites = {...state.sites, [siteInfo.siteId]: siteInfo}
    }
    state.status.getSite = "successful"
  },
  [GET_SITE_FAILURE]: function (state) {
    state.status.getSite = "failed"
  },
  [GET_SITES_REQUEST]: function (state) {
    state.status.getSites = "requested"
  },
  [GET_SITES_SUCCESS]: function (state, {data}) {
    let sites = {}
    let siteIds = []
    data.map(siteData => {
      let siteInfo = parser.parseSiteData(siteData)
      if (siteInfo.siteId) {
        sites[siteInfo.siteId] = siteInfo
        siteIds.push(siteInfo.siteId)
      }
    })
    Vue.set(state, 'sites', sites)
    Vue.set(state, 'siteIds', siteIds)
    state.status.getSites = "successful"
  },
  [GET_SITES_FAILURE]: function (state) {
    state.status.getSites = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
