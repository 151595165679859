export default {
  parseTTSData: function (data) {
    return {
      ttsId: data.id,
      ttsType: data.type,
      ttsText: data.text,
      ttsUrl: data.url,
      ttsPlayingTime: data.playing_time,
      ttsSize: data.size,
      ttsUseType: data.tts_use_type
    }
  },

}
