import Vue from 'vue'
import api from '@/apis/siteTTS'
import parser from '@/models/siteTTS'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_SITE_ALL_TTS_REQUEST,
  GET_SITE_ALL_TTS_SUCCESS,
  GET_SITE_ALL_TTS_FAILURE,
  SEND_SITE_TTS_REQUEST,
  SEND_SITE_TTS_SUCCESS,
  SEND_SITE_TTS_FAILURE,
  CREATE_AUDIO_UPLOAD_URL_REQUEST,
  CREATE_AUDIO_UPLOAD_URL_SUCCESS,
  CREATE_AUDIO_UPLOAD_URL_FAILURE,
  UPDATE_AUDIO_DATA_TO_CLOUD_REQUEST,
  UPDATE_AUDIO_DATA_TO_CLOUD_SUCCESS,
  UPDATE_AUDIO_DATA_TO_CLOUD_FAILURE,
  CHANGE_OPEN_TTS_MODAL,
} from '@/store/mutation-types'

let initialState = {
  ttsInfos: null,
  audioUploadURLInfo: null,
  openedTTSModalType: 'general', // Defalt is show predefined modal
  status: {
    getSiteAllTTS:null,
    sendSiteTTS:null,
    sendSiteLiveTTS:null,
    createAudioUploadURL:null,
    updateAudioDataToCloud: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  siteTTSList: function (state) {
    return state.ttsInfos || []
  },
  audioUploadURLInfo: function (state) {
    return state.audioUploadURLInfo
  },
  statusGetSiteTTS: function (state) {
    return state.status.getSiteAllTTS
  },
  statusSendSiteTTS: function (state) {
    return state.status.sendSiteTTS
  },
  statusSendSiteLiveTTS: function (state) {
    return state.status.sendSiteLiveTTS
  },
  statusCreateAudioUploadURL: function (state) {
    return state.status.createAudioUploadURL
  },
  statusUpdateAudioDataToCloud: function (state) {
    return state.status.updateAudioDataToCloud
  },
  openedTTSModalType: function (state) {
    return state.openedTTSModalType
  },
}

// actions
const actions = {
  getSiteAllTTS: function ({commit}, {siteId}) {
    commit(GET_SITE_ALL_TTS_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteAllTTS({siteId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_ALL_TTS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ALL_TTS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  sendSiteTTS: function ({commit}, {siteId, ttsData, ttsMode}) {
    commit(SEND_SITE_TTS_REQUEST)
    return new Promise((resolve, reject) => {
      api.sendSiteTTS({siteId, ttsData, ttsMode}).then(
        res => {
          let data = res.body
          commit(SEND_SITE_TTS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(SEND_SITE_TTS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  sendSiteLiveTTS: async function ({commit, dispatch, getters}, {siteId, mimeType, audioData}) {
    commit(SEND_SITE_TTS_REQUEST)
    try {
      // 1 - audio upload url 만들기
      await dispatch('createAudioUploadURL', {siteId, mimeType})

      // 2 - audio url cloud에 upload 하기
      let audioUploadURL = getters.audioUploadURLInfo.audioUploadURL
      await dispatch('uploadAudioDataToCloud', {audioUploadURL, audioData, mimeType})
      
      // 3 - live tts 재생하기
      let audioUploadId = getters.audioUploadURLInfo.audioUploadId
      await dispatch('sendSiteTTS', { siteId, ttsData: { mimeType, audioUploadId }, ttsMode: 4,})
      
      // 4 - status 업데이트 하기
      commit(SEND_SITE_TTS_SUCCESS)
      return
    }
    catch(err) {
      commit(SEND_SITE_TTS_FAILURE)
      throw err
    }
  },
  createAudioUploadURL: function ({commit}, {siteId, mimeType}) {
    commit(CREATE_AUDIO_UPLOAD_URL_REQUEST)
    return new Promise((resolve, reject) => {
      api.createAudioUploadURL({siteId, mimeType}).then(
        res => {
          let data = res.body
          commit(CREATE_AUDIO_UPLOAD_URL_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(CREATE_AUDIO_UPLOAD_URL_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  uploadAudioDataToCloud: function ({commit}, {audioUploadURL, audioData, mimeType}) {
    commit(UPDATE_AUDIO_DATA_TO_CLOUD_REQUEST)
    return new Promise((resolve, reject) => {
      api.uploadAudioDataToCloud({audioUploadURL, audioData, mimeType}).then(
        res => {
          let data = res.body
          commit(UPDATE_AUDIO_DATA_TO_CLOUD_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(UPDATE_AUDIO_DATA_TO_CLOUD_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },

}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_ALL_TTS_REQUEST]: function (state) {
    state.status.getSiteAllTTS = "requested"
  },
  [GET_SITE_ALL_TTS_SUCCESS]: function (state, {data}) {
    let ttsInfos = []
    data.map(ttsData => {
      let ttsInfo = parser.parseTTSData(ttsData)
      ttsInfos.push(ttsInfo)
    })
    Vue.set(state, 'ttsInfos', ttsInfos)
    state.status.getSiteAllTTS = "successful"
  },
  [GET_SITE_ALL_TTS_FAILURE]: function (state) {
    state.status.getSiteAllTTS = "failed"
  },
  [SEND_SITE_TTS_REQUEST]: function (state) {
    state.status.sendSiteTTS = "requested"
  },
  [SEND_SITE_TTS_SUCCESS]: function (state) {
    state.status.sendSiteTTS = "successful"
  },
  [SEND_SITE_TTS_FAILURE]: function (state) {
    state.status.sendSiteTTS = "failed"
  },
  [CREATE_AUDIO_UPLOAD_URL_REQUEST]: function (state) {
    state.status.createAudioUploadURL = "requested"
  },
  [CREATE_AUDIO_UPLOAD_URL_SUCCESS]: function (state, {data}) {
    let Info = {}
    Info['audioUploadURL'] = data.audio_upload_url
    Info['audioUploadId'] = data.audio_upload_id
    state.audioUploadURLInfo = Info
    state.status.createAudioUploadURL = "successful"
  },
  [CREATE_AUDIO_UPLOAD_URL_FAILURE]: function (state) {
    state.status.createAudioUploadURL = "failed"
  },
  [UPDATE_AUDIO_DATA_TO_CLOUD_REQUEST]: function (state) {
    state.status.updateAudioDataToCloud = "requested"
  },
  [UPDATE_AUDIO_DATA_TO_CLOUD_SUCCESS]: function (state) {
    state.status.updateAudioDataToCloud = "successful"
  },
  [UPDATE_AUDIO_DATA_TO_CLOUD_FAILURE]: function (state) {
    state.status.updateAudioDataToCloud = "failed"
  },
  [CHANGE_OPEN_TTS_MODAL]: function (state, type) {
    // 1 - Predefined
    // 2 - Custom
    // 3 - Live
    state.openedTTSModalType = type
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
