const AuthPlugin = {
  install (Vue) {
    let auth = {
      idToken: null,
      email: null,
      getBearerHeader: function (timeout) {
        return { 'headers': { 'Authorization': 'Bearer ' + Vue.auth.idToken }, 'timeout': timeout > 1000 ? timeout : 30000 }
      },
      getIdTokenFromLocalStorage: async function () {
        const idTokenRaw = await Vue.tool.storageGetItem('id_token')
        if (!idTokenRaw) Vue.auth.idToken = null
        else Vue.auth.idToken = Vue.tool.decodeFromBase64Url(idTokenRaw)
        return Vue.auth.idToken
      },
      removeIdTokenFromLocalStorage: function () {
        Vue.tool.storageRemoveItem('id_token')
        Vue.logger.warn('removeIdTokenFromLocalStorage')
      },
      removeIdTokenFromAuthService: function () {
        Vue.auth.idToken = null
        Vue.logger.warn('removeIdTokenFromAuthService')
      },
      storeIdTokenToLocalStorage: function (token) {
        Vue.tool.storageSetItem('id_token', Vue.tool.encodeToBase64Url(token))
        Vue.auth.tokenExpirationTimeLeft(token) // just to use 'log message'
      },
      storeIdTokenToAuthService: function (token) {
        Vue.auth.idToken = token
      },
      storeIdToken: function (token) {
        Vue.auth.storeIdTokenToAuthService(token)
        Vue.auth.storeIdTokenToLocalStorage(token)
        Vue.logger.info('storeIdToken')
      },
      removeIdToken: function () {
        Vue.auth.removeIdTokenFromLocalStorage()
        Vue.auth.removeIdTokenFromAuthService()
      },
      getRefreshTokenFromLocalStorage: async function () {
        const refreshTokenRaw = await Vue.tool.storageGetItem('refresh_token')
        if (!refreshTokenRaw) return null
        else return Vue.tool.decodeFromBase64Url(refreshTokenRaw)
      },
      removeRefreshTokenFromLocalStorage: function () {
        Vue.tool.storageRemoveItem('refresh_token')
        Vue.logger.warn('removeRefreshTokenFromLocalStorage')
      },
      storeRefreshTokenToLocalStorage: function (token) {
        Vue.tool.storageSetItem('refresh_token', Vue.tool.encodeToBase64Url(token))
        Vue.logger.info('storeRefreshTokenToLocalStorage')
      },
      removeAllToken: function () {
        Vue.auth.removeIdToken()
        Vue.auth.removeRefreshTokenFromLocalStorage()
      },
      tokenExpirationTimeLeft: function (token) {
        let timeLeft = 0
        try {
          let decodedToken = Vue.tool.decodeJwtToken(token)
          let now = Vue.time.newDate()
          timeLeft = decodedToken.exp * 1000 - now.getTime()
        } catch (err) {
          Vue.logger.error('tokenExpirationTimeLeft')
          Vue.logger.error(err)
        }
        Vue.logger.info('hasTimeFromInternet: ' + !!Vue.time.currentDate)
        // Vue.logger.info(Vue.time.newDate())
        Vue.logger.info('id_token is valid for next {timeLeft} minutes.'.replace('{timeLeft}', '' + Math.round(timeLeft / 60000 + 0.5)))
        return timeLeft
      },
      authenticated: async function () {
        let isAuthenticated = false
        let idToken = await Vue.auth.getIdTokenFromLocalStorage()
        if (idToken) {
          try {
            if (+Vue.auth.tokenExpirationTimeLeft(idToken) > 0) isAuthenticated = true
          } catch (err) {
            isAuthenticated = false
          }
        }
        return isAuthenticated
      }
    }
    Vue.prototype.$auth = auth
    Vue.auth = auth
  }
}

export default AuthPlugin
