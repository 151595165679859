<template>
  <div>
    <ion-header>
      <ion-toolbar color="light">
        <ion-buttons slot="start">
          <ion-button v-on:click="onClickClose()">
            Cancel
          </ion-button>
        </ion-buttons>
        <ion-title>Terms and Conditions</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" v-on:click="onClickConfirm()">
            Confirm
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-content-with-safe-area-bottom">
      <div class="content">
        <p style="white-space:pre-wrap"> {{lastAgreementText}}</p>
        <br>
        <p>TO REVIEW THE CHEKT PRIVACY POLICY VISIT <a href="https://chekt.com/privacy-policy/" target="_blank" rel="stylesheet">WWW.CHEKT.COM</a></p>
      </div>
    </ion-content>

  </div>
</template>

<script>
export default {
  props: {
    store: { type: Object, default: function () { return {} } },
  },
  computed: {
    lastAgreementText: function () {
      return this.store.getters.lastAgreementText
    },
  },
  data: function () {
    return {
    }
  },
  watch: {
  },
  created: function () {
  },
  methods: {
    onClickClose: function () {
      let modals = document.getElementsByTagName("ion-modal")
      if (modals.length < 1) {
        return
      }
      modals[modals.length - 1].dismiss()
    },
    onClickConfirm: function () {
      // Emit data to agreement page
      this.$parent.$emit('confirm', false)
      this.onClickClose()
    }
  }
}
</script>

<style scoped>
ion-content{
  --ion-background-color:#ffffff;
}
.content {
  margin: 30px 20px;
  color: var(--ion-color-blue-gray-higher);
  font-size: 13px;
  line-height: 20px;
}
.br {
  height: 20px;
}
</style>
