import Vue from 'vue'
import api from '@/apis/agreement'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_LAST_AGREEMENT_INFO_REQUEST,
  GET_LAST_AGREEMENT_INFO_SUCCESS,
  GET_LAST_AGREEMENT_INFO_FAILURE,
  GET_AGREEMENT_REQUEST,
  GET_AGREEMENT_SUCCESS,
  GET_AGREEMENT_FAILURE,
  UPDATE_AGREEMENT_REQUEST,
  UPDATE_AGREEMENT_SUCCESS,
  UPDATE_AGREEMENT_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  lastAgreementText: null,
  isAgreementAccepted: false,
  lastAgreementTosId: null,
  agreementText: null,
  status: {
    getLastAgreementInfo: null,
    getAgreement: null
  }
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  lastAgreementText: function (state) {
    return state.lastAgreementText
  },
  agreementText: function (state) {
    return state.agreementText
  },
  isAgreementAccepted: function (state) {
    return state.isAgreementAccepted
  },
  lastAgreementTosId: function (state) {
    return state.lastAgreementTosId
  },
  statusGetLastAgreementInfo: function (state) {
    return state.status.getLastAgreementInfo
  },
  statusGetAgreement: function (state) {
    return state.status.getAgreement
  },
  statusUpdateAgreement: function (state) {
    return state.status.updateAgreement
  },
}

// actions
const actions = {
  getLastAgreementInfo: function ({commit}) {
    commit(GET_LAST_AGREEMENT_INFO_REQUEST)
    return new Promise((resolve, reject) => {
      api.getLastAgreementInfo().then(
        res => {
          let data = res.body
          commit(GET_LAST_AGREEMENT_INFO_SUCCESS, data)
          commit(PROCESS_API_SUCCESS)
          resolve()
        },
        err => {
          commit(GET_LAST_AGREEMENT_INFO_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getAgreement: function ({commit}, tosId) {
    commit(GET_AGREEMENT_REQUEST)
    return new Promise((resolve, reject) => {
      api.getAgreement(tosId).then(
        res => {
          let data = res.body
          commit(GET_AGREEMENT_SUCCESS, data)
          commit(PROCESS_API_SUCCESS)
          resolve()
        },
        err => {
          let data = err.body
          commit(GET_AGREEMENT_FAILURE, data)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  updateAgreement: function ({commit}, tosId) {
    commit(UPDATE_AGREEMENT_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateAgreement(tosId).then(
        res => {
          commit(UPDATE_AGREEMENT_SUCCESS)
          commit(PROCESS_API_SUCCESS)
          resolve()
        },
        err => {
          commit(UPDATE_AGREEMENT_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_LAST_AGREEMENT_INFO_REQUEST]: function (state) {
    state.status.getLastAgreementInfo = 'requested'
  },
  [GET_LAST_AGREEMENT_INFO_SUCCESS]: function (state, data) {

    if (!data) return

    // lastAgreementText
    state.lastAgreementText = data.text;
    // isAgreementAccepted
    state.isAgreementAccepted = data.is_accepted
    // lastAgreementTosId
    state.lastAgreementTosId = data.tos_id

    state.status.getLastAgreementInfo = 'successful'
  },
  [GET_LAST_AGREEMENT_INFO_FAILURE]: function (state) {
    state.status.getLastAgreementInfo = 'failed'
  },
  [GET_AGREEMENT_REQUEST]: function (state) {
    state.status.getAgreement = 'requested'
  },
  [GET_AGREEMENT_SUCCESS]: function (state, data) {
    state.agreementText = data.text
    state.status.getAgreement = 'successful'
  },
  [GET_AGREEMENT_FAILURE]: function (state) {
    state.status.getAgreement = 'failed'
  },
  [UPDATE_AGREEMENT_REQUEST]: function (state) {
    state.status.updateAgreement = 'requested'
  },
  [UPDATE_AGREEMENT_SUCCESS]: function (state) {
    state.status.updateAgreement = 'successful'
  },
  [UPDATE_AGREEMENT_FAILURE]: function (state) {
    state.status.updateAgreement = 'failed'
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
