import Vue from 'vue'

export default {
  isNumber(value) {
    return typeof value === 'number' && !isNaN(value) && isFinite(value);
  },

  apiPrefix: '/api/v2/sites',
  getOnvifSpeakerConfigFromProxy: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=media2/GetAudioOutputConfigurations`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  getOnvifSpeakerConfigFromProxyOld: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=media/GetAudioOutputConfigurations`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  getOnvifSpeakerConfigOptionsFromProxy: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=media2/GetAudioOutputConfigurationOptions`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  getOnvifSpeakerConfigOptionsFromProxyOld: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=media/GetAudioOutputConfigurationOptions`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  setOnvifSpeakerConfigFromProxy: function ({siteId, bridgeId, channel, audioOutputConfigToken, audioOutputToken, volume}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let bridgeApiPathQueryOptions = `audio_output_configuration_token=${audioOutputConfigToken}&audio_output_token=${audioOutputToken}&output_level=${volume}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=media2/SetAudioOutputConfiguration&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  setOnvifSpeakerConfigFromProxyOld: function ({siteId, bridgeId, channel, audioOutputToken, volume}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let bridgeApiPathQueryOptions = `audio_output_token=${audioOutputToken}&output_level=${volume}`;
    const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);

    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/request?request=media/SetAudioOutputConfiguration&options=${encodedUri}`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
}