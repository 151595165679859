import Vue from 'vue'
import api from '@/apis/siteArmingSchedule'
import parser from '@/models/siteArmingSchedule'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  UPDATE_SITE_ARMING_SCHEDULE_ENABLED_REQUEST,
  UPDATE_SITE_ARMING_SCHEDULE_ENABLED_SUCCESS,
  UPDATE_SITE_ARMING_SCHEDULE_ENABLED_FAILURE,
  GET_SITE_ARMING_SCHEDULES_REQUEST,
  GET_SITE_ARMING_SCHEDULES_SUCCESS, 
  GET_SITE_ARMING_SCHEDULES_FAILURE,
  CREATE_SITE_ARMING_SCHEDULE_REQUEST,
  CREATE_SITE_ARMING_SCHEDULE_SUCCESS, 
  CREATE_SITE_ARMING_SCHEDULE_FAILURE,
  UPDATE_SITE_ARMING_SCHEDULE_REQUEST,
  UPDATE_SITE_ARMING_SCHEDULE_SUCCESS, 
  UPDATE_SITE_ARMING_SCHEDULE_FAILURE,
  DELETE_SITE_ARMING_SCHEDULE_REQUEST,
  DELETE_SITE_ARMING_SCHEDULE_SUCCESS, 
  DELETE_SITE_ARMING_SCHEDULE_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  sitesArmingSchedules: {},
  sitesHasArmingSchedule: {},
  status: {
    updateSiteArmingScheduleEnabled: null,
    getSiteArmingSchedules: null,
    createSiteArmingSchedule: null,
    updateSiteArmingSchedule: null,
    deleteSiteArmingSchedule: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  sitesArmingSchedules: function (state) {
    return state.sitesArmingSchedules
  },
  siteArmingSchedules: function (state, getters) {
    return function (siteId) {
      return getters.sitesArmingSchedules[siteId]
    }
  },
  siteHasArmingSchedule: function (state) {
    return function (siteId) {
      return state.sitesHasArmingSchedule[siteId]
    }
  },
  updateSiteArmingScheduleEnabled: function (state) {
    return state.status.updateSiteArmingScheduleEnabled
  },
  statusGetSiteArmingSchedules: function (state) {
    return state.status.getSiteArmingSchedules
  },
  statusCreateSiteArmingSchedule: function (state) {
    return state.status.createSiteArmingSchedule
  },
  statusUpdateSiteArmingSchedule: function (state) {
    return state.status.updateSiteArmingSchedule
  },
  statusDeleteSiteArmingSchedule: function (state) {
    return state.status.deleteSiteArmingSchedule
  },
}

// actions
const actions = {
  updateSiteArmingScheduleEnabled: function ({commit}, {siteId, armingScheduleEnabled}) {
    commit(UPDATE_SITE_ARMING_SCHEDULE_ENABLED_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateSiteArmingScheduleEnabled({siteId, armingScheduleEnabled}).then(
        res => {
          let data = res.body
          commit(UPDATE_SITE_ARMING_SCHEDULE_ENABLED_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(UPDATE_SITE_ARMING_SCHEDULE_ENABLED_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSiteArmingSchedules: function ({commit}, {siteId}) {
    commit(GET_SITE_ARMING_SCHEDULES_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteArmingSchedules({siteId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_ARMING_SCHEDULES_SUCCESS, {data, siteId})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ARMING_SCHEDULES_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  createSiteArmingSchedule: function ({commit}, {siteId, scheduleArray}) {
    commit(CREATE_SITE_ARMING_SCHEDULE_REQUEST)
    return new Promise((resolve, reject) => {
      api.createSiteArmingSchedule({siteId, scheduleArray}).then(
        res => {
          let data = res.body
          commit(CREATE_SITE_ARMING_SCHEDULE_SUCCESS, data)
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(CREATE_SITE_ARMING_SCHEDULE_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  updateSiteArmingSchedule: function ({commit}, {siteId, armingScheduleId, time}) {
    commit(UPDATE_SITE_ARMING_SCHEDULE_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateSiteArmingSchedule({siteId, armingScheduleId, time}).then(
        res => {
          let data = res.body
          commit(UPDATE_SITE_ARMING_SCHEDULE_SUCCESS, data)
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(UPDATE_SITE_ARMING_SCHEDULE_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  deleteSiteArmingSchedule: function ({commit}, {siteId, armingScheduleId}) {
    commit(DELETE_SITE_ARMING_SCHEDULE_REQUEST)
    return new Promise((resolve, reject) => {
      api.deleteSiteArmingSchedule({siteId, armingScheduleId}).then(
        res => {
          let data = res.body
          commit(DELETE_SITE_ARMING_SCHEDULE_SUCCESS, data)
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(DELETE_SITE_ARMING_SCHEDULE_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  }
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [UPDATE_SITE_ARMING_SCHEDULE_ENABLED_REQUEST]: function (state) {
    state.status.updateSiteArmingScheduleEnabled = "requested"
  },
  [UPDATE_SITE_ARMING_SCHEDULE_ENABLED_SUCCESS]: function (state) {
    state.status.updateSiteArmingScheduleEnabled = "successful"
  },
  [UPDATE_SITE_ARMING_SCHEDULE_ENABLED_FAILURE]: function (state) {
    state.status.updateSiteArmingScheduleEnabled = "failed"
  },
  [GET_SITE_ARMING_SCHEDULES_REQUEST]: function (state) {
    state.status.getSiteArmingSchedules = "requested"
  },
  [GET_SITE_ARMING_SCHEDULES_SUCCESS]: function (state, {data, siteId}) {
    // Init
    let hasArmingSchedule = false
    const siteArmingSchedules = parser.parseArmingScheduleData(data)

    // PREPARE - hasArmingSchedule
    for (const weekday in siteArmingSchedules) {
      if (!(siteArmingSchedules[weekday] instanceof Array)) continue
      if (siteArmingSchedules[weekday].length < 1) continue
      hasArmingSchedule = true
      break
    }
    
    // UPDATE
    state.sitesHasArmingSchedule = {...state.sitesHasArmingSchedule, [siteId]: hasArmingSchedule}
    state.sitesArmingSchedules = {...state.sitesArmingSchedules, [siteId]: siteArmingSchedules}
    state.status.getSiteArmingSchedules = "successful"
  },
  [GET_SITE_ARMING_SCHEDULES_FAILURE]: function (state) {
    state.status.getSiteArmingSchedules = "failed"
  },
  [CREATE_SITE_ARMING_SCHEDULE_REQUEST]: function (state) {
    state.status.createSiteArmingSchedule = "requested"
  },
  [CREATE_SITE_ARMING_SCHEDULE_SUCCESS]: function (state) {
    state.status.createSiteArmingSchedule = "successful"
  },
  [CREATE_SITE_ARMING_SCHEDULE_FAILURE]: function (state) {
    state.status.createSiteArmingSchedule = "failed"
  },
  [UPDATE_SITE_ARMING_SCHEDULE_REQUEST]: function (state) {
    state.status.updateSiteArmingSchedule = "requested"
  },
  [UPDATE_SITE_ARMING_SCHEDULE_SUCCESS]: function (state) {
    state.status.updateSiteArmingSchedule = "successful"
  },
  [UPDATE_SITE_ARMING_SCHEDULE_FAILURE]: function (state) {
    state.status.updateSiteArmingSchedule = "failed"
  },
  [DELETE_SITE_ARMING_SCHEDULE_REQUEST]: function (state) {
    state.status.deleteSiteArmingSchedule = "requested"
  },
  [DELETE_SITE_ARMING_SCHEDULE_SUCCESS]: function (state) {
    state.status.deleteSiteArmingSchedule = "successful"
  },
  [DELETE_SITE_ARMING_SCHEDULE_FAILURE]: function (state) {
    state.status.deleteSiteArmingSchedule = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
