import Vue from 'vue'
import api from '@/apis/siteDevice'
import parser from '@/models/siteArmingMode'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_SITE_ARMING_MODES_REQUEST,
  GET_SITE_ARMING_MODES_SUCCESS,
  GET_SITE_ARMING_MODES_FAILURE,
  SET_SITE_DISARMED_MODE_REQUEST,
  SET_SITE_DISARMED_MODE_SUCCESS,
  SET_SITE_DISARMED_MODE_FAILURE,
  SET_SITE_ARMED_MODE_REQUEST,
  SET_SITE_ARMED_MODE_SUCCESS,
  SET_SITE_ARMED_MODE_FAILURE,
  SET_SITE_HOME_MODE_REQUEST,
  SET_SITE_HOME_MODE_SUCCESS,
  SET_SITE_HOME_MODE_FAILURE,
  SET_SITE_AWAY_MODE_REQUEST,
  SET_SITE_AWAY_MODE_SUCCESS,
  SET_SITE_AWAY_MODE_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  // only devices in the selected site will be stored
  siteId: null,
  disarmedDeviceIds: null,
  homeDeviceIds: null,
  awayDeviceIds: null,
  armedDeviceIds: null,
  status: {
    getArmingModes: null,
    setDisarmedMode: null,
    setArmedMode: null,
    setHomeMode: null,
    setAwayMode: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  siteDisarmedDeviceIds: function (state) {
    return state.disarmedDeviceIds instanceof Array ? state.disarmedDeviceIds : []
  },
  siteHomeDeviceIds: function (state) {
    return state.homeDeviceIds instanceof Array ? state.homeDeviceIds : []
  },
  siteAwayDeviceIds: function (state) {
    return state.awayDeviceIds instanceof Array ? state.awayDeviceIds : []
  },
  siteArmedDeviceIds: function (state) {
    return state.armedDeviceIds instanceof Array ? state.armedDeviceIds : []
  },
  statusSetDisarmedMode: function (state) {
    return state.status.setDisarmedMode
  },
  statusSetArmedMode: function (state) {
    return state.status.setArmedMode
  },
  statusSetHomeMode: function (state) {
    return state.status.setHomeMode
  },
  statusSetAwayMode: function (state) {
    return state.status.setAwayMode
  },
}

// actions
const actions = {
  getSiteArmingModes: function ({commit}, {siteId}) {
    commit(GET_SITE_ARMING_MODES_REQUEST, {siteId})
    return new Promise((resolve, reject) => {
      api.getSiteDevices({siteId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_ARMING_MODES_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ARMING_MODES_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  setSiteDisarmedMode: function ({commit}, {siteId, deviceIds}) {
    commit(SET_SITE_DISARMED_MODE_REQUEST, {siteId})
    return new Promise((resolve) => {
      commit(SET_SITE_DISARMED_MODE_SUCCESS, { deviceIds })
      resolve()
    })
  },
  setSiteArmedMode: function ({commit}, {siteId, deviceIds}) {
    commit(SET_SITE_ARMED_MODE_REQUEST, {siteId})
    return new Promise((resolve) => {
      commit(SET_SITE_ARMED_MODE_SUCCESS, { deviceIds })
      resolve()
    })
  },
  setSiteHomeMode: function ({commit}, {siteId, deviceIds}) {
    commit(SET_SITE_HOME_MODE_REQUEST, {siteId})
    return new Promise((resolve) => {
      commit(SET_SITE_HOME_MODE_SUCCESS, { deviceIds })
      resolve()
    })
  },
  setSiteAwayMode: function ({commit}, {siteId, deviceIds}) {
    commit(SET_SITE_AWAY_MODE_REQUEST, {siteId})
    return new Promise((resolve) => {
      commit(SET_SITE_AWAY_MODE_SUCCESS, { deviceIds })
      resolve()
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_ARMING_MODES_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.getArmingModes = "requested"
  },
  [GET_SITE_ARMING_MODES_SUCCESS]: function (state, {data}) {
    let modes = parser.parseArmingModeData(data)
    Vue.set(state, 'disarmedDeviceIds', modes['disarmed'])
    Vue.set(state, 'homeDeviceIds', modes['home'])
    Vue.set(state, 'awayDeviceIds', modes['away'])
    Vue.set(state, 'armedDeviceIds', modes['armed'])
    state.status.getArmingModes = "successful"
  },
  [GET_SITE_ARMING_MODES_FAILURE]: function (state) {
    state.status.getArmingModes = "failed"
  },
  [SET_SITE_DISARMED_MODE_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.setDisarmedMode = "requested"
  },
  [SET_SITE_DISARMED_MODE_SUCCESS]: function (state, {deviceIds}) {
    Vue.set(state, 'disarmedDeviceIds', deviceIds)
    state.status.setDisarmedMode = "successful"
  },
  [SET_SITE_DISARMED_MODE_FAILURE]: function (state) {
    state.status.setDisarmedMode = "failed"
  },
  [SET_SITE_ARMED_MODE_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.setArmedMode = "requested"
  },
  [SET_SITE_ARMED_MODE_SUCCESS]: function (state, {deviceIds}) {
    Vue.set(state, 'armedDeviceIds', deviceIds)
    state.status.setArmedMode = "successful"
  },
  [SET_SITE_ARMED_MODE_FAILURE]: function (state) {
    state.status.setArmedMode = "failed"
  },
  [SET_SITE_HOME_MODE_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.setHomeMode = "requested"
  },
  [SET_SITE_HOME_MODE_SUCCESS]: function (state, {deviceIds}) {
    Vue.set(state, 'homeDeviceIds', deviceIds)
    state.status.setHomeMode = "successful"
  },
  [SET_SITE_HOME_MODE_FAILURE]: function (state) {
    state.status.setHomeMode = "failed"
  },
  [SET_SITE_AWAY_MODE_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.setAwayMode = "requested"
  },
  [SET_SITE_AWAY_MODE_SUCCESS]: function (state, {deviceIds}) {
    Vue.set(state, 'awayDeviceIds', deviceIds)
    state.status.setAwayMode = "successful"
  },
  [SET_SITE_AWAY_MODE_FAILURE]: function (state) {
    state.status.setAwayMode = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
