import { addIcons } from 'ionicons'
import '@ionic/core/css/ionic.bundle.css'
import * as myicons from 'ionicons/icons'
(() => {
  let icons = {}
  Object.keys(myicons).map(key => {
    const name = key.replace(/[A-Z]/g, m => "-" + m.toLowerCase())
    icons[name] = myicons[key]
  })
  icons['chekt-disarmed-mode'] = `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M12 2L21 5.64L21 11.09C21 16.14,17.16 20.85,12 22C6.84 20.85,3 16.14,3 11.09L3 5.64L12 2ZM12 3.2L4.2 6.4L4.2 11.2C4.2 15.64,7.53 19.79,12 20.8C16.47 19.79,19.8 15.64,19.8 11.2L19.8 6.4L12 3.2Z'/><path  d='M9.8 8C10.9 8,11.8 8.75,11.8 9.67L11.8 11L11.04 11L11.04 9.67C11.04 9.1,10.48 8.63,9.8 8.63C9.12 8.63,8.56 9.1,8.56 9.67L8.56 11L7.8 11L7.8 9.67C7.8 8.75,8.7 8,9.8 8ZM10.4 11.5L14.5 11.5C14.78 11.5,15 11.72,15 12L15 15C15 15.28,14.78 15.5,14.5 15.5L10.5 15.5C10.22 15.5,10 15.28,10 15L10 11.9C10 11.68,10.18 11.5,10.4 11.5Z'/></svg>`
  icons['chekt-home-mode'] = `data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L21 5.64L21 11.09C21 16.14,17.16 20.85,12 22C6.84 20.85,3 16.14,3 11.09L3 5.64L12 2ZM12 3.2L4.2 6.4L4.2 11.2C4.2 15.64,7.53 19.79,12 20.8C16.47 19.79,19.8 15.64,19.8 11.2L19.8 6.4L12 3.2Z"/><path d="M12 8C13.1 8,14 8.75,14 9.67L14 11L13.24 11L13.24 9.67C13.24 9.1,12.68 8.63,12 8.63C11.32 8.63,10.76 9.1,10.76 9.67L10.76 11L10 11L10 9.67C10 8.75,10.9 8,12 8ZM10 11.5L14 11.5C14.28 11.5,14.5 11.72,14.5 12L14.5 15C14.5 15.28,14.28 15.5,14 15.5L10 15.5C9.72 15.5,9.5 15.28,9.5 15L9.5 12C9.5 11.72,9.72 11.5,10 11.5Z"/></svg>`
  icons['chekt-away-mode'] = `data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2L21 5.64L21 11.09C21 16.14,17.16 20.85,12 22C6.84 20.85,3 16.14,3 11.09L3 5.64L12 2ZM12 3.2L4.2 6.4L4.2 11.2C4.2 15.64,7.53 19.79,12 20.8C16.47 19.79,19.8 15.64,19.8 11.2L19.8 6.4L12 3.2Z"/><path d="M12 8.5L15.25 11.47L14.76 11.47L14.76 15L13.03 15L13.03 13.33C13.03 13.02,12.8 12.77,12.52 12.77L11.48 12.77C11.2 12.77,10.97 13.02,10.97 13.33L10.97 15L9.24 15L9.24 11.47L8.75 11.47L12 8.5Z"/></svg>`
  // console.log(icons)
  addIcons(icons)
})()
