<template>
  <div></div>
</template>

<script>
export default {
  name: 'auth-manager',
  computed: {
    statusHandleAuthentication: function () {
      return this.$store.getters.statusHandleAuthentication
    },
    statusLogin: function () {
      return this.$store.getters.statusLogin
    },
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated
    },
    idTokenExpiresAt: function () {
      return this.$store.getters.idTokenExpiresAt
    },
    amICHeKTAdmin: function () {
      return this.$store.getters.amICHeKTAdmin
    },
    myUserId: function () {
      return this.$store.getters.myUserId
    },
  },
  data: function () {
    return {
      isTokenRefreshedMoreThanOnce: false,
      refreshingToken$t: null,
      checkingAuthStatus$i: null,
      isAuthorized: null,
    }
  },
  watch: {
    statusLogin: function (now) {
      if (now === 'successful') {
        this.$logger.info('statusLogin: authCallback: Authenticated action')
        this.onAuthenticatedUser()
      }
    },
  },
  created: async function () {
    await this.init()
  },
  beforeDestroy: function () {
    this.stopCheckingAuthStatus()
    this.cancelRefreshingTokenTimeout()
  },
  methods: {
    init: async function () {
      this.$logger.info('MyAuth: init - WEB')
      // LOAD - last auth session
      //  - This should be ALWAYS FIRST!
      //  - set id_token in auth service before "Set Session" takes its place.
      await this.$store.dispatch('loadLastAuthSession')
      if (this.$router.currentRoute.path !== "/callback") {
        this.refreshTokens();
      }
    },
    onCheckSessionErr: function (err) {
      let errMessage = JSON.stringify(err);
      if (err.error === "login_required") {
        if (this.isTokenRefreshedMoreThanOnce) {
          alert("Login required: Login session timeout.");
        }
        this.login();
      } else {

        console.log('this is test time error');
        this.goToEmailAuth(errMessage)

        console.error(errMessage);
        alert(errMessage);
        if (confirm("Do you want to try login again?")) {
          this.login();
        } else {
          // do nothing
        }
      }
    },
    goToEmailAuth: function (errMessage) {
      let err = JSON.parse(errMessage)
      if (err.code === 'timeout') {
        const emailAuthUri = this.$env.currentDomain === 'console.chekt.com' ? 'email-auth.chekt.com' : 'email-auth.chektdev.com';
        const encodedCurrentUri = encodeURIComponent(`https://${this.$env.currentDomain}`);
        window.location.href = `https://${emailAuthUri}?redirect_uri=${encodedCurrentUri}`;
      }
    },
    refreshTokens: function () {
      this.$store.dispatch("checkSession").then(
        () => {
          console.info("refreshToken: Authenticated user");
          this.isTokenRefreshedMoreThanOnce = true;
          this.onAuthenticatedUser();
        },
        (err) => {
          console.info("refreshToken: Not authenticated user: " + err.error);
          this.onCheckSessionErr(err);
        }
      );
    },
    initRefreshingTokenTimeout: function () {
      this.cancelRefreshingTokenTimeout()
      // UPDATE - timeoutAfter
      let timeoutAfter = 0
      if (this.idTokenExpiresAt) {
        timeoutAfter = (this.idTokenExpiresAt - (new Date()).getTime()) - (1 * 60 * 1000) // 1 min before expiration
      }
      this.$logger.info('initRefreshingTokenTimeout: Refreshing id token after {timeLeft} minutes.'.replace('{timeLeft}', '' + Math.round(timeoutAfter / 60000 + 0.5)))
      // SET TIMEOUT - to refresh id_token
      this.refreshingToken$t = setTimeout(() => {
        this.$logger.info('initRefreshingTokenTimeout: id token will be refreshed')
        this.$store.dispatch('checkSession').then(
          () => {
            this.$logger.info('initRefreshingTokenTimeout: Authenticated user')
            this.initRefreshingTokenTimeout()
          },
          err => {
            this.$logger.info('initRefreshingTokenTimeout: Not authenticated user: ' + this.$tool.parseToStringify(err))
            this.onCheckSessionErr(err)
          }
        )
      }, timeoutAfter)
      // startCheckingAuthStatus is used for when the computer wakes up after sleeping
      // it is a backing up feature for initRefreshingTokenTimeout
      // because initRefreshingTokenTimeout doens't work after sleeping
      setTimeout(() => { this.startCheckingAuthStatus() }, 1000)
    },
    cancelRefreshingTokenTimeout: function () {
      if (this.refreshingToken$t) {
        this.$logger.info('cancelRefreshingTokenTimeout: Canceling the previous timeout request')
        clearTimeout(this.refreshingToken$t)
      }
    },
    startCheckingAuthStatus: function () {
      let checkingInterval = 1000 * 10
      this.stopCheckingAuthStatus()
      this.$logger.info('startCheckingAuthStatus')
      this.checkingAuthStatus$i = setInterval(async () => {
        if (!this.isAuthenticated()) {
          this.$logger.info('Check authenticated: No valid id_token!')
          this.refreshTokens()
          this.stopCheckingAuthStatus()
        }
      }, checkingInterval)
    },
    stopCheckingAuthStatus: function () {
      this.$logger.info('stopCheckingAuthStatus')
      if (this.checkingAuthStatus$i) {
        clearInterval(this.checkingAuthStatus$i)
        this.checkingAuthStatus$i = null
      }
    },
    login: function () {
      this.$store.dispatch("login");
    },
    onAuthenticatedUser: function () {
      this.$store.dispatch('getMyProfile').then(
        () => {
          this.$store.dispatch('getSites')
          this.onAuthorizedUser()
        },
        () => {
          this.onNotAuthorizedUser()
        }
      )
    },
    onAuthorizedUser: function () {
      this.$logger.info('onAuthorizedUser: action!')
      let isAuthorizedPrev = this.isAuthorized;
      this.isAuthorized = true

      // init refresh token timeout everytime authorized flag changes to true
      if (!isAuthorizedPrev) {
        this.initRefreshingTokenTimeout();
      }
    },
    onNotAuthorizedUser: function () {
      this.login()
      // this.$router.push({name: 'notAuthorized'})
    }
  }
}
</script>

<style scoped>
</style>
