export default {
  // Array ---> Object
  // Object[:deviceID] = []
  // result will look like below
  // {
  //   "56753": [
  //       {
  //           "site_id": 21147,
  //           "user_id": 1033,
  //           "source": "area",
  //           "id": "56753",
  //           ...
  //       },
  //       ...
  //   ],
  //   ...
  // }
  createParsableArray: function (data) {
    var result = {}
    for (let i=0; i<data.length; i++) {
      let id = data[i]["id"]
      if (!(result[id] instanceof Array)) result[id] = [] // INIT
      result[id].push(data[i])
    }
    return result
  },
  parseNotificationMemberData: function (data) {
    if (!(data instanceof Array)) return
    let di = {}
    let bypass = {}
    let deviceConnection = {}
    // var email = false
    // var push = false
    // var source = null
    for (let i=0; i<data.length; i++) {
      const notiData = data[i]
      
      // di
      if (notiData.source_event_type == 'di') {
        switch (notiData.notification_type) {
        case 'email':
          if (notiData["is_enabled"] == true) di['email'] = true
          else di['email'] = false
          break
        case 'enduser-app':
          if (notiData["is_enabled"] == true) di['push'] = true
          else di['push'] = false
          break
        }
      }

      // bypass
      if (notiData.source_event_type == 'swinger_bypass') {
        switch (notiData.notification_type) {
        case 'email':
          if (notiData["is_enabled"] == true) bypass['email'] = true
          else bypass['email'] = false
          break
        case 'enduser-app':
          if (notiData["is_enabled"] == true) bypass['push'] = true
          else bypass['push'] = false
          break
        }
      }

      // device connection
      if (notiData.source_event_type == 'device_connection') {
        switch (notiData.notification_type) {
        case 'email':
          if (notiData["is_enabled"] == true) deviceConnection['email'] = true
          else deviceConnection['email'] = false
          break
        case 'enduser-app':
          if (notiData["is_enabled"] == true) deviceConnection['push'] = true
          else deviceConnection['push'] = false
          break
        }
      }
      var deviceId = notiData["id"]
      // if (notiData["source"] == "area") {
      //   source = "area"
      // }
      // if (notiData["source"] == "device") {
      //   source = "device"
      // }
      // switch (notiData["notification_type"]) {
      // // CASE 1 - "email" notification_type
      // case "email":
      //   if (notiData["is_enabled"] == true) email = true
      //   break
      // // CASE 2 - "enduser-app" notification_type
      // case "enduser-app":
      //   if (notiData["is_enabled"] == true) push = true
      //   break
      // }
    }
    return {
      di,
      bypass,
      deviceConnection,
      deviceId
      // notificationType: data.notification_type,
      // isEnabled: data.is_enabled
    }
  },
  parseNotificationSiteData: function (data) {
    if (!(data instanceof Array)) return
    let arm = {}
    let di = {}
    let bypass = {}
    let deviceConnection = {}
    for (let i=0; i<data.length; i++) {
      const notiData = data[i]

      // arm
      if (notiData.source_event_type == 'arm') {
        switch (notiData.notification_type) {
        case 'email':
          if (notiData["is_enabled"] == true) arm['email'] = true
          else arm['email'] = false
          break
        case 'enduser-app':
          if (notiData["is_enabled"] == true) arm['push'] = true
          else arm['push'] = false
          break
        }
      }

      // di
      if (notiData.source_event_type == 'di') {
        switch (notiData.notification_type) {
        case 'email':
          if (notiData["is_enabled"] == true) di['email'] = true
          else di['email'] = false
          break
        case 'enduser-app':
          if (notiData["is_enabled"] == true) di['push'] = true
          else di['push'] = false
          break
        }
      }

      // bypass
      if (notiData.source_event_type == 'swinger_bypass') {
        switch (notiData.notification_type) {
        case 'email':
          if (notiData["is_enabled"] == true) bypass['email'] = true
          else bypass['email'] = false
          break
        case 'enduser-app':
          if (notiData["is_enabled"] == true) bypass['push'] = true
          else bypass['push'] = false
          break
        }
      }

      // device connection
      if (notiData.source_event_type == 'device_connection') {
        switch (notiData.notification_type) {
        case 'email':
          if (notiData["is_enabled"] == true) deviceConnection['email'] = true
          else deviceConnection['email'] = false
          break
        case 'enduser-app':
          if (notiData["is_enabled"] == true) deviceConnection['push'] = true
          else deviceConnection['push'] = false
          break
        }
      }

      // switch (notiData.source_event_type) {
      // case 'arm':
      //   sourceEventType = 'arm'
      //   break
      // case 'di':
      //   sourceEventType = 'di'
      //   break
      // }
    }
    
    return {
      arm,
      di,
      bypass,
      deviceConnection
    }
  },
}
