<template>
  <div></div>
</template>

<script>
export default {
  name: 'push-message-manager',
  computed: {
    selectedSiteId: function () {
      return this.$store.getters.selectedSiteId
    },
    myUserId: function () {
      return this.$store.getters.myUserId
    },
    pushInitRequest: function () {
      return this.$store.getters.pushInitRequest
    },
    pushResetRequest: function () {
      return this.$store.getters.pushResetRequest
    },
    statusGetSiteArmingStatus: function () {
      return this.$store.getters.statusGetSiteArmingStatus
    },
    siteMemberMePermission: function () {
      return this.$store.getters.siteMemberMePermission
    },
    isWebEnv: function () {
      return this.$env.deviceInfo.platform === 'web'
    }
  },
  data: function () {
    return {
    }
  },
  watch: {
    myUserId: async function (now, before) {
      if (!now) return
      if (now == before) return
      // userID changed
      if (this.isWebEnv) return
      await this.initPushInstance()
    },
    pushInitRequest: async function () { // for manual request (e.g. usually for debugging)
      if (this.isWebEnv) return
      await this.initPushInstance()
    },
    pushResetRequest: async function () { // for manual request (e.g. usually for debugging)
      if (this.isWebEnv) return
      await this.resetPushInstance()
    },
  },
  created: function () {
  },
  beforeDestroy: function () {
  },
  methods: {
    initPushInstance: async function () {
      return await this.$push.init(
        this.myUserId,
        this.onPushNotificationReceived,
        this.onPushNotificationReceivedFromWindow,
        this.onPushNotificationActionPerformed,
        this.onLocalNotificationReceived,
        this.onLocalNotificationActionPerformed,
      )
    },
    resetPushInstance: async function () {
      await this.$push.deleteFCMInstance()
      return await this.initPushInstance()
    },
    onPushNotificationReceived: async function (notification) {
      // alert('onPushNotificationReceived: ' + JSON.stringify(message))
      // console.log('Push action performed: ' + JSON.stringify(message))
      if (!(notification instanceof Object)) return
      if (!(notification.data instanceof Object)) return
      // this.onLocalNotificationReceived(notification)
      switch (('' + notification.data.event_type).toLowerCase()) {
      case 'arm':
        await this.$store.dispatch('getSiteArmingStatus',{siteId: this.selectedSiteId})
        // Because of DB sync
        await this.$tool.wait(5000)
        this.$store.dispatch('getSiteArmingStatus',{siteId: this.selectedSiteId})
        break
      }
      //푸쉬 받는곳
    },
    onPushNotificationReceivedFromWindow: async function (notification) {
      // alert('Push received(Window): ' + JSON.stringify(notification))
      // await this.$push.createLocalNotification(notification)
    },
    onPushNotificationActionPerformed: async function (notification) {
      // INIT - Data
      if (!(notification instanceof Object)) return
      if (!(notification.notification instanceof Object)) return
      if (!(notification.notification.data instanceof Object)) return
      let siteId = notification.notification.data.siteId
      let deviceId = notification.notification.data.deviceId
      let eventId = notification.notification.data.eventId
      let eventTime = notification.notification.data.created
      let deviceName = notification.notification.data?.device_name ?? 'No Name'
      let eventType = ('' + notification.notification.data.event_type).toLowerCase()
      let eventStatus = ('' + notification.notification.data.event_status).toLowerCase()

      // CHECK - Current Site
      let maxRetry = 5
      let retryInterval = 1000 * 1
      for (let i=0; i < maxRetry; i++) {
        if (this.selectedSiteId) break
        await this.$tool.wait(retryInterval)
      }
      if (this.selectedSiteId != siteId) this.$store.commit('SELECT_SITE', {siteId})

      // CHECK - Notification Type
      switch (eventType) {
      case 'arm':
        this.$router.push({path: '/tabs/security'})
        this.$store.dispatch('getSiteArmingStatus',{siteId: this.selectedSiteId})
        break
      case 'di':
        if (!this.siteMemberMePermission?.liveView) {
          this.$router.push({path: '/tabs/activity'})
          this.$store.commit('OPEN_EVENT_VIDEO_VIEWER', {eventId, timeStr:eventTime, deviceName})
          break
        }
        this.$router.push({path: '/tabs/cameras'})
        this.$router.push({path: `/camera/${deviceId}/view`})
        this.$store.commit('OPEN_EVENT_VIDEO_VIEWER', {eventId, timeStr:eventTime, deviceName})
        break
      case 'swinger_bypass':
        this.$router.push({path: '/tabs/security'})
        break
      case 'device_connection':
        // If Online
        if (eventStatus == 'active') {
          this.$router.push({path: '/tabs/cameras'})
          this.$router.push({path: `/camera/${deviceId}/view`})
        }
        // If Offline or others
        else this.$router.push({path: '/tabs/security'})
        break
      default:
        this.$router.push({path: '/tabs/security'})
        break
      }
    },
    onLocalNotificationReceived: async function (notification) {
      // alert('Local received: ' + JSON.stringify(notification))
      // await this.$push.createLocalNotification(notification)
    },
    onLocalNotificationActionPerformed: async function (notification) {
      // check data
      if (!(notification instanceof Object)) return
      if (!(notification.notification instanceof Object)) return
      let siteId = notification.notification.siteId
      let deviceId = notification.notification.deviceId
      let eventId = notification.notification.eventId
      let eventTime = notification.notification.created
      let deviceName = notification.notification?.device_name ?? 'No Name'
      // check site
      if (this.selectedSiteId != siteId) this.$store.commit('SELECT_SITE', {siteId})
      // check notification type
      switch (('' + notification.notification.eventType).toLowerCase()) {
      case 'arm':
        this.$router.push({path: '/tabs/security'})
        this.$store.dispatch('getSiteArmingStatus',{siteId: this.selectedSiteId})
        // await this.$tool.wait(300)
        // if(notification.notification.eventStatus == 'active') alert('Site is Armed')
        // if(notification.notification.eventStatus == 'inactive') alert('Site is Disarmed')
        break
      case 'di':
        if (!this.siteMemberMePermission?.liveView) {
          this.$router.push({path: '/tabs/activity'})
          this.$store.commit('OPEN_EVENT_VIDEO_VIEWER', {eventId, timeStr:eventTime, deviceName})
          break
        }
        this.$router.push({path: `/camera/${deviceId}/view`})
        this.$store.commit('OPEN_EVENT_VIDEO_VIEWER', {eventId, timeStr:eventTime, deviceName})
        break                  
      }
    },
  }
}
</script>

<style scoped>
</style>
