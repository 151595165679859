import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getSiteEventStatistics: function ({siteId, stime, etime}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/events/daily_statistics`
    let query = `?stime=${stime}&etime=${etime}`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  },
  // getSiteEventStatistics: function ({siteId, stime, etime, eventType}) {
  //   let apiDetailPath = this.apiPrefix + `/${siteId}/event_statistics`
  //   let query = `?stime=${stime}&etime=${etime}&type=${eventType}`
  //   return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  // },
}
