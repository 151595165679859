import Vue from 'vue'
import {
  RESET_STATE,
  CHANGE_CAMERAS_PER_ROW,
  UPDATE_PREFER_NO_NAME,
} from '@/store/mutation-types'

let initialState = {
  camerasPerRow: 3,
  preferNoName: false,
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  camerasPerRow: function (state) {
    return state.camerasPerRow
  },
  preferNoName: function (state) {
    return state.preferNoName
  }
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [CHANGE_CAMERAS_PER_ROW]: function (state, data) {
    state.camerasPerRow = parseInt(data)
  },
  [UPDATE_PREFER_NO_NAME]: function (state, data) {
    state.preferNoName = data
  }
}

export default {
  state,
  getters,
  mutations
}
