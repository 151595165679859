export default {
  parseSecurityData: function (data) {
    var armingMode = 'unknown'
    var networkStatus = 'unknown'
    var siteId = null
    var hasArmedBridge = null
    var hasDisarmedBridge = null

    var hasOfflineBridge = null
    var hasOnlineBridge = null

    // SITE ID
    for(let i=0; i < data.length; i++) {
      const armingData = data[i]
      if (armingData.site_id) {
        siteId = armingData.site_id
        break
      }
    }

    // NETWORK SATATUS
    for(let i=0; i < data.length; i++) {
      const armingData = data[i]
      if (!armingData.is_online) {
        hasOfflineBridge = true
        continue
      }
      else if (armingData.is_online) {
        hasOnlineBridge = true
        continue
      }
    }

    // ARMING MODE
    for(let i=0; i < data.length; i++) {
      const armingData = data[i]
      // CONTINUE - Ignore invalid type of armingData
      if (!(armingData instanceof Object)) continue

      // CONTINUE - Ignore offline bridges
      if (!armingData.is_online) continue

      // UPDATE - Each bridge arming mode
      switch (('' + armingData.last_armed_status).toLowerCase()) {
      case 'armed':
        hasArmedBridge = true
        break
      case 'disarmed':
        hasDisarmedBridge = true
        break
      }
    }
    
    // UPDATE - armingMode
    if (hasArmedBridge && hasDisarmedBridge) armingMode = "partially armed"
    else if (hasArmedBridge) armingMode = "armed"
    else if (hasDisarmedBridge) armingMode = "disarmed"

    // UPDATE - networkStatus
    if (hasOfflineBridge && hasOnlineBridge) networkStatus = "partially online"
    else if (hasOfflineBridge) networkStatus = "offline"
    else if (hasOnlineBridge) networkStatus = "online"

    return {
      armingMode: armingMode,
      networkStatus: networkStatus,
      siteId: siteId,
      data: data,
    }
  }
}

// [ARMING INFO]

// FW ~3.0 ///////////////

// Arming Type
// 0: voltage arming
// 1: always armed
// 2: always disarmed


// FW 3.0~ ///////////////

// Arming Type
// 0: high-voltage-arming
// 1: low-voltage-arming
// 2: chekt-user-arming

// Arming Mode
// 10: disarm
// 20: arm-stay
// 21: arm-stay-bypass
// 30: arm-away
// 31: arm-away-bypass












