import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getNotificationMemberMeStatus: function ({siteId, getWriteDB}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/notification_members/me` 
    let query = ""
    // switch (source) {
    //   case 'area':
    //     // FILTER - `arm` event type
    //     query += `?filter=source_event_type,arm`
    //     break
    //   case 'device':
    //     // FILTER - `di` event type
    //     query += `?filter=source_event_type,di`
    //     break
    // }
    if (getWriteDB) {
      // FILTER - use write DB
      query += `?form=local&sync=1`
    }
    return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  },
  getDeviceNotificationMemberMe: function ({siteId, source, deviceId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${deviceId}/notification_members/me` 
    // let query = ""
    // switch (source) {
    //   case 'area':
    //     // FILTER - `arm` event type
    //     query += `?filter=source_event_type,arm`
    //     break
    //   case 'device':
    //     // FILTER - `di` event type
    //     query += `?filter=source_event_type,di`
    //     break
    // }
    // // Prevent MISTAKE - no "source" provided
    // if (query == "" && deviceId) {
    //   // FILTER - `di` event type
    //   query += `?filter=source_event_type,di`
    // }
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getAllDeviceNotificationMemberMe: function ({siteId, source}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/0/notification_members/me` 
    // let query = ""
    // switch (source) {
    //   case 'area':
    //     // FILTER - `arm` event type
    //     query += `?filter=source_event_type,arm`
    //     break
    //   case 'device':
    //     // FILTER - `di` event type
    //     query += `?filter=source_event_type,di`
    //     break
    // }
    // Prevent MISTAKE - no "source" provided
    // if (query == "" && deviceId) {
    //   // FILTER - `di` event type
    //   query += `?filter=source_event_type,di`
    // }
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  updateNotificationMemberMeStatus: function ({siteId, isEnabled, source, sourceEventType, notificationType, }) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/notification_members/me`
    let data = {
      source: source, // area | device
      source_event_type: sourceEventType, // di | arm
      notification_type: notificationType, // email | enduser-app (푸쉬알람)
      is_enabled: isEnabled ? 1 : 0, // [0:DISABLE,1:ENABLE]
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  updateDeviceNotificationMemberMeStatus: function ({siteId, deviceId, isEnabled, source, sourceEventType, notificationType, }) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${deviceId}/notification_members/me`
    let data = {
      device_id: deviceId,
      source: source, // area | device
      source_event_type: sourceEventType, // di | arm
      notification_type: notificationType, // email | enduser-app (푸쉬알람)
      is_enabled: isEnabled ? 1 : 0, // [0:DISABLE,1:ENABLE]
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  }
  
}