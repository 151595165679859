import api from '@/apis/siteWebrtc'

class Peer {
  ice_servers = null
  connection = null
  channel_command = null
  channel_video = null
  channel_audio = null
  channel_audio_bc = null
  is_connected = false
  is_requesting = false
  is_audio_connected = false
  is_video_connected = false
  is_command_connected = false
  is_audio_bc_connected = false
  my_stream = null

  constructor({ ice_servers, my_stream, site_id, bridge_id}) {
    this.ice_servers = ice_servers
    this.my_stream = my_stream
    this.site_id = site_id
    this.bridge_id = bridge_id,
    this.video_data_callback = null;
    this.audio_data_callback = null;
    this.command_data_callback = null;
    this.connection_status_callback = null;
  }

  async connect() {
    try {
      this.is_requesting = true
      this.connection = new RTCPeerConnection({ iceServers: this.ice_servers })
      this._set_on_handlers()
      this._set_data_channels()
      await this._request_p2p()
      await this._wait_connected()
      this.is_requesting = false
    }
    catch (err) {
      console.log(err);
      this.is_requesting = false
    }
  }

  async close() {
    if (!this.connection) return
    this.connection.close()
    this.connection = null
    this.is_connected = false
    this.is_requesting = false
  }

  send_command(data) {
    if (!this.is_connected) return
    if (!this.channel_command) return
    if (!this.is_command_connected) return
    
    try {
      this.channel_command.send(data)
    } 
    catch (err) {
      console.log(err);
    }
  }

  set_stream(stream_url, media) {
    // media: "va" or "videa" or "audio" or "audio_bc"
    const request = { "request_type": "stream_setup", "request": { stream_url, media } }
    this.send_command(JSON.stringify(request))
  }

  play_stream(stime) {
    const request = { "request_type": "stream_play" }

    if (stime) {
      request.request = {stime}
    }

    this.send_command(JSON.stringify(request))
  }

  stop_stream() {
    const request = { "request_type": "stream_stop" }
    this.send_command(JSON.stringify(request))
  }

  play_playback(stime) {
    if (!stime) return
    
    const request = { "request_type":"stream_request", "request": { "request":"play", "stime":`${stime}`, "speed": "1"} }
    this.send_command(JSON.stringify(request))
  }

  pause_playback() {
    const request = { "request_type":"stream_request", "request":{ "request":"pause" } }
    this.send_command(JSON.stringify(request))
  }

  get_client() {
    const request = { "request_type":"stream_request", "request":{ "request":"get_client" } }
    this.send_command(JSON.stringify(request))
  }

  request_proxy(uri) {
    const request = { "request_type": "http_proxy", "request": { "uri":`${uri}`, "method": "GET" } }
    this.send_command(JSON.stringify(request))
  }

  send_audio_bc(data) {
    if (!this.is_connected) return
    if (!this.channel_audio_bc) return
    if (!this.is_audio_bc_connected) return

    try {
      this.channel_audio_bc.send(data)
    } 
    catch (err) {
      console.log(err);
    }
  }

  set_on_audio_data_callback(callback) {
    this.audio_data_callback = callback;
  }

  set_on_video_data_callback(callback) {
    this.video_data_callback = callback;
  }

  set_on_command_data_callback(callback) {
    this.command_data_callback = callback;
  }

  set_on_connection_status_callback(callback) {
    this.connection_status_callback = callback;
  }

  async _wait_connected(timeout = 5000) {
    return new Promise((resolve, reject) => {
      if (!this.connection) return reject(new Error('no peer connection'))
      if (this.is_connected) return resolve()
      setTimeout(() => reject(new Error('timeout')), timeout)
      this.connection.onconnectionstatechange = () => {
        this.is_connected = this.connection.connectionState === 'connected'
        if (this.is_connected) resolve()
      }
    })
  }

  _set_on_handlers() {
    if (!this.connection) return
    this.connection.onicegatheringstatechange = () => console.log("iceGatheringState:", this.connection.iceGatheringState)
    this.connection.oniceconnectionstatechange = () => {
      console.log("iceConnectionState:", this.connection.iceConnectionState)
      if (this.connection_status_callback) this.connection_status_callback(this.connection.iceConnectionState)
      switch (this.connection.iceConnectionState) {
        case 'disconnected':
        case 'failed':
        case 'closed':
          this.is_connected = false
          break;
      }
    }
    this.connection.onicecandidate = (event) => console.log(`candidate: ${event?.candidate?.candidate}`)
    this.connection.onicecandidateerror = (event) => console.log(`candidateError: ${event.errorCode}`)
    this.connection.onconnectionstatechange = () => {
      console.log("connectionState:", this.connection.connectionState)
      if (this.connection_status_callback) this.connection_status_callback(this.connection.connectionState)
      switch (this.connection.connectionState) {
        case 'connected':
          this.is_connected = true
          break;
        case 'disconnected':
        case 'failed':
        case 'closed':
          this.is_connected = false
          break;
      }
    }
  }

  _set_data_channels() {
    if (!this.connection) return
    this.channel_command = this._new_data_channel("command")
    this.channel_video = this._new_data_channel("video")
    this.channel_audio = this._new_data_channel("audio")
    this.channel_audio_bc = this._new_data_channel("audio_bc")
  }

  _new_data_channel(label) {
    if (!this.connection) return
    const channel = this.connection.createDataChannel(label)
    channel.onmessage = (event) => {
      switch (label) {
        case "command":
          if (this.command_data_callback) {
            try {
              const uint8Array = new Uint8Array(event.data);
              const stringData = new TextDecoder().decode(uint8Array);
              const data = JSON.parse(stringData)
              this.command_data_callback(data);
            }
            catch(err) {
              console.log(err);
            }
          }
          break;
        case "video":
          if (this.video_data_callback) {
            this.video_data_callback(event);
          }
          break;
        case "audio":
          if (this.audio_data_callback) {
            this.audio_data_callback(event);
          }
          break;
        case "audio_bc":
          break;
      }
    }
    channel.onopen = () => {
      console.log(`channel(${label}) open`)
      if (label === 'audio') this.is_audio_connected = true
      if (label === 'video') this.is_video_connected = true
      if (label === 'audio_bc') this.is_audio_bc_connected = true
      if (label === 'command') this.is_command_connected = true
    }
    channel.onclose = () => {
      console.log(`channel(${label}) close`)
      if (label === 'audio') this.is_audio_connected = false
      if (label === 'video') this.is_video_connected = false
      if (label === 'audio_bc') this.is_audio_bc_connected = false
      if (label === 'command') this.is_command_connected = false
    }
    return channel
  }


  async _gather_all_candidates() {
    return new Promise((resolve, reject) => {
      if (!this.connection) return reject(new Error('no peer connection'))
      this.connection.onicecandidate = (event) => {
        if (event.candidate === null) resolve();
      };
    })
  }

  async _request_p2p() {
    // PREPARE - offer
    await this.connection.setLocalDescription(await this.connection.createOffer())
    await this._gather_all_candidates()
    const offer = this.connection.localDescription

    // REQUEST - p2p
    const response = await api.createWebrtcPeer({
      siteId: this.site_id, 
      bridgeId: this.bridge_id, 
      iceServers: this.ice_servers, 
      myStream: this.my_stream,
      offer: offer, 
    })

    // SET - answer
    const answer = await response.json()
    await this.connection.setRemoteDescription(answer)
  }
}

export default Peer;