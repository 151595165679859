import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  updateSiteArmingScheduleEnabled: function ({siteId, armingScheduleEnabled}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}`
    let data = {
      arming_schedule_enabled: armingScheduleEnabled, // 0=disabled, 1=enabled
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  getSiteArmingSchedules: function ({siteId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/arming_schedules`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  createSiteArmingSchedule: function ({siteId, scheduleArray}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/arming_schedules`
    let data = scheduleArray
    return Vue.http.post(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  updateSiteArmingSchedule: function ({siteId, armingScheduleId, time}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/arming_schedules/${armingScheduleId}`
    let data = {
      start_time: time
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  deleteSiteArmingSchedule: function ({siteId, armingScheduleId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/arming_schedules/${armingScheduleId}`
    return Vue.http.delete(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
}