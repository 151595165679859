<template>
  <div class="page">
    <!-- backdrop -->
    <div class="backdrop" @click="onClickClose($event)"></div>

    <!-- content -->
    <div class="content">
      <div class="title">Playback Speed</div>
      <ion-list color="dark" class="list" style="background-color: transparent;">
        <ion-radio-group :compareWith="compareWith" :value="playSpeed" @ionChange="changeSpeed($event)">
          <ion-item style="--background:none;" v-for="(speed, i) in speedList" :key="i"> 
            <ion-radio color="light" :value="speed"></ion-radio> <div style="color:white;">{{ speed }}×</div>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    playSpeed: { type: Number, default: 1 },
  },
  data: function () {
    return {
      playingSpeed: null,
      speedList: [0.25, 0.5, 1, 1.25, 1.5, 1.75, 2]
    }
  },
  created: function () {
    this.playingSpeed = this.playSpeed
  },
  methods: {
    changeSpeed: function (e) {
      this.playingSpeed = parseFloat(e?.detail?.value)
      this.$emit('changeSpeed', this.playingSpeed)
      this.$emit('closeSpeedModal')
    },
    onClickClose: function (e) {
      e.preventDefault()
      e.stopPropagation()
      this.$emit('closeSpeedModal')
    },
    compareWith: function (o1, o2) {
      return o1.id === o2.id;
    },
  }
}
</script>

<style scoped>
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}
.content {
  position: absolute;
  padding: 10px 0px;
  background-color: #2b2b2b;
  border-radius: 10px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  max-height: 80%;
  width: calc(100% - 20px - var(--ion-safe-area-left) - var(--ion-safe-area-right));
  bottom: calc(10px + var(--ion-safe-area-bottom));
  margin-left: calc(10px + var(--ion-safe-area-left));
  margin-right: calc(10px + var(--ion-safe-area-right));
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
}
.title {
  padding: 5px 20px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}
.list {
  overflow: scroll;
}
.speed {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
</style>