import Vue from 'vue'
import {
  RESET_STATE,
  OPEN_EVENT_VIDEO_VIEWER,

  STORE_EVENT_VIDEO_URL_REQUEST,
  STORE_EVENT_VIDEO_URL_SUCCESS,
  STORE_EVENT_VIDEO_URL_FAILURE,
  STORE_EVENT_SNAPSHOT_URLS_REQUEST,
  STORE_EVENT_SNAPSHOT_URLS_SUCCESS,
  STORE_EVENT_SNAPSHOT_URLS_FAILURE,
  IS_FINISH_STORE_EVENT_VIDEO_AND_SNAPSHOTS_ROUTINE,
  CLEAR_EVENT_VIEW_INFO,
} from '@/store/mutation-types'

let initialState = { 
  event: null,
  eventVideoUrl: null,
  eventSnapshotUrls: null,
  isFinishStoreEventVideoAndSnapshotRoutine: null,
  status: {
    eventVideoUrl: null,
    eventSnapshotUrls: null,
  }
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  requestedEventForEventVideoViewer: function (state) {
    return state.event || {}
  },
  eventVideoUrl: function (state) {
    return state.eventVideoUrl
  },
  eventSnapshotUrls: function (state) {
    return state.eventSnapshotUrls || []
  },
  isFinishStoreEventVideoAndSnapshotRoutine: function (state) {
    return state.isFinishStoreEventVideoAndSnapshotRoutine
  },
  stautsEventVideoUrl: function (state) {
    return state.status.eventVideoUrl
  },
  statusEventSnapshotUrls: function (state) {
    return state.status.eventSnapshotUrls
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [OPEN_EVENT_VIDEO_VIEWER]: function (state, {eventId, timeStr, deviceName}) {
    if (!eventId) return
    
    Vue.set(state, 'event', {eventId, timeStr, deviceName})
  },
  [STORE_EVENT_VIDEO_URL_REQUEST]: function (state) {
    state.status.eventVideoUrl = 'requested'
  },
  [STORE_EVENT_VIDEO_URL_SUCCESS]: function (state, videoUrl) {
    state.status.eventVideoUrl = 'successful'
    state.eventVideoUrl = videoUrl
  },
  [STORE_EVENT_VIDEO_URL_FAILURE]: function (state) {
    state.status.eventVideoUrl = 'failed'
  },
  [STORE_EVENT_SNAPSHOT_URLS_REQUEST]: function (state) {
    state.status.eventSnapshotUrls = 'requested'
  },
  [STORE_EVENT_SNAPSHOT_URLS_SUCCESS]: function (state, snapshotUrls) {
    state.status.eventSnapshotUrls = 'successful'
    state.eventSnapshotUrls = snapshotUrls
  },
  [STORE_EVENT_SNAPSHOT_URLS_FAILURE]: function (state) {
    state.status.eventSnapshotUrls = 'failed'
  },
  [CLEAR_EVENT_VIEW_INFO]: function (state) {
    state.eventVideoUrl = null
    state.eventSnapshotUrls = null
    state.status.eventVideoUrl = null
    state.status.eventSnapshotUrls = null
  },
  [IS_FINISH_STORE_EVENT_VIDEO_AND_SNAPSHOTS_ROUTINE]: function (state, isFinish) {
    state.isFinishStoreEventVideoAndSnapshotRoutine = isFinish
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
