import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getSiteDevices: function ({siteId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getSiteDevice: function ({siteId, deviceId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${deviceId}`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  updateSiteDevicePrivacyMode: function ({siteId, deviceId, isEnabled}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${deviceId}/privacy`
    let data = {
      is_privacy: isEnabled ? 1 : 0 // [0:DISABLE,1:ENABLE]
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  }
}
