import Vue from 'vue'
import parser from '@/models/siteDelay'
import {
  RESET_STATE,
  GET_SITE_DELAYS_REQUEST,
  GET_SITE_DELAYS_SUCCESS,
  GET_SITE_DELAYS_FAILURE,
  SET_SITE_ENTRY_DELAY_REQUEST,
  SET_SITE_ENTRY_DELAY_SUCCESS,
  SET_SITE_ENTRY_DELAY_FAILURE,
  SET_SITE_EXIT_DELAY_REQUEST,
  SET_SITE_EXIT_DELAY_SUCCESS,
  SET_SITE_EXIT_DELAY_FAILURE,
  SET_SITE_BELL_DURATION_REQUEST,
  SET_SITE_BELL_DURATION_SUCCESS,
  SET_SITE_BELL_DURATION_FAILURE,
  SET_SITE_RECORDING_INTERVAL_REQUEST,
  SET_SITE_RECORDING_INTERVAL_SUCCESS,
  SET_SITE_RECORDING_INTERVAL_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  siteId: null,
  entryDelay: null,
  exitDelay: null,
  bellDuration: null,
  recordingInterval: null,
  delayOptions: [
    {name: 'None', sec: 1},
    {name: '30 Seconds', sec: 30},
    {name: '1 Minute', sec: 60},
    {name: '2 Minutes', sec: 60*2},
    {name: '3 Minutes', sec: 60*3},
    {name: '4 Minutes', sec: 60*4},
    {name: '5 Minutes', sec: 60*5},
  ],
  bellDurationOptions: [
    {name: '1 Minute', sec: 60},
    {name: '5 Minutes', sec: 60*5},
    {name: '10 Minutes', sec: 60*10},
    {name: '20 Minutes', sec: 60*20},
    {name: '30 Minutes', sec: 60*30},
    {name: '40 Minutes', sec: 60*40},
    {name: '50 Minutes', sec: 60*50},
    {name: '60 Minutes', sec: 60*60},
  ],
  recordingIntervalOptions: [
    {name: '20 Seconds', sec: 20},
    {name: '30 Seconds', sec: 30},
    {name: '40 Seconds', sec: 40},
    {name: '50 Seconds', sec: 50},
    {name: '1 Minute', sec: 60},
    {name: '5 Minutes', sec: 60*5},
    {name: '10 Minutes', sec: 60*10},
    {name: '30 Minutes', sec: 60*30},
  ],
  status: {
    getDelays: null,
    setEntryDelay: null,
    setExitDelay: null,
    setBellDuration: null,
    setRecordingInterval: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  siteEntryDelay: function (state) {
    return state.entryDelay
  },
  siteExitDelay: function (state) {
    return state.exitDelay
  },
  siteBellDuration: function (state) {
    return state.bellDuration
  },
  siteRecordingInterval: function (state) {
    return state.recordingInterval
  },  
  siteDelayOptions: function (state) {
    return state.delayOptions
  },
  siteBellDurationOptions: function (state) {
    return state.bellDurationOptions
  },
  siteRecordingIntervalOptions: function (state) {
    return state.recordingIntervalOptions
  },
  siteEntryDelayName: function (state, getters) {
    let name = ''
    for(let i=0; i<getters.siteDelayOptions.length; i++) {
      if (getters.siteDelayOptions[i].sec != getters.siteEntryDelay) continue
      name = getters.siteDelayOptions[i].name
    }
    if (!name) name = `${getters.siteEntryDelay} Seconds`
    return name
  },
  exitDelayName: function (state, getters) {
    let name = ''
    for(let i=0; i<getters.siteDelayOptions.length; i++) {
      if (getters.siteDelayOptions[i].sec != getters.siteExitDelay) continue
      name = getters.siteDelayOptions[i].name
    }
    if (!name) name = `${getters.siteExitDelay} Seconds`
    return name
  },
  bellDurationName: function (state, getters) {
    let name = ''
    for(let i=0; i<getters.siteBellDurationOptions.length; i++) {
      if (getters.siteBellDurationOptions[i].sec != getters.siteBellDuration) continue
      name = getters.siteBellDurationOptions[i].name
    }
    if (!name) name = `${getters.siteBellDuration} Seconds`
    return name
  },
  recordingIntervalName: function (state, getters) {
    let name = ''
    for(let i=0; i<getters.siteRecordingIntervalOptions.length; i++) {
      if (getters.siteRecordingIntervalOptions[i].sec != getters.siteRecordingInterval) continue
      name = getters.siteRecordingIntervalOptions[i].name
    }
    if (!name) name = `${getters.siteRecordingInterval} Seconds`
    return name
  },
  statusGetSiteDelays: function (state) {
    return state.status.getDelays
  },
  statusSetEntryDelay: function (state) {
    return state.status.setEntryDelay
  },
  statusSetExitDelay: function (state) {
    return state.status.setExitDelay
  },
  statusSetBellDuration: function (state) {
    return state.status.setBellDuration
  },
  statusSetRecordingInterval: function (state) {
    return state.status.setRecordingInterval
  },
}

// actions
const actions = {
  getSiteDelays: function ({commit}, {siteId}) {
    commit(GET_SITE_DELAYS_REQUEST, {siteId})
    return new Promise((resolve) => {
      const data = {
        entry_delay: 60,
        exit_delay: 40,
        bell_duration: 60*10,
        recording_interval: 10,
      }
      commit(GET_SITE_DELAYS_SUCCESS, {data})
      resolve()
    })
  },
  setSiteEntryDelay: function ({commit}, {siteId, entryDelay}) {
    commit(SET_SITE_ENTRY_DELAY_REQUEST, {siteId})
    return new Promise((resolve) => {
      commit(SET_SITE_ENTRY_DELAY_SUCCESS, { entryDelay })
      resolve()
    })
  },
  setSiteExitDelay: function ({commit}, {siteId, exitDelay}) {
    commit(SET_SITE_EXIT_DELAY_REQUEST, {siteId})
    return new Promise((resolve) => {
      commit(SET_SITE_EXIT_DELAY_SUCCESS, { exitDelay })
      resolve()
    })
  },
  setSiteBellDuration: function ({commit}, {siteId, bellDuration}) {
    commit(SET_SITE_BELL_DURATION_REQUEST, {siteId})
    return new Promise((resolve) => {
      commit(SET_SITE_BELL_DURATION_SUCCESS, { bellDuration })
      resolve()
    })
  },
  setSiteRecordingInterval: function ({commit}, {siteId, recordingInterval}) {
    commit(SET_SITE_RECORDING_INTERVAL_REQUEST, {siteId})
    return new Promise((resolve) => {
      commit(SET_SITE_RECORDING_INTERVAL_SUCCESS, { recordingInterval })
      resolve()
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_DELAYS_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.getDelays = "requested"
  },
  [GET_SITE_DELAYS_SUCCESS]: function (state, {data}) {
    const delayInfo = parser.parseDelayData(data)
    Vue.set(state, 'entryDelay', delayInfo.entryDelay)
    Vue.set(state, 'exitDelay', delayInfo.exitDelay)
    Vue.set(state, 'bellDuration', delayInfo.bellDuration)
    Vue.set(state, 'recordingInterval', delayInfo.recordingInterval)
    state.status.getDelays = "successful"
  },
  [GET_SITE_DELAYS_FAILURE]: function (state) {
    state.status.getDelays = "failed"
  },
  [SET_SITE_ENTRY_DELAY_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.setEntryDelay = "requested"
  },
  [SET_SITE_ENTRY_DELAY_SUCCESS]: function (state, {entryDelay}) {
    Vue.set(state, 'entryDelay', entryDelay)
    state.status.setEntryDelay = "successful"
  },
  [SET_SITE_ENTRY_DELAY_FAILURE]: function (state) {
    state.status.setEntryDelay = "failed"
  },
  [SET_SITE_EXIT_DELAY_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.setExitDelay = "requested"
  },
  [SET_SITE_EXIT_DELAY_SUCCESS]: function (state, {exitDelay}) {
    Vue.set(state, 'exitDelay', exitDelay)
    state.status.setExitDelay = "successful"
  },
  [SET_SITE_EXIT_DELAY_FAILURE]: function (state) {
    state.status.setExitDelay = "failed"
  },
  [SET_SITE_BELL_DURATION_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.setBellDuration = "requested"
  },
  [SET_SITE_BELL_DURATION_SUCCESS]: function (state, {bellDuration}) {
    Vue.set(state, 'bellDuration', bellDuration)
    state.status.setBellDuration = "successful"
  },
  [SET_SITE_BELL_DURATION_FAILURE]: function (state) {
    state.status.setBellDuration = "failed"
  },
  [SET_SITE_RECORDING_INTERVAL_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - siteId changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.setRecordingInterval = "requested"
  },
  [SET_SITE_RECORDING_INTERVAL_SUCCESS]: function (state, {recordingInterval}) {
    state.recordingInterval = recordingInterval
    state.status.setRecordingInterval = "successful"
  },
  [SET_SITE_RECORDING_INTERVAL_FAILURE]: function (state) {
    state.status.setRecordingInterval = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
