import Vue from 'vue'
import api from '@/apis/siteRelay'
import parser from '@/models/siteRelay'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_SITE_ALL_RELAY_REQUEST,
  GET_SITE_ALL_RELAY_SUCCESS,
  GET_SITE_ALL_RELAY_FAILURE,
  GET_SITE_ALL_RELAY_STATUS_REQUEST,
  GET_SITE_ALL_RELAY_STATUS_SUCCESS,
  GET_SITE_ALL_RELAY_STATUS_FAILURE,
  CONTROL_SITE_RELAY_REQUEST,
  CONTROL_SITE_RELAY_SUCCESS,
  CONTROL_SITE_RELAY_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  relayUIDs: null,
  relaysInfo: null,
  relaysStatus: null,
  status: {
    getSiteAllRelayInfo: null,
    getSiteAllRelayStatus: null,
    controlSiteRelays: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  siteRelayUIDs: function (state) {
    return state.relayUIDs ? state.relayUIDs : []
  },
  siteRelaysInfo: function (state) {
    return state.relaysInfo ? state.relaysInfo : {}
  },
  siteRelayInfo: function (state, getters) {
    return function (uid) {
      return getters.siteRelaysInfo[uid] ? getters.siteRelaysInfo[uid] : {}
    }
  },
  siteRelaysStatus: function (state) {
    return state.relaysStatus ? state.relaysStatus : {}
  },
  siteRelayStatus: function (state, getters) {
    return function (relayUid) {
      return getters.siteRelaysStatus[relayUid] ? getters.siteRelaysStatus[relayUid] : {}
    }
  },
  statusGetSiteAllRelayInfo: function (state) {
    return state.status.getSiteAllRelayInfo
  },
  statusGetSiteAllRelayStatus: function (state) {
    return state.status.getSiteAllRelayStatus
  },
  statusControlSiteRelays: function (state) {
    return state.status.controlSiteRelays ? state.status.controlSiteRelays : {}
  },
  statusControlSiteRelay: function (state, getters) {
    return function (relayUid) {
      return getters.statusControlSiteRelays[relayUid]
    }
  },
}

// actions
const actions = {
  getSiteAllRelayInfo: function ({commit}, {siteId}) {
    commit(GET_SITE_ALL_RELAY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteAllRelayInfo({siteId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_ALL_RELAY_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ALL_RELAY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSiteAllRelayStatus: function ({commit}, {siteId}) {
    commit(GET_SITE_ALL_RELAY_STATUS_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteAllRelayStatus({siteId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_ALL_RELAY_STATUS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ALL_RELAY_STATUS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  controlSiteRelay: function ({commit}, {siteId, relayId, requestedStatus, relayDelayTimes, relayWithSnapshot, relayMode, relayUid}) {
    commit(CONTROL_SITE_RELAY_REQUEST, {relayUid})
    return new Promise((resolve, reject) => {
      api.controlSiteRelay({siteId, relayId, requestedStatus, relayDelayTimes, relayWithSnapshot, relayMode}).then(
        res => {
          let data = res.body
          commit(CONTROL_SITE_RELAY_SUCCESS, {relayUid})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(CONTROL_SITE_RELAY_FAILURE, {relayUid})
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_ALL_RELAY_REQUEST]: function (state) {
    state.status.getSiteAllRelayInfo = "requested"
  },
  [GET_SITE_ALL_RELAY_SUCCESS]: function (state, {data}) {

    // PREPARE
    let relaysInfo = {}
    let relayUIDs = []

    if (!(data instanceof Array)) return
    for(let i=0; i < data.length; i++) {
      // PARSE
      const relayData = data[i];
      const relayInfo = parser.parseRelayData(relayData)
      // CHECK - relayInfo 데이터 체크 
      if (!(relayInfo instanceof Object)) continue

      // FILTER 1 - Relay Disable경우 데이터 넣지 말기
      if (!relayInfo.showRelay) continue
      const uid = `${relayInfo.deviceId}-${relayInfo.channel}`

      // FILTER 2 - labelType에 따라 label 이름 담기
      let energizedLabel = 'Unknown'
      let normalLabel = 'Unknown'
      switch (relayInfo.labelType) {
      case 'manual':
        normalLabel = relayInfo.ManualNormalLabel
        energizedLabel = relayInfo.ManualEnergizedLabel
        break
      case 'default':
      default:
        normalLabel = relayInfo.defaultNormalLabel
        energizedLabel = relayInfo.defaultEnergizedLabel
        break
      }

      // SET - relayInfoStatus
      relaysInfo[uid] = {
        relayId: relayInfo.relayId,
        name: relayInfo.name,
        normalIcon: relayInfo.normalIcon,
        energizedIcon: relayInfo.energizedIcon,
        showRelay: relayInfo.showRelay,
        showEnduserApp: relayInfo.showEnduserApp,
        labelType: relayInfo.labelType,
        latchEnabled: relayInfo.latchEnabled,
        momentaryEnabled: relayInfo.momentaryEnabled,
        momentaryDelayTimes: relayInfo.momentaryDelayTimes,
        cameraId: relayInfo.cameraId,
        energizedLabel,
        normalLabel,
      }

      // * 만약 Dealer portal에서 Use Relay가 Disable 되있으면, Backend에서 안줌.
      // ADD - relayUID
      if (!relayInfo.showEnduserApp) continue
      if (relayUIDs.indexOf(uid) < 0) relayUIDs.push(uid)
    }
    Vue.set(state, 'relaysInfo', relaysInfo)
    Vue.set(state, 'relayUIDs', relayUIDs)
    state.status.getSiteAllRelayInfo = "successful"
  },
  [GET_SITE_ALL_RELAY_FAILURE]: function (state) {

    // REMOVE - All remain relay
    Vue.set(state, 'relaysInfo', {})
    Vue.set(state, 'relayUIDs', [])

    state.status.getSiteAllRelayInfo = "failed"
  },
  [GET_SITE_ALL_RELAY_STATUS_REQUEST]: function (state) {
    state.status.getSiteAllRelayStatus = "requested"
  },
  [GET_SITE_ALL_RELAY_STATUS_SUCCESS]: function (state, {data}) {
    let relaysStatus = {}
    relaysStatus = parser.parseRelayStatusData(data)
    Vue.set(state, 'relaysStatus', relaysStatus)
    state.status.getSiteAllRelayStatus = "successful"
  },
  [GET_SITE_ALL_RELAY_STATUS_FAILURE]: function (state) {
    state.status.getSiteAllRelayStatus = "failed"
  },
  [CONTROL_SITE_RELAY_REQUEST]: function (state, {relayUid}) {
    if (state.status.controlSiteRelays === null) state.status.controlSiteRelays = {}
    state.status.controlSiteRelays = {...state.status.controlSiteRelays, [relayUid]: 'requested'}
  },
  [CONTROL_SITE_RELAY_SUCCESS]: function (state, {relayUid}) {
    state.status.controlSiteRelays = {...state.status.controlSiteRelays, [relayUid]: 'successful'}
  },
  [CONTROL_SITE_RELAY_FAILURE]: function (state, {relayUid}) {
    state.status.controlSiteRelays = {...state.status.controlSiteRelays, [relayUid]: 'failed'}
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
