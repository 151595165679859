export default {
  parseArmingScheduleData: function (data) {
    // Init
    let armingSchedule = {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    }

    // Parse data
    for (let i=0; i<data.length; i++) {

      const scheduleData = data[i]
      let arming = null

      if (scheduleData.type === 1) arming = 'arm'
      else if (scheduleData.type === 2) arming = 'disarm'

      // REDEFIND
      let redefinedData = {
        createdAt: scheduleData.created_at,
        weekday: scheduleData.day_of_week,
        id: scheduleData.id,
        time: scheduleData.start_time,
        armingMode: arming,
      }

      // CHECK - schedule weeks
      switch (scheduleData.day_of_week) {
      case 1:
        armingSchedule['sunday'].push(redefinedData)
        break
      case 2:
        armingSchedule['monday'].push(redefinedData)
        break
      case 3:
        armingSchedule['tuesday'].push(redefinedData)
        break
      case 4:
        armingSchedule['wednesday'].push(redefinedData)
        break
      case 5:
        armingSchedule['thursday'].push(redefinedData)
        break
      case 6:
        armingSchedule['friday'].push(redefinedData)
        break
      case 7:
        armingSchedule['saturday'].push(redefinedData)
        break
      }
    }
    // DONE
    return armingSchedule
  },
}
