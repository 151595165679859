import Vue from 'vue'
import apiAuth0 from '@/apis/auth0'
import parser from '@/models/auth0IDToken'
import jwtDecode from "jwt-decode";
import auth0 from 'auth0-js'
import {
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  AUTH_INIT_AUTH0,
  AUTH_CLEAR_SESSION,
  AUTH_REFRESH_TOKENS_REQUEST,
  AUTH_REFRESH_TOKENS_SUCCESS,
  AUTH_REFRESH_TOKENS_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_CHECK_SESSION_REQUEST,
  AUTH_CHECK_SESSION_SUCCESS,
  AUTH_CHECK_SESSION_FAILURE,
  AUTH_HANDLE_AUTHENTICATION_REQUEST,
  AUTH_HANDLE_AUTHENTICATION_SUCCESS,
  AUTH_HANDLE_AUTHENTICATION_FAILURE,
  AUTH_UPDATE_LAST_SESSION,
  SEND_PASSWORDRESET_EMAIL_REQUEST,
  SEND_PASSWORDRESET_EMAIL_SUCCESS,
  SEND_PASSWORDRESET_EMAIL_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  auth0: null,
  lastPasswordReset: 'TBD',
  authResult: {
    email: null,
    accessToken: null,
    idToken: null,
    expiresAt: null
  },
  status: {
    login: null,
    refreshTokens: null,
    sendPasswordResetEmail: null,
    loadLastAuthSession: null,
    checkSession: null,
    handleAuthentication: null,
  }
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  statusRefreshTokens: function (state) {
    return state.status.refreshTokens
  },
  statusLogin: function (state) {
    return state.status.login
  },
  statusLoadLastAuthSession: function (state) {
    return state.status.loadLastAuthSession
  },
  statusGetIDTokenIPAuth: function (state) {
    return state.status.getIDTokenIPAuth
  },
  statusCheckSession: function (state) {
    return state.status.checkSession;
  },
  statusHandleAuthentication: function (state) {
    return state.status.handleAuthentication;
  },
  totalGetIDTokenIPAuth: function (state) {
    return state.status.totalGetIDTokenIPAuth
  },
  isToResetPasswordAuth0: function (state) {
    return state.lastPasswordReset === 'TBD' ? false : !state.lastPasswordReset
  },
  lastPasswordReset: function (state) {
    return state.lastPasswordReset
  },
  auth0: function (state) {
    return state.auth0
  },
  idToken: function (state) {
    return state.authResult.idToken
  },
  idTokenExpiresAt: function (state) {
    return state.authResult.expiresAt
  },
  isAuthenticated: function (state) {
    return () => {
      const expiresAt = state.authResult.expiresAt || 0
      return (new Date()).getTime() < expiresAt
    }
  },
  statusSendPasswordResetEmail: function (state) {
    return state.status.sendPasswordResetEmail
  },
}
// actions
const actions = {
  loadLastAuthSession: async function ({commit}) {
    if (Vue.env.deviceInfo.platform === 'web') {
      let idToken = await Vue.tool.getCookie("chekt::id_token")
      let accessToken = await Vue.tool.getCookie("chekt::access_token")
      let expiresAt = await Vue.tool.getCookie("chekt::expires_at")
      if (!idToken) idToken = ''
      if (!accessToken) accessToken = ''
      if (!expiresAt) expiresAt = ''
      Vue.auth.storeIdTokenToAuthService(idToken)
      commit(AUTH_UPDATE_LAST_SESSION, {idToken, expiresAt, accessToken})
      Vue.logger.info('loadLastAuthSession - WEB')
      return
    } else {
      let idToken = await Vue.tool.storageGetItem('id_token')
      let accessToken = await Vue.tool.storageGetItem('access_token')
      let expiresAt = await Vue.tool.storageGetItem('expires_at')
      if (!idToken) idToken = ''
      if (!accessToken) accessToken = ''
      if (!expiresAt) expiresAt = ''
      Vue.auth.storeIdTokenToAuthService(idToken)
      commit(AUTH_UPDATE_LAST_SESSION, {idToken, expiresAt, accessToken})
      Vue.logger.info('loadLastAuthSession')
      return
    }
  },
  checkSession: function ({ commit, state }) {
    commit(AUTH_CHECK_SESSION_REQUEST);
    if (!state.auth0) commit("AUTH_INIT_AUTH0");
    return new Promise((resolve, reject) => {
      state.auth0.checkSession(
        {
          // state and nonce should be handled by auth0-js library, so don't need to do that here
        },
        function (err, authResult) {
          if (err) {
            commit(AUTH_CHECK_SESSION_FAILURE);
            reject(err);
          } else {
            let expiresIn = commit(AUTH_LOGIN_SUCCESS, authResult);
            commit(AUTH_CHECK_SESSION_SUCCESS);
            resolve(expiresIn);
          }
        }
      );
    });
  },
  login: async function ({ commit, state }) {
    if (!state.auth0) commit("AUTH_INIT_AUTH0");
    state.auth0.authorize({
      login_hint: await Vue.tool.getCookie("chekt::email")
    });
  },
  loginLegacy: function ({commit}, {email, password}) {
    commit(AUTH_LOGIN_REQUEST)
    return new Promise((resolve, reject) => {
      apiAuth0.loginLegacy({email, password}).then(
        res => {
          const data = res.body
          commit(AUTH_LOGIN_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(AUTH_LOGIN_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  refreshTokens: async function ({commit}) {
    commit(AUTH_REFRESH_TOKENS_REQUEST)
    // CASE 1 - NO refresh token
    let refreshToken = await Vue.auth.getRefreshTokenFromLocalStorage()
    if (!refreshToken) {
      commit(AUTH_REFRESH_TOKENS_FAILURE)
      throw new Error('login_required')
    }
    // CASE 2 - HAS refresh token
    try {
      const res = await apiAuth0.refreshTokensLegacy({refreshToken})
      commit(AUTH_REFRESH_TOKENS_SUCCESS, {data: res.body})
      commit(PROCESS_API_SUCCESS)
      return
    } catch(err) {
      commit(AUTH_REFRESH_TOKENS_FAILURE)
      commit(PROCESS_API_FAILURE, {
        status: err.status,
        statusText: err.body,
        origin: window.location.origin,
        err: Vue.tool.parseToStringify(err),
      })
      if (err.status == 400 || err.status == 401) {
        throw new Error('login_required')
      } else {
        throw new Error(Vue.tool.parseToStringify(err))
      }
    }
  },
  revokeRefreshToken: async function ({commit}) {
    // CASE 1 - NO refresh token
    let refreshToken = await Vue.auth.getRefreshTokenFromLocalStorage()
    if (!refreshToken) {
      commit(AUTH_CLEAR_SESSION) // LOGOUT - local
      return
    }
    // CASE 2 - HAS refresh token
    commit(AUTH_CLEAR_SESSION) // LOGOUT - cloud
    try {
      const res = await apiAuth0.revokeRefreshToken({refreshToken})
      return
    } catch(err) {
      throw new Error(Vue.tool.parseToStringify(err))
    }
  },
  logout: function ({commit}) {
    commit(AUTH_CLEAR_SESSION) // LOGOUT - local
    if (!state.auth0) commit('AUTH_INIT_AUTH0')
    return state.auth0.logout({ // LOGOUT - cloud
      returnTo: window.location.origin + window.location.search,
      clientID: Vue.env.authClientID
    })
  },
  changePassword: function ({commit, state}) {
    if (!state.auth0) commit('AUTH_INIT_AUTH0')
    commit('SEND_PASSWORDRESET_EMAIL_REQUEST')
    return new Promise((resolve, reject) => {
      state.auth0.changePassword({
        connection: Vue.env.authConnection,
        email: state.authResult.email
      }, function (err, resp) {
        if (err) {
          reject(err)
          commit('SEND_PASSWORDRESET_EMAIL_FAILURE')
        } else {
          resolve(resp)
          commit('SEND_PASSWORDRESET_EMAIL_SUCCESS')
        }
      })
    })
  },
  changeForgotPassword: function ({commit, state}, {email}) {
    if (!state.auth0) commit('AUTH_INIT_AUTH0')
    commit('SEND_PASSWORDRESET_EMAIL_REQUEST')
    return new Promise((resolve, reject) => {
      state.auth0.changePassword({
        connection: Vue.env.authConnection,
        email: email
      }, function (err, resp) {
        if (err) {
          reject(err)
          commit('SEND_PASSWORDRESET_EMAIL_FAILURE')
        } else {
          resolve(resp)
          commit('SEND_PASSWORDRESET_EMAIL_SUCCESS')
        }
      })
    })
  },
  handleAuthentication: function ({ commit, state }) {
    if (!state.auth0) commit("AUTH_INIT_AUTH0");
    commit(AUTH_HANDLE_AUTHENTICATION_REQUEST);
    return new Promise((resolve, reject) => {
      state.auth0.parseHash(
        {
          __enableIdPInitiatedLogin: true, // warning CSRF attackable
        },
        function (err, authResult) {
          if (authResult && authResult.accessToken && authResult.idToken) {
            commit(AUTH_LOGIN_SUCCESS, authResult);
            commit(AUTH_HANDLE_AUTHENTICATION_SUCCESS);
            resolve();
          } else if (err) {
            commit(AUTH_HANDLE_AUTHENTICATION_FAILURE);
            reject(err);
          } else {
            reject(new Error("no_result"));
          }
        }
      );
    });
  },
}

// mutations
const mutations = {
  [AUTH_INIT_AUTH0]: function (state) {
    state.auth0 = new auth0.WebAuth({
      domain: Vue.env.authDomain,
      // audience: Vue.env.authAudience, // it goes RS256 if audience is added.
      clientID: Vue.env.authClientID,
      redirectUri: Vue.env.origin + '/callback' + window.location.search,
      responseType: 'token id_token', // RH256 requires both access_token and id_token. It uses access_token to validate id_token
      // scope: 'openid',
      scope: Vue.env.deviceInfo.platform === 'web' ? 'openid' : 'openid offline_access',
    })
  },
  [AUTH_UPDATE_LAST_SESSION]: function (state, {idToken, expiresAt, accessToken}) {
    state.authResult = {...state.authResult, 'idToken': idToken}
    state.authResult = {...state.authResult, 'expiresAt': expiresAt}
    state.authResult = {...state.authResult, 'accessToken': accessToken}
    state.status = {...state.status, 'loadLastAuthSession': 'successful'}
  },
  [AUTH_LOGIN_REQUEST]: function (state) {
    state.status = {...state.status, 'login': 'requested'}
  },
  [AUTH_LOGIN_SUCCESS]: function (state, data) {
    if( Vue.env.deviceInfo.platform === 'web' ) {
      let decodedToken = jwtDecode(data.idToken);
      let expiresAt = decodedToken.exp * 1000;
      let expiresIn = +decodedToken.exp - +decodedToken.iat;
      Vue.tool.setCookie("chekt::access_token", data.accessToken);
      Vue.tool.setCookie("chekt::expires_at", expiresAt);
      Vue.tool.setCookie("chekt::id_token", data.idToken);
      Vue.auth.storeIdTokenToAuthService(data.idToken);
      if (data.idTokenPayload instanceof Object) {
        // email
        if (data.idTokenPayload.email) {
          Vue.tool.setCookie("chekt::email", data.idTokenPayload.email);
          state.authResult = {
            ...state.authResult,
            email: data.idTokenPayload.email,
          };
        }
        // last password reset
        state.lastPasswordReset = data.idTokenPayload.last_password_reset;
      }
      console.info("Session valid for next " + expiresIn / 60 + " minute(s)");
      state.authResult = { ...state.authResult, idToken: data.idToken };
      state.authResult = { ...state.authResult, expiresAt: expiresAt };
      state.authResult = {
        ...state.authResult,
        accessToken: data.accessToken,
      };
      state.status = {...state.status, 'login': 'successful'}
      return expiresIn;
    } else {
      let idTokenData = parser.parseIDTokenData(data)
      // STORE - idToken, accessToken, exp
      Vue.tool.storageSetItem('access_token', data.access_token)
      Vue.tool.storageSetItem('expires_at', idTokenData.expiresAt)
      Vue.tool.storageSetItem('id_token', idTokenData.idToken)
      Vue.auth.storeIdTokenToAuthService(idTokenData.idToken)

      Vue.auth.storeRefreshTokenToLocalStorage(data.refresh_token)
      Vue.auth.storeIdTokenToAuthService(data.id_token)
      
      // UPDATE - email and last password reset
      if (idTokenData instanceof Object) {
        // email
        if (idTokenData.email) {
          Vue.tool.storageSetItem('email', idTokenData.email)
          state.authResult = {...state.authResult, 'email': idTokenData.email}
        }
        // last password reset
        state.lastPasswordReset = idTokenData.last_password_reset
      }

      Vue.logger.info('Session valid for next ' + (idTokenData.expiresIn / 60) + ' minute(s)')
      state.authResult = {...state.authResult, 'idToken': idTokenData.idToken}
      state.authResult = {...state.authResult, 'expiresAt': idTokenData.expiresAt}
      state.status = {...state.status, 'login': 'successful'}
    }
  },
  [AUTH_LOGIN_FAILURE]: function (state) {
    state.status = {...state.status, 'login': 'failed'}
  },
  [AUTH_CLEAR_SESSION]: function (state) {
    if (Vue.env.deviceInfo.platform === 'web') {
      Vue.tool.deleteCookie('chekt::id_token')
      Vue.tool.deleteCookie('chekt::access_token')
      Vue.tool.deleteCookie('chekt::expires_at')
      // Vue.auth.removeRefreshTokenFromLocalStorage()
      state.authResult = {...state.authResult, 'email': null}
      state.authResult = {...state.authResult, 'idToken': null}
      state.authResult = {...state.authResult, 'expiresAt': null}
      state.authResult = {...state.authResult, 'accessToken': null}
    } else {
      Vue.tool.storageRemoveItem('access_token')
      Vue.tool.storageRemoveItem('expires_at')
      Vue.tool.storageRemoveItem('id_token')
      Vue.auth.removeRefreshTokenFromLocalStorage()
      state.authResult = {...state.authResult, 'email': null}
      state.authResult = {...state.authResult, 'idToken': null}
      state.authResult = {...state.authResult, 'expiresAt': null}
      state.authResult = {...state.authResult, 'accessToken': null}
    }
  },
  [AUTH_REFRESH_TOKENS_REQUEST]: function (state) {
    state.status.refreshTokens = "requested"
  },
  [AUTH_REFRESH_TOKENS_SUCCESS]: function (state, {data}) {
    if( Vue.env.deviceInfo.platform === 'web' ) {
      
      let idTokenData = jwtDecode(data.idToken);
      
      Vue.tool.setCookie("chekt::id_token", data.access_token)
      Vue.tool.setCookie("chekt::expires_at", idTokenData.idToken)
      Vue.auth.storeIdTokenToAuthService(idTokenData.idToken)
      
      // UPDATE - email and last password reset
      if (idTokenData instanceof Object) {
        // email
        if (idTokenData.email) {
          Vue.tool.setCookie('email', idTokenData.email)
          state.authResult = {...state.authResult, 'email': idTokenData.email}
        }
        // last password reset
        state.lastPasswordReset = idTokenData.last_password_reset
      }
    } else {
      let idTokenData = parser.parseIDTokenData(data)
      // STORE - idToken, exp
      Vue.tool.storageSetItem('expires_at', idTokenData.expiresAt)
      Vue.tool.storageSetItem('id_token', idTokenData.idToken)
      Vue.auth.storeIdTokenToAuthService(idTokenData.idToken)
      
      // UPDATE - email and last password reset
      if (idTokenData instanceof Object) {
        // email
        if (idTokenData.email) {
          Vue.tool.storageSetItem('email', idTokenData.email)
          state.authResult = {...state.authResult, 'email': idTokenData.email}
        }
        // last password reset
        state.lastPasswordReset = idTokenData.last_password_reset
      }
    }

    state.status.refreshTokens = "successful"

    Vue.logger.info('Session valid for next ' + (idTokenData.expiresIn / 60) + ' minute(s)')
    // console.log(authResult)
    state.authResult = {...state.authResult, 'idToken': idTokenData.idToken}
    state.authResult = {...state.authResult, 'expiresAt': idTokenData.expiresAt}
  },
  [AUTH_REFRESH_TOKENS_FAILURE]: function (state) {
    state.status.refreshTokens = "failed"
  },
  [AUTH_CHECK_SESSION_REQUEST]: function (state) {
    state.status = { ...state.status, checkSession: "requested" };
  },
  [AUTH_CHECK_SESSION_SUCCESS]: function (state) {
    state.status = { ...state.status, checkSession: "successful" };
  },
  [AUTH_CHECK_SESSION_FAILURE]: function (state) {
    state.status = { ...state.status, checkSession: "failed" };
  },
  [AUTH_HANDLE_AUTHENTICATION_REQUEST]: function (state) {
    state.status = { ...state.status, handleAuthentication: "requested" };
  },
  [AUTH_HANDLE_AUTHENTICATION_SUCCESS]: function (state) {
    state.status = { ...state.status, handleAuthentication: "successful" };
  },
  [AUTH_HANDLE_AUTHENTICATION_FAILURE]: function (state) {
    state.status = { ...state.status, handleAuthentication: "failed" };
  },
  [SEND_PASSWORDRESET_EMAIL_REQUEST]: function (state) {
    state.status = {...state.status, 'sendPasswordResetEmail': 'requested'}
  },
  [SEND_PASSWORDRESET_EMAIL_SUCCESS]: function (state) {
    state.status = {...state.status, 'sendPasswordResetEmail': 'successful'}
  },
  [SEND_PASSWORDRESET_EMAIL_FAILURE]: function (state) {
    state.status = {...state.status, 'sendPasswordResetEmail': 'failed'}
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
