const baseRoutes = [
  {
    path: '/camera/:deviceId',
    component: () => import("@/_routes/camera/Root"),
  },
  {
    path: '/camera/:deviceId/view',
    component: () => import("@/_routes/camera/View")
  },
  {
    path: '/camera/:deviceId/recording_interval',
    component: () => import("@/_routes/camera/RecordingInterval")
  },
  {
    path: '/camera/:deviceId/notification',
    component: () => import("@/_routes/camera/Notification")
  },
]

const routes = baseRoutes

export default routes
