import Vue from 'vue'
import api from '@/apis/siteNotificationMember'
import parser from '@/models/siteNotificationMemberDevice'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_MY_NOTIFICATION_STATUS_REQUEST,
  GET_MY_NOTIFICATION_STATUS_SUCCESS,
  GET_MY_NOTIFICATION_STATUS_FAILURE,
  GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_REQUEST,
  GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_SUCCESS,
  GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_FAILURE,
  GET_MY_DEVICE_NOTIFICATION_STATUS_REQUEST,
  GET_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS,
  GET_MY_DEVICE_NOTIFICATION_STATUS_FAILURE,
  UPDATE_MY_NOTIFICATION_STATUS_REQUEST,
  UPDATE_MY_NOTIFICATION_STATUS_SUCCESS,
  UPDATE_MY_NOTIFICATION_STATUS_FAILURE,
  UPDATE_MY_DEVICE_NOTIFICATION_STATUS_REQUEST,
  UPDATE_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS,
  UPDATE_MY_DEVICE_NOTIFICATION_STATUS_FAILURE,
} from '@/store/mutation-types'
// import device from './device'

let initialState = {
  siteId: null,
  members: null,
  notificationMemberMe:null,
  notificationMemberMeDevices:null,
  notificationMemberMeDevice:null,
  status: {
    getNotificationMemberMeStatus: null,
    getDeviceNotificationMemberMe: null,
    getAllDeviceNotificationMemberMe: null,
    updateNotificationMemberMeStatus: null,
    updateDeviceNotificationMemberMeStatus: null,
  }
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  siteNotificationMemberMe: function (state) {
    return state.notificationMemberMe || {}
  },
  siteNotificationMemberMeDevices: function (state) {
    return state.notificationMemberMeDevices || {}
  },
  siteNotificationMemberMeDevice: function (state, getters) {
    return function (deviceId) {
      return getters.siteNotificationMemberMeDevices[deviceId]
    }
  },
  statusGetNotificationMemberMeStatus: function (state) {
    return state.status.getNotificationMemberMeStatus
  },
  statusGetDeviceNotificationMemberMe: function (state) {
    return state.status.getDeviceNotificationMemberMe
  },
  statusGetAllDeviceNotificationMemberMe: function (state) {
    return state.status.getAllDeviceNotificationMemberMe
  },
  statusUpdateNotificationMemberMeStatus: function (state) {
    return state.status.updateNotificationMemberMeStatus
  },
  statusUpdateDeviceNotificationMemberMeStatus: function (state) {
    return state.status.updateDeviceNotificationMemberMeStatus
  },
}

// actions
const actions = {
  getAllDeviceNotificationMemberMe: function ({commit}, {siteId, source, deviceId}) {
    commit(GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_REQUEST)
    return new Promise((resolve, reject) => {
      api.getAllDeviceNotificationMemberMe({siteId, source, deviceId}).then(
        res => {
          let data = res.body
          commit(GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getDeviceNotificationMemberMe: function ({commit}, {siteId, source, deviceId}) { 
    commit(GET_MY_DEVICE_NOTIFICATION_STATUS_REQUEST)
    return new Promise((resolve, reject) => {
      api.getDeviceNotificationMemberMe({siteId, source, deviceId}).then(
        res => {
          let data = res.body
          commit(GET_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_MY_DEVICE_NOTIFICATION_STATUS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getNotificationMemberMeStatus: function ({commit}, {siteId, source, getWriteDB}) {   
    commit(GET_MY_NOTIFICATION_STATUS_REQUEST)
    return new Promise((resolve, reject) => {
      api.getNotificationMemberMeStatus({siteId, source, getWriteDB}).then(
        res => {
          let data = res.body
          commit(GET_MY_NOTIFICATION_STATUS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_MY_NOTIFICATION_STATUS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  updateNotificationMemberMeStatus: function ({commit}, {siteId, isEnabled, source, sourceEventType, notificationType}) {
    commit(UPDATE_MY_NOTIFICATION_STATUS_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateNotificationMemberMeStatus({siteId, isEnabled, source, sourceEventType, notificationType}).then(
        res => {
          let data = res.body
          commit(UPDATE_MY_NOTIFICATION_STATUS_SUCCESS)
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(UPDATE_MY_NOTIFICATION_STATUS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  updateDeviceNotificationMemberMeStatus: function ({commit}, {siteId, deviceId, isEnabled, source, sourceEventType, notificationType}) {
    commit(UPDATE_MY_DEVICE_NOTIFICATION_STATUS_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateDeviceNotificationMemberMeStatus({siteId, deviceId, isEnabled, source, sourceEventType, notificationType}).then(
        res => {
          let data = res.body
          commit(UPDATE_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS)
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(UPDATE_MY_DEVICE_NOTIFICATION_STATUS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_MY_NOTIFICATION_STATUS_REQUEST]: function (state) {
    state.status.getNotificationMemberMeStatus = "requested"
  },
  [GET_MY_NOTIFICATION_STATUS_SUCCESS]: function (state, {data}) {
    if (!(state.notificationMemberMe instanceof Object)) state.notificationMemberMe = {}
    let notificationMemberMe = parser.parseNotificationSiteData(data)
    // data.map(memberData => {
    //   let notificationInfo = parser.parseNotificationSiteData(memberData)
    //   if (notificationInfo) {
    //     notificationMemberMe = notificationInfo
    //   }
    // })
    Vue.set(state, 'notificationMemberMe', notificationMemberMe)
    state.status.getNotificationMemberMeStatus = "successful"
  },
  [GET_MY_NOTIFICATION_STATUS_FAILURE]: function (state) {
    state.status.getNotificationMemberMeStatus = "failed"
  },
  [GET_MY_DEVICE_NOTIFICATION_STATUS_REQUEST]: function (state) {
    state.status.getDeviceNotificationMemberMe = "requested"
  },
  [GET_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS]: function (state, {data}) {
    if (!(state.notificationMemberMeDevices instanceof Object)) state.notificationMemberMeDevices = {}
    let deviceNotificationsInfo = parser.parseNotificationMemberData(data)
    let deviceId = deviceNotificationsInfo["deviceId"]
    Vue.set(state.notificationMemberMeDevices, [deviceId], deviceNotificationsInfo)
    state.status.getDeviceNotificationMemberMe = "successful"
  },
  [GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_REQUEST]: function (state) {
    state.status.getAllDeviceNotificationMemberMe = "requested"
  },
  [GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_SUCCESS]: function (state, {data}) {
    if (!(state.notificationMemberMeDevices instanceof Object)) state.notificationMemberMeDevices = {}
    let devicesNotificationsInfo = {}
    let createParsableArrayData = parser.createParsableArray(data)
    Object.keys(createParsableArrayData).map(deviceId => {
      let deviceNotificationsInfo = parser.parseNotificationMemberData(createParsableArrayData[deviceId])
      devicesNotificationsInfo[deviceId + ''] = deviceNotificationsInfo
    })
    Vue.set(state, 'notificationMemberMeDevices', devicesNotificationsInfo)
    state.status.getAllDeviceNotificationMemberMe = "successful"
  },
  [GET_MY_ALL_DEVICE_NOTIFICATION_STATUS_FAILURE]: function (state) {
    state.status.getAllDeviceNotificationMemberMe = "failed"
  },
  [UPDATE_MY_NOTIFICATION_STATUS_REQUEST]: function (state) { 
    state.status.updateNotificationMemberMeStatus = "requested"
  },
  [UPDATE_MY_NOTIFICATION_STATUS_SUCCESS]: function (state) { 
    state.status.updateNotificationMemberMeStatus = "successful"
  },
  [UPDATE_MY_NOTIFICATION_STATUS_FAILURE]: function (state) {
    state.status.updateNotificationMemberMeStatus = "failed"
  },
  [UPDATE_MY_DEVICE_NOTIFICATION_STATUS_REQUEST]: function (state) { 
    state.status.updateDeviceNotificationMemberMeStatus = "requested"
  },
  [UPDATE_MY_DEVICE_NOTIFICATION_STATUS_SUCCESS]: function (state) { 
    state.status.updateDeviceNotificationMemberMeStatus = "successful"
  },
  [UPDATE_MY_DEVICE_NOTIFICATION_STATUS_FAILURE]: function (state) {
    state.status.updateDeviceNotificationMemberMeStatus = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
