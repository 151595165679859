import Vue from 'vue'
import App from './App.vue'
import store  from './store'
import router from './_router'
import VueResource  from 'vue-resource'
import Ionic from '@modus/ionic-vue';

// CSS
// import "@ionic/core/css/core.css"
import '@ionic/core/css/ionic.bundle.css'
import './icon'

// Plugins
import AuthPlugin from './plugins/auth'
import EnvironmentPlugin  from './plugins/environment'
import DeployPlugin  from './plugins/deploy'
import ToolPlugin from './plugins/tool'
import ActlogPlugin from './plugins/actlog'
import PushPlugin from './plugins/push'
import LoggerPlugin from './plugins/logger'
import GoogleMapPlugin from './plugins/googlemap'
import WorkerPlugin from './plugins/workers'
import WebrtcPlugin from './plugins/webrtc'

// CORE
Vue.use(VueResource)

// CUSTOM
Vue.use(Ionic)
Vue.use(AuthPlugin)
Vue.use(EnvironmentPlugin)
Vue.use(DeployPlugin) // always after 'EnvironmentPlugin'
Vue.use(ToolPlugin)
Vue.use(ActlogPlugin)
Vue.use(PushPlugin)
Vue.use(LoggerPlugin)
Vue.use(GoogleMapPlugin)
Vue.use(WorkerPlugin)
Vue.use(WebrtcPlugin, {store, router})

Vue.config.productionTip = false
Vue.config.devtools = false
// Vue.config.ignoredElements = [/^ion-/]
// Vue.config.ignoredElements = ['ion-icon']

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')