import Vue from 'vue'
import commonApi from '@/apis/common'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_DEVICE_SNAPSHOT_REQUEST,
  GET_DEVICE_SNAPSHOT_SUCCESS,
  GET_DEVICE_SNAPSHOT_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  devicesSnapshot: {},
  devicesSnapshotLatency: {},
  status: {
    getDeviceSnapshot: {},
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  deviceSnapshot: function (state) {
    return function (deviceId) {
      return state.devicesSnapshot[deviceId]
    }
  },
  devicesSnapshot: function (state) {
    return state.devicesSnapshot
  },
  deviceSnapshotLatency: function (state) {
    return function (deviceId) {
      return state.devicesSnapshotLatency[deviceId]
    }
  },
  devicesSnapshotLatency: function (state) {
    return state.devicesSnapshotLatency
  },
  statusGetDeviceSnapshot: function (state) {
    return function (deviceId) {
      return state.status.getDeviceSnapshot[deviceId]
    }
  },
}

// actions
const actions = {
  getDeviceSnapshot: function ({getters, commit}, {deviceId, duration, interval}) {
    commit(GET_DEVICE_SNAPSHOT_REQUEST, {deviceId})
    return new Promise((resolve, reject) => {
      // PREPARE 1 - snapshotUrl
      const device = getters.siteDevice(deviceId)
      if (!(device instanceof Object)) {
        commit(GET_DEVICE_SNAPSHOT_FAILURE, {deviceId})
        reject({
          status: 400,
          statusText: `device(id: ${deviceId}) doesn't exist`,
        })
        return
      }
      const snapshotUrl = device.networkInfo.snapshotUrl

      // PREPARE 2 - query
      let query = ''
      if (duration) query += '&duration=' + duration
      else query += '&duration=2'
      if (interval) query += '&interval=' + interval

      // ACTION - get snapshot
      commonApi.get(snapshotUrl + query).then(
        res => {
          let data = res.body
          commit(GET_DEVICE_SNAPSHOT_SUCCESS, {deviceId, data})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_DEVICE_SNAPSHOT_FAILURE, {deviceId})
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_DEVICE_SNAPSHOT_REQUEST]: function (state, {deviceId}) {
    if (!(state.devicesSnapshotLatency[deviceId] instanceof Object)) {
      state.devicesSnapshotLatency[deviceId] = {}
    }
    state.devicesSnapshotLatency[deviceId].beginTime = new Date()
    state.status.getDeviceSnapshot = {...state.status.getDeviceSnapshot, [deviceId]: 'requested'}
  },
  [GET_DEVICE_SNAPSHOT_SUCCESS]: function (state, {deviceId, data}) {
    try {
      if (!data.data) throw new Error('no snapshot data')
      let snapshotInfo = {
        data: 'data:image/jpg;base64,' + data.data,
        timestamp: null
      }
      if ('snapshotTimestamp' in data) {
        snapshotInfo.timestamp = data.snapshotTimestamp * 1000
      } else {
        snapshotInfo.timestamp = data.timestamp
      }
      state.devicesSnapshot = {...state.devicesSnapshot, [deviceId]: snapshotInfo}
      state.status.getDeviceSnapshot = {...state.status.getDeviceSnapshot, [deviceId]: 'successful'}
    } catch (err) {
      Vue.logger.error('GET_DEVICE_SNAPSHOT_SUCCESS: Failed')
      Vue.logger.error(err)
      state.status.getDeviceSnapshot = {...state.status.getDeviceSnapshot, [deviceId]: 'failed'}
    }
    const beginTime = state.devicesSnapshotLatency[deviceId].beginTime
    const endTime = new Date()
    state.devicesSnapshotLatency[deviceId].endTime = endTime
    state.devicesSnapshotLatency[deviceId].latency = endTime - beginTime
  },
  [GET_DEVICE_SNAPSHOT_FAILURE]: function (state, {deviceId}) {
    const beginTime = state.devicesSnapshotLatency[deviceId].beginTime
    const endTime = new Date()
    state.devicesSnapshotLatency[deviceId].endTime = endTime
    state.devicesSnapshotLatency[deviceId].latency = endTime - beginTime
    state.status.getDeviceSnapshot = {...state.status.getDeviceSnapshot, [deviceId]: 'failed'}
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
