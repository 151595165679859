export default {
  getPTZContinuousMoveQuery: function (brand) {
    switch (brand) {
      case 'HIKVISION':
        return 'PTZMoveContinuous';
      case 'AXIS':
        return 'PTZMoveContinuous';
      default:
        return 'ptz/ContinuousMove';
    }
  },
  getPTZStopQuery: function (brand) {
    switch (brand) {
      case 'HIKVISION':
        return 'PTZStopContinuous';
      case 'AXIS':
        return 'PTZStop';
      default:
        return 'ptz/Stop';
    }
  },
  getPTZConfigurationQuery: function (brand) {
    switch (brand) {
      case 'HIKVISION':
        return 'PTZGetCapabilities';
      case 'AXIS':
        return 'PTZGetLimits';
      default:
        return 'ptz/GetConfigurations';
    }
  },
  getPTZConfigurationOptionsQuery: function (brand) {
    switch (brand) {
      // case 'HIKVISION':
      //   return 'ptzConfiguration';
      default:
        return 'ptz/GetConfigurationOptions';
    }
  },
  getPTZGoToHomeQuery: function (brand) {
    switch (brand) {
      case 'HIKVISION':
        return 'PTZGotoHome';
      case 'AXIS':
        return 'PTZGotoHome';
      default:
        return 'ptz/GotoHomePosition';
    }
  },
  getPTZGoToPresetQuery: function (brand) {
    switch (brand) {
      case 'HIKVISION':
        return 'PTZGotoPreset';
      case 'AXIS':
        return 'PTZGotoPreset';
      default:
        return 'ptz/GotoPreset';
    }
  },
  getPTZGoToPresetQueryOption: function (brand) {
    switch (brand) {
      case 'HIKVISION':
        return 'preset_id';
      case 'AXIS':
        return 'preset_name';
      default:
        return 'preset_token';
    }
  },
  getPTZPresets: function (brand) {
    switch (brand) {
      case 'HIKVISION':
        return 'PTZGetPresets';
      case 'AXIS':
        return 'PTZGetPresets';
      default:
        return 'ptz/GetPresets';
    }
  },
  getPTZStatusQuery: function (brand) {
    switch (brand) {
      case 'HIKVISION':
        return 'PTZGetStatus';
      case 'AXIS':
        return 'PTZGetPosition';
      default:
        return 'ptz/GetStatus';
    }
  },
  getPTZConfigurationParser: function ({brand, data}) {
    if (!data) return
    if (!(data instanceof Object)) return
    if (!(data.data instanceof Object)) return

    switch (brand) {
      case 'HIKVISION':
        return this.parsePTZConfigurationHikvision(data.data);
      case 'AXIS':
        return this.parsePTZConfigurationAxis(data.data);
      default:
        return this.parsePTZConfigurationOnvif(data.data)
    }
  },
  getPTZConfigurationOptionsParser: function ({brand, data}) {
    switch (brand) {
      case 'HIKVISION':
        return this.parsePTZConfigurationOptionsHikvision(data);
      case 'AXIS':
        return this.parsePTZConfigurationOptionsAxis();
      default:
        return this.parsePTZConfigurationOptionsOnvif(data?.data)
    }
  },
  parsePTZConfigurationOnvif:  function (data) {
    if (!data?.GetConfigurationsResponse?.PTZConfiguration) return
    let ptz = data.GetConfigurationsResponse.PTZConfiguration

    return {
      pRange: ptz?.PanTiltLimits?.Range?.XRange,
      tRange: ptz?.PanTiltLimits?.Range?.YRange,
      zRange: ptz?.ZoomLimits?.Range?.XRange,
      token: ptz?.['-token'],
    }
  },
  parsePTZConfigurationOptionsHikvision: function (data) {
    return {
      pSpeedRange: { Max: data.pRange.Max, Min: data.pRange.Min },
      tSpeedRange: { Max: data.tRange.Max, Min: data.tRange.Min },
      zSpeedRange: { Max: data.zRange.Max, Min: data.zRange.Min, },
    }
  },
  parsePTZConfigurationOptionsAxis: function () {
    return {
      pSpeedRange: { Max: "100", Min: "1" },
      tSpeedRange: { Max: "100", Min: "1" },
      zSpeedRange: { Max: "100", Min: "1" },
    }
  },
  parsePTZConfigurationOptionsOnvif:  function (data) {
    let ptz = data?.GetConfigurationOptionsResponse?.PTZConfigurationOptions

    // 값이 없을 경우...
    if (!ptz) return {
      pSpeedRange: { Max: "1", Min: "0" },
      tSpeedRange: { Max: "1", Min: "0" },
      zSpeedRange: { Max: "1", Min: "0" },
    }

    return {
      pSpeedRange: ptz?.Spaces?.PanTiltSpeedSpace?.XRange,
      tSpeedRange: ptz?.Spaces?.PanTiltSpeedSpace?.XRange,
      zSpeedRange: ptz?.Spaces?.ZoomSpeedSpace?.XRange,
    }
  },
  parsePTZConfigurationHikvision: function (data) {
    const ptRanges = data?.PTZChanelCap?.ContinuousPanTiltSpace
    const zRanges = data?.PTZChanelCap?.ContinuousZoomSpace
    return {
      pRange: {Min: ptRanges?.XRange?.Min, Max: ptRanges?.XRange?.Max},
      tRange: {Min: ptRanges?.YRange?.Min, Max: ptRanges?.YRange?.Max},
      zRange: {Min: zRanges?.ZRange?.Min, Max: zRanges?.ZRange?.Max},
    }
  },
  parsePTZConfigurationAxis: function (data) {
    const { MaxPan, MaxTilt, MaxZoom, MinPan, MinTilt, MinZoom } = data
    return {
      pRange: {Min: MinPan, Max: MaxPan},
      tRange: {Min: MinTilt, Max: MaxTilt},
      zRange: {Min: MinZoom, Max: MaxZoom},
    }
  },
  getPTZPresetsParser: function (brand, data) {
    switch (brand) {
      case 'HIKVISION':
        return this.parsePTZPresetsHikvision(data?.data);
      case 'AXIS':
        return this.parsePTZPresetsAxis(data?.data);
      default:
        return this.parsePTZPresetsOnvif(data?.data)
    }
  },
  parsePTZPresetsOnvif: function (data) {
    const presets = data?.GetPresetsResponse?.Preset
    if(!presets) {
      return null
    }
    if(!Array.isArray(presets)) {
      return [{
        name: presets.Name,
        token: presets['-token'],
      }]
    } else {
      return presets.map(p => {
        return {
          name: p.Name,
          token: p['-token'],
        }
      })
    }
  },
  parsePTZPresetsHikvision: function (data) {
    let presets = [];

    let baseData = data?.PTZPresetList?.PTZPreset
    if (!baseData) return []

    for (let key in baseData) {
      if (baseData[key]?.enabled === 'true') {
        presets.push({
          name: baseData[key]?.presetName,
          token: baseData[key]?.id,
        });
      }
    }
    return presets;
  },
  parsePTZPresetsAxis: function (data) {
    if (!data) return
    
    let presets = [];
    for (let key in data) {
      if (key.startsWith('presetposno')) {
        presets.push({
          name: data[key],
          token: data[key],
        });
      }
    }
    return presets;
  },
  parsePTZStatus: function ({data, brand}) {
    switch (brand) {
      case 'AXIS':
        return this.parsePTZStatusAxis(data.data);
      default:
        return this.parsePTZStatusOnvif(data.data)
    }
  },
  parsePTZStatusAxis: function (data) {
    return {
      p: data?.pan,
      t: data?.tilt,
      z: data?.zoom,
    }
  },
  parsePTZStatusOnvif: function (data) {
    let status = data?.GetStatusResponse?.PTZStatus
    return {
      p: status?.Position?.PanTilt?.['-x'],
      t: status?.Position?.PanTilt?.['-y'],
      z: status?.Position?.Zoom?.['-x'],
    }
  },
  getPTZAreaZoomQuery: function (brand) {
    switch (brand) {
      case 'HIKVISION':
        return 'PTZPosition3D';
      case 'AXIS':
        return 'AreaZoom';
    }
  },
  getPTZAreaZoomQueryOption: function ({ brand, p, t, z }) {
    switch (brand) {
      case 'HIKVISION':
        return this.ptzToHikvisionAreaZoom(p, t, z);
      case 'AXIS':
        return this.ptzToAxisAreaZoom(p, t, z);
    }
  },
  ptzToHikvisionAreaZoom: function (p, t, z) {
    const width = 255;
    const height = 255;
    const zoom = 255;

    const panCenter = Math.round(p.xCenterPercent * width);
    const tiltCenter = Math.round((1 - t.yCenterPercent) * height);

    const pan1 = z === 1 ? panCenter : Math.round(p.xStartPercent * width);
    const pan2 = z === 1 ? panCenter : Math.round(p.xEndPercent * width);
    const tilt1 = z === 1 ? tiltCenter : Math.round((1 - t.yStartPercent) * height);
    const tilt2 = z === 1 ? tiltCenter : Math.round((1 - t.yEndPercent) * height);

    // const pan = Math.round(p.xCenterPercent * width);
    // const tilt = Math.round((1 - t.yCenterPercent) * height); // y기준이 아래서부터 0이라서 변환.

    return `x1=${pan1}&y1=${tilt1}&x2=${pan2}&y2=${tilt2}`;
  },
  ptzToAxisAreaZoom: function (p, t, z) {
    const width = 1000;
    const height = 1000;
    const zoom = 100;

    const pan = Math.round(p.xCenterPercent * width);
    const tilt = Math.round(t.yCenterPercent * height);
    const zoomLevel = Math.round(z * zoom);

    return `x=${pan}&y=${tilt}&z=${zoomLevel}`;
  }
}
