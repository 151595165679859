<template>
  <div>
  </div>
</template>

<script>

export default {
  name: 'webrtc-manager',
  computed: {
    selectedSiteId: function () {
      return this.$store.getters.selectedSiteId
    },
    devices: function () {
      return this.$store.getters.siteDevices
    },
    statusGetSiteDevices: function () {
      return this.$store.getters.statusGetSiteDevices
    },
    networkType: function () {
      return this.$store.getters.networkType
    },
  },
  data: function () {
    return {
    }
  },
  created: function () {
  },
  mounted: function() {
    this.$webrtc.init()
  },
  watch: {
    $route: function () {
      this.$store.commit('SET_CURRENT_PAGE_DEVICE_ID', this.$route?.params?.deviceId)
    },
    selectedSiteId: async function () {
      for (let i = 0; i < 10; i++) {
        if (this.statusGetSiteDevices !== 'successful') {
          await this.$tool.wait(500)
          continue
        }
        this.$webrtc.resetAndReconnectPeers()
        break
      }
    },
    networkType: function () {
      // network type이 변경될시 peer다시 연결
      if (this.networkType === 'wifi') {
        this.$webrtc.resetAndReconnectPeers()
      }
      // TODO: video webrtc할때 cellular인경우 유저에게 물어보는 것도 고려 요망
      else if (this.networkType === 'cellular') {
        this.$webrtc.resetAndReconnectPeers()
      }
      else if (this.networkType === 'none') {
        this.$webrtc.clearAllPeers()
      }
    }
  },

}
</script>

<style>
</style>
