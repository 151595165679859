const baseRoutes = [
  {
    path: '/tabs',
    component: () => import("@/_routes/tabs/Root"),
    redirect: '/tabs/security',
    children: [
      {
        path: 'security',
        component: () => import("@/_routes/tabs/Security")
      },
      {
        path: 'cameras',
        component: () => import("@/_routes/tabs/Cameras")
      },
      {
        path: 'activity',
        component: () => import("@/_routes/tabs/Activity")
      },
    ],
  },
]

const routes = baseRoutes

export default routes
