import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getLastAgreementInfo: function () {
    let apiDetailPath = this.apiPrefix + '/toss/last'
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getAgreement: function (tosId) {
    let apiDetailPath = this.apiPrefix + `/toss/${tosId}`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  updateAgreement: function (tosId) {
    let apiDetailPath = this.apiPrefix + '/toss'
    let data = {
      tos_id: tosId
    }
    return Vue.http.post(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
}
