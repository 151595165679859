const baseRoutes = [
  {
    path: '/settings/account',
    component: () => import("@/_routes/settings/account/Root"),
  },
  {
    path: '/settings/account/name',
    component: () => import("@/_routes/settings/account/Name")
  },
  {
    path: '/settings/account/password',
    component: () => import("@/_routes/settings/account/Password")
  },
  {
    path: '/settings/account/email',
    component: () => import("@/_routes/settings/account/Email")
  },
]

const routes = baseRoutes

export default routes
