export default {
  parseSiteCammeraOnvifProfiles: function (data) {
    if (!data) return
    
    // init
    let video = {}
    let audio = {}
    let audioBc = {}
    let dataCh = {}
    let ptz = {}
    
    if ((typeof data?.stream_capabilities?.video?.codec) !== 'string') return

    // video
    // H264, H265 아니면 스킵
    if (data?.stream_capabilities?.video?.codec?.toUpperCase() !== 'H264' &&
        data?.stream_capabilities?.video?.codec?.toUpperCase() !== 'H265') return


    video = data.stream_capabilities?.video

    video.fps = Math.floor(data.fps)
    video.resolution = data.resolution
    video.encodingGop = data.encoding_gop

    const dimensions = data.resolution.split('x');
    const width = parseInt(dimensions[0], 10);
    const height = parseInt(dimensions[1], 10);
    const pixels = width * height;

    if (921600 <= pixels) {
      video.name = 'High Quality'
      video.label = 'HQ'
    }
    else if (921600 > pixels && 230400 <= pixels) {
      video.name = 'Medium Quality'
      video.label = 'MQ'
    }
    else if (230400 > pixels) {
      video.name = 'Low Quality'
      video.label = 'LQ'
    }

    video.pixels = pixels


    
    // audio
    audio = data.stream_capabilities.audio

    // audio back channel
    audioBc = data.stream_capabilities.audio_bc

    // data channel
    dataCh = data.stream_capabilities.data

    // ptz
    ptz.ptz_supported = data.ptz_supported
    ptz.ptz_node_token = data.ptz_node_token

    return {
      url: data?.stream_capabilities?.url,
      profileToken: data?.profile_token,
      audioOutputConfigToken: data?.audio_output_configuration_token,
      audioOutputToken: data?.audio_output_token,
      video: video,
      audio: audio,
      audioBc: audioBc,
      dataCh: dataCh,
      ptz: ptz,
    }
  },
}