<template>
  <div></div>
</template>

<script>
import { modalController } from '@modus/ionic-vue'
import VideoPlayerModal from '@/components/modals/VideoPlayer'
export default {
  name: 'event-video-viewer-manager',
  computed: {
    selectedSiteId: function () {
      return this.$store.getters.selectedSiteId
    },
    siteEvent: function () {
      return this.$store.getters.siteEvent
    },
    eventRecords: function () {
      return this.$store.getters.eventRecords
    },
    recordMedia: function () {
      return this.$store.getters.recordMedia
    },
    statusGetRecordMedia: function () {
      return this.$store.getters.statusGetRecordMedia
    },
    siteActivityLog: function () {
      return this.$store.getters.siteActivityLog
    },
    requestedEventForEventVideoViewer: function () {
      return this.$store.getters.requestedEventForEventVideoViewer
    },
  },
  data: function () {
    return {
    }
  },
  watch: {
    requestedEventForEventVideoViewer: function () {
      if (!this.requestedEventForEventVideoViewer) return
      if (!(this.requestedEventForEventVideoViewer instanceof Object)) return
      // clear event info before store.
      this.$store.commit('CLEAR_EVENT_VIEW_INFO')
      let eventId = this.requestedEventForEventVideoViewer?.eventId
      let timeStr = this.requestedEventForEventVideoViewer?.timeStr ?? 'Unknown'
      let deviceName = this.requestedEventForEventVideoViewer?.deviceName ?? 'No Name'

      if (!eventId) return

      // Action - open video
      this.openVideo(eventId, timeStr, deviceName)
    },
  },
  created: function () {
  },
  beforeDestroy: function () {
  },
  methods: {
    openVideo: async function (eventId, timeStr, deviceName) {
      // 🗃️ emit start routine to store
      this.$store.commit('IS_FINISH_STORE_EVENT_VIDEO_AND_SNAPSHOTS_ROUTINE', false)

      let chektVideoPlayer = document.getElementById('--chekt-video-player')
      
      if (!chektVideoPlayer) this.openModal(eventId, timeStr, deviceName)

      //스냅샷 timeout = 미국에서 한국으로 스냅샷을 보낼때 10~15초, 10*2=20초
      let maxRetry = 10
      let retryInterval = 1000 * 2 // seconds
      let urls = {}

      try {
        // url을 얻기위한 시도
        for (let i=0; i < maxRetry; i++) {
          urls = await this.getEventVideoAndSnapshots(eventId)
          
          // video url을 얻을때 까지 시도한다. (최대 20초)
          if (urls?.videoUrl) break

          // retryInterval만큼 기다린후 다시시작
          await this.$tool.wait(retryInterval)
        }
      } 
      catch(err) {
        this.$logger.error(err)
      }

      // 🗃️ emit finish routine to store
      this.$store.commit('IS_FINISH_STORE_EVENT_VIDEO_AND_SNAPSHOTS_ROUTINE', true)
    },
    openModal: function (eventId, timeStr, deviceName) {
      // ACTION - open video
      modalController.create({
      // swipeToClose: true,
      showBackdrop: false,
      component: VideoPlayerModal,
      componentProps: {
        propsData: {
          store: this.$store,
          tool: this.$tool,
          eventId: eventId,
          timeStr: timeStr,
          deviceName: deviceName,
        },
      }}).then(m => m.present())
    },
    getEventVideoAndSnapshots: async function (eventId) {
      try {
        let recordIndex = ''
        let recordId = ''
        let url = ''
        let videoUrl = null
        let snapshotUrls = []

        // REQUEST - 🗃️ emit info to store
        this.$store.commit('STORE_EVENT_SNAPSHOT_URLS_REQUEST')
        this.$store.commit('STORE_EVENT_VIDEO_URL_REQUEST')

        // GET - event media records
        let event = this.siteEvent(eventId)
        if (event === undefined) {
          await this.loadSiteEvent(eventId)
          event = this.siteEvent(eventId)
        }
        if (event === undefined) return
        const siteId = event.deviceInfo.siteId
        const deviceId = event.deviceInfo.deviceId

        // GET - Media (video.mp4)
        await this.$store.dispatch('getEventRecords', {siteId, deviceId, eventId})

        const eventRecords = this.eventRecords(eventId)
        if (eventRecords === undefined) return

        for (let i = 0; i < eventRecords.length; i++) {
          // VIDEO - get video url for get video src
          if (('' + eventRecords[i].format).toLowerCase() === 'mp4') {
            recordIndex = i
            recordId = eventRecords[i].recordId
            url = eventRecords[i].mediaUrl
          }
          // SNAPSHAOT - get snapshot img urls
          else {
            snapshotUrls.push(eventRecords[i].mediaUrl)
          }
        }
        // SUCCESS - 🗃️ emit info to store
        if (snapshotUrls.length) this.$store.commit('STORE_EVENT_SNAPSHOT_URLS_SUCCESS', snapshotUrls)
        // FAILURE - 🗃️ emit info to store
        else this.$store.commit('STORE_EVENT_SNAPSHOT_URLS_FAILURE')

        // VIDEO - get video src with video url
        const statusGetRecordMedia = this.statusGetRecordMedia(recordId)
        if (statusGetRecordMedia !== 'requested' && statusGetRecordMedia !== 'successful') {
          await this.$store.dispatch('getRecordMedia', {eventId, recordIndex, recordId, url})
        }

        // VIDEO - update src and codec
        const recordMedia = this.recordMedia(recordId)
        if (recordMedia === undefined) return
        videoUrl = recordMedia

        // SUCCESS - 🗃️ emit info to store
        if (videoUrl) this.$store.commit('STORE_EVENT_VIDEO_URL_SUCCESS', videoUrl)
        // FAILURE - 🗃️ emit info to store
        else this.$store.commit('STORE_EVENT_VIDEO_URL_FAILURE')

        return {snapshotUrls, videoUrl}
      } 
      catch (err) {
        this.$logger.error(err)
        return
      }
    },
    loadSiteEvent: async function (eventId) {
      // CHECK - selectedSiteId
      if (!this.selectedSiteId) return
      // ACTION
      return await this.$store.dispatch('getSiteEvent', {
        siteId: this.selectedSiteId,
        eventId,
      })
    },
  }
}
</script>

<style scoped>
</style>
