import Vue from 'vue'
var moment = require('moment')//for get time

export default {
  apiPrefix: '/api/v2/sites',
  // USE 1 - Get only older events             - targetEventId={{firstEventId}}
  // USE 2 - Get only newer events             - targetEventId={{lastEventId}}, toGetNewEvents=true
  // USE 3 - Get events with time (only older) - targetEventId={{firstEventId}}, stime={{start time}}, etime={{end time}}
  // USE 4 - Get events with time              - stime={{start time}}, etime={{end time}}
  getSiteDeviceEvents: function ({siteId, deviceId, stime, etime, eventTypes, eventStatus, targetEventId, toGetNewEvents, siteTimezone}) {
    // SET - stime, etime
    if (!stime) stime = 0
    if (!etime) etime = 0
    // SET - site stime
    // ********************************** 여기서 Activity log 기간 설정하기! 
    // 사이트 타임존으로 색깔 입히기
    let nowWithSiteTimezone = moment.tz(siteTimezone)
    // 30일전 00:00:00 으로 변경
    let nowBefore30days = nowWithSiteTimezone.hours(0).minutes(0).seconds(0).hours(-24*30)
    // 사이트 타임존기준으로 UTC시간 구하기
    stime = nowBefore30days.tz('UTC').unix()
    // ********************************** 여기서 Activity log 기간 설정하기! 
    // API URL
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${deviceId}/events/stime/${stime}/etime/${etime}`
    // OPTION 1 - limit total event to 50
    let query = '?limit=50'
    // OPTION 2 - category
    if (eventTypes) query += `&filter=device_event.type,${eventTypes}`
    // OPTION 3 - to retrieve ONLY events not yet retrieved
    if (targetEventId) {
      if (toGetNewEvents) {
        query += '&dir=asc&sort=device_event.event_id'
        query += `&filter=device_event.event_id,${targetEventId},gt`
      }
      else {
        query += '&dir=desc&sort=device_event.event_id'
        query += `&filter=device_event.event_id,${targetEventId},lt`
      }
    }
    else {
      query += '&dir=desc&sort=device_event.event_id'
    }
    // OPTION 4 - to retrieve ONLY certain event types [di|netloss|...]
    // if (eventTypes instanceof Array) eventTypes.map(eventType => { query += `&filter=device_event.type,${eventType},eq` })
    // OPTION 5 - to retrieve ONLY certain event status [active|inactive]
    if (eventStatus) query += `&filter=device_event.status,${eventStatus},eq`
    // ACTION
    return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  },
  getSiteDeviceEvent: function ({siteId, deviceId, eventId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${deviceId}/events/${eventId}`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
}
