export default {
  parseDealerData: function (data) {
    return {
      dealerId: data.dealer_id,
      dealerName: data.company_name,
      dealerLogoUrl: data.logo_url,
      profilePictureUrl: data.provider_picture,
      ownerInfo: {
        userId: data.user_id,
        email: data.email,
        firstName: data.firstname,
        lastName: data.lastname
      },
      locationInfo: {
        address: (data.company_address2 ? data.company_address2 + ' ' : '') + data.company_address1,
        postalCode: data.company_zipcode,
        timezone: data.timezone,
        lat: data.lat,
        lng: data.lng
      },
      contactInfo: {
        phone: data.company_phone,
        email: data.company_email,
        info: data?.company_contact_info,
      },
      statusInfo: {
        isDeleted: !!data.is_deleted,
        isRegistered: !!data.is_registered,
        isMonitoringBlocked: !!data.is_monitoring_block,
        createdDate: data.created ? new Date(data.created) : null,
        registeredDate: data.registered_time ? new Date(data.registered_time) : null,
        deletedDate: data.deleted_time ? new Date(data.deleted_time) : null
      },
      statistics: {
        totalSite: data.site_count ? data.site_count : 0,
        totalBridge: data.bridge_count ? data.bridge_count : 0,
        totalCamera: data.camera_count ? data.camera_count : 0,
        totalUser: data.user_count ? data.user_count : 0
      }
    }
  },
}