export default {
  parseEventData: function (data) {
    return {
      eventId: data.event_id,
      eventStatus: data.status,
      eventType: data.type,
      eventTimestamp: data.timestamp,
      deviceInfo: {
        deviceId: data.device_id,
        deviceName: data.name,
        siteId: data.site_id
      },
      statistics: {
        totalView: data.watch
      },
      statusInfo: {
        createdDate: data.created ? new Date(data.created) : null
      }
    }
  },
}
