import Vue from 'vue'
import {
  REQUEST_LOGOUT,
} from '@/store/mutation-types'

let initialState = {
  logoutRequest: 0, // just increase counter to notify StoreUpdateManager
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  logoutRequest: function (state) {
    return state.logoutRequest
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [REQUEST_LOGOUT]: function (state) {
    state.logoutRequest++
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
