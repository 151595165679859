export default {
  parseRecordData: function (data) {
    return {
      recordId: data.record_id,
      eventId: data.event_id,
      format: data.format, // 'jpg', 'mp4'
      mediaTimestamp: data.timestamp,
      mediaUrl: data.url,
      hasAccessToken: data.url.indexOf('?') > -1,
      deviceInfo: {
        deviceId: data.device_id,
        isPrivacyEnabled: !!data.is_privacy
      },
      statusInfo: {
        createdDate: data.created ? new Date(data.created) : null,
        completed: !!data.completed,
        downloaded: false,
        reallyUploaded: null, // it is to validate 'data.completed'
        codec: null,
        completedClassification: !!data.completed_classification,
        completedCaptioning: !!data.completed_captioning
      }
    }
  },
}
