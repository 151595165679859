import store from '@/store'
import * as momentTimezone from 'moment-timezone'
import * as moment from 'moment'
import jwtDecode from 'jwt-decode'
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx'
import { SocialSharing } from '@ionic-native/social-sharing/ngx'
import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx'
// IOS 16부터 old Screen Orientation이 동작하지 않음.
import { ScreenOrientation as OldScreenOrientation } from '@ionic-native/screen-orientation/ngx'
import { ScreenOrientation } from '@capacitor/screen-orientation'

import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Preferences } from '@capacitor/preferences';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { SplashScreen } from '@capacitor/splash-screen';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { Network } from '@capacitor/network';

const ToolPlugin = {
  install (Vue) {
    const spinnerDialog = new SpinnerDialog()
    const oldScreenOrientation = new OldScreenOrientation()
    const openNativeSettings = new OpenNativeSettings()
    let loadingDialog = null
    let text = null

    let prevDiff = -1;   
    const evHistory = [];

    let FAKE_AUDIO_ELEMENT = null
    let WEBRTC_BRIDGES = null

    let tool = {
      wait: function (time) {
        return new Promise(resolve => {
          setTimeout(() => { resolve() }, time)
        })
      },
      showLoadingDialog: function (message) {
        if (!loadingDialog) {
          loadingDialog = document.createElement('div')
          text = document.createElement('div')
          let dialog = document.createElement('div')
          let backdrop = document.createElement('div')
          let spinner = document.createElement('ion-spinner')
          let style = document.createElement('style');

          style.innerText = `
            .animate {
              animation: fadeOut .3s ease;
            }
            @keyframes fadeOut {
              0% { opacity: 0; }
              100% { opacity: 1; }
            }
          `;

          backdrop.addEventListener('click', () => {
            if (loadingDialog) loadingDialog.style.display = `none`
          })


          loadingDialog.style.position = `absolute`
          loadingDialog.style.display = `flex`
          loadingDialog.style.justifyContent = `center`
          loadingDialog.style.alignItems = `center`
          loadingDialog.style.width = `100%`
          loadingDialog.style.height = `100%`

          dialog.style.maxWidth = `80%`
          dialog.style.fontSize = `14px`
          dialog.style.fontWeight = `600`
          dialog.style.padding = `24px`
          dialog.style.backgroundColor = `var(--ion-color-light)`
          dialog.style.zIndex = `1`
          dialog.style.display = `flex`
          dialog.style.justifyContent = `center`
          dialog.style.alignItems = `center`
          if (message) dialog.style.gap = `7px`
          dialog.style.borderRadius = `10px`

          backdrop.style.position = `absolute`
          backdrop.style.width = `100%`
          backdrop.style.height = `100%`
          backdrop.style.backgroundColor = `var(--ion-backdrop-color)`
          backdrop.style.opacity = `var(--ion-backdrop-opacity)`


          if (message) text.innerText = message

          dialog.appendChild(spinner)
          dialog.appendChild(text)
          loadingDialog.appendChild(backdrop)
          loadingDialog.appendChild(dialog)
          loadingDialog.appendChild(style)
        }

        loadingDialog.style.display = `flex`
        let app = document.querySelector(`ion-app`)
        if (app) app.appendChild(loadingDialog)
        loadingDialog.classList.add('animate');
      },
      hideLoadingDialog: function () {
        if (loadingDialog) loadingDialog.style.display = `none`
      },
      changeTextLoadingDialog: function (message) {
        if (text && message) text.innerText = message
      },
      requestAudioRecordingPermission: async function () {
        await VoiceRecorder.requestAudioRecordingPermission()
      },
      startRecording: async function () {
        await VoiceRecorder.startRecording()
      },
      stopRecording: async function () {
        return await VoiceRecorder.stopRecording()
      },
      addAppStatusListener: function () {
        // Listen app status change (active/inactive) 
        App.addListener('appStateChange', (app = AppState) => {
          store.commit('IS_APP_ACTIVE', app.isActive)
        })
        // Listen app is completely go to background
        App.addListener('pause', () => {
          store.commit('IS_APP_USE', false)
        })
        // Listen app is completely came.
        App.addListener('resume', () => {
          store.commit('IS_APP_USE', true)
        })
      },
      removeAllAppListener: function () {
        App.removeAllListeners()
      },
      addNetworkListener: async function () {
        if (!store.getters.supportNetwork) return 
        // 최초 한번 status값 업데이트 해주기
        const status = await Network.getStatus();
        store.commit('IS_NETWORK_CONNECTED', status)

        Network.addListener('networkStatusChange', status => {
          store.commit('IS_NETWORK_CONNECTED', status)
        });
      },
      removeNetworkListener: async function () {
        if (!store.getters.supportNetwork) return 
        Network.removeAllListeners()
      },
      getNetworkStatus: async function () {
        if (!store.getters.supportNetwork) return 
        return await Network.getStatus();
      },
      isOpenedInBrowser() {
        // Exclude known WebView user agents
        return !/Version\/\d+\.\d+/i.test(navigator.userAgent);
      },
      lockScreenOrientation: function (type) {
        if(this.isOpenedInBrowser) return;

        // Default는 portrait
        if (!type) type = 'portrait'

        // NEW
        if (store.getters.supportNewScreenOrientation) {

          if (type === 'landscape') {
            // 오른 손잡이 기준
            ScreenOrientation.lock({ orientation: 'landscape-secondary' })
            store.commit('IS_FULLSCREEN', true)
          }
          else {
            ScreenOrientation.lock({ orientation: type})
            store.commit('IS_FULLSCREEN', false)
          }

          store.commit('IS_SCREEN_ORIENTATION_LOCK', true)
        }
        // OLD
        else {
          if (type === 'landscape') {
            oldScreenOrientation.lock(oldScreenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY)
            store.commit('IS_FULLSCREEN', true)
          }
          else {
            oldScreenOrientation.lock(oldScreenOrientation.ORIENTATIONS.LANDSCAPE)
            store.commit('IS_FULLSCREEN', false)
          }

          store.commit('IS_SCREEN_ORIENTATION_LOCK', true)
        }
      },
      unlockScreenOrientation: async function () {
        if(this.isOpenedInBrowser) return;

        if (store.getters.supportNewScreenOrientation) {
          await ScreenOrientation.unlock()
          store.commit('IS_SCREEN_ORIENTATION_LOCK', false)
        }
        else {
          oldScreenOrientation.unlock()
          store.commit('IS_SCREEN_ORIENTATION_LOCK', false)
        }

        // 폰이 세로로 뒤집어 졌을 때는 무시하는 것으로 판단.
        if (store.getters.currentAppOrientationType  + '' ===  'portrait-secondary') return

        if ((store.getters.currentAppOrientationType + '').indexOf('landscape') > -1) store.commit('IS_FULLSCREEN', true)
        else store.commit('IS_FULLSCREEN', false)
      },
      subscribeOrientationChange: function () {
        if(this.isOpenedInBrowser) return;

        if (store.getters.supportNewScreenOrientation) {
          ScreenOrientation.addListener('screenOrientationChange', async () => {
            let orientation = await ScreenOrientation.orientation()
            store.commit('CURRENT_APP_ORIENTATION_TYPE', orientation.type)

            if (store.getters.isScreenOrientationLock) return

            // 폰이 세로로 뒤집어 졌을 때는 무시하는 것으로 판단.
            if (orientation.type + '' ===  'portrait-secondary') return

            if ((orientation.type + '').indexOf('landscape') > -1) store.commit('IS_FULLSCREEN', true)
            else store.commit('IS_FULLSCREEN', false)
          })
        }
        else {
          oldScreenOrientation.onChange().subscribe(() => {
            store.commit('CURRENT_APP_ORIENTATION_TYPE', oldScreenOrientation.type)

            if (store.getters.isScreenOrientationLock) return

            // 폰이 세로로 뒤집어 졌을 때는 무시하는 것으로 판단.
            if (oldScreenOrientation.type + '' ===  'portrait-secondary') return

            if ((oldScreenOrientation.type + '').indexOf('landscape') > -1) store.commit('IS_FULLSCREEN', true)
            else store.commit('IS_FULLSCREEN', false)
          })
        }
      },
      unsubscribeOrientationChange: function () {
        if(this.isOpenedInBrowser) return;

        if (store.getters.supportNewScreenOrientation) { 
          ScreenOrientation.removeAllListeners()
        }
        else {
          oldScreenOrientation.unsubscribe()
        }
      },
      openNativeAppSettings: async function () {
        try {
          await openNativeSettings.open('application_details')
        } 
        catch (err) {
          alert(`Can't open the settings page. Please go to settings and allow manually.`)
        }
      },
      saveToPhotoAlbumForAndroid: function (src) {
        return new Promise((resolve, reject) => { 
          // Define nane
          const fileName = new Date().getTime() + '.jpg';
          // save photo
          Filesystem.writeFile({
            path: fileName,
            data: src,
            directory: Directory.Documents,
          }).then(() => {
            resolve(true)
          }, () => {
            reject(false)
          })
        })
      },
      saveToPhotoAlbumForIos: function (src) {
        return new Promise((resolve, reject) => { 
          let socialSharing = new SocialSharing()
          socialSharing.saveToPhotoAlbum(src).then(() => {
            resolve(true)
          }, () => {
            reject(false)
          })
        })
      },
      saveToPhotoAlbum: function (src) {
        if (Vue.env.deviceInfo.platform === 'android') {
          return this.saveToPhotoAlbumForAndroid(src)
        }
        else if (Vue.env.deviceInfo.platform === 'ios') {
          return this.saveToPhotoAlbumForIos(src)
        }
      },
      downloadFile: function (file, fileName, fileType) {
        let anchor = document.createElement('a');
        anchor.style.display = 'none';
        document.body.appendChild(anchor);

        // Set the anchor's href attribute to the S3 URL
        anchor.href = file;

        // Set the download attribute to specify the file name
        anchor.setAttribute('download', fileName);

        // Trigger a click event on the anchor element to start the download
        anchor.click();

        // Remove the anchor element from the DOM after a short delay
        setTimeout(function() {
            document.body.removeChild(anchor);
        }, 100);
      },
      shareFile: function (file, fileName, fileType) {
        let newFile = ''
        // IOS 파일명 넣기
        switch (fileType) {
          case 'video':
            newFile = `df:${fileName}.mp4;` + file
            break;
           case 'audio':
            newFile = `df:${fileName}.mp3;` + file
            break;
          default:
            break;
        }

        return new Promise((resolve, reject) => {
          let socialSharing = new SocialSharing()
          socialSharing.shareWithOptions({
            files: [newFile],
            subject: fileName,
          }, resolve, reject)
        })
      },
      showStatusBar: async function () {
        if (Vue.env.deviceInfo.platform != 'web') {
          await StatusBar.show()
        }
      },
      hideStatusBar: async function () {
        if (Vue.env.deviceInfo.platform != 'web') {
          await StatusBar.hide()
        }
      },
      showKeyboard: function () {
        if (Vue.env.deviceInfo.platform == 'web') return;
        Keyboard.show()
      },
      hideKeyboard: function () {
        if (Vue.env.deviceInfo.platform == 'web') return;
        Keyboard.hide()
      },
      showSplashScreen: async function () {
        await SplashScreen.show()
      },
      hideSplashScreen: async function () {
        await SplashScreen.hide()
      },
      // keyboardSetScroll: function () {
      //   Keyboard.setScroll({ isDisabled: true })
      // },
      // setAccessoryBarVisible: function () {
      //   Keyboard.setAccessoryBarVisible({ isVisible: true })
      // },
      // setResizeMode: function (options) {
      //   Keyboard.setResizeMode({ options: options })
      // },
      // keyboardWillShow: function () {
      //  Keyboard.addListener('keyboardWillShow', (KeyboardInfo) => {
      //     // this.currentkeyboardHeight() = KeyboardInfo.keyboardHeight
      //   })
      // },
      // keyboardDidShow: function () {
      //   Keyboard.addListener('keyboardDidShow', (KeyboardInfo) => {
      //     // console.log('keyboard did show with height', KeyboardInfo.keyboardHeight)
      //   });
      //  },
      // removeAllKeyboardListeners: function () {
      //   Keyboard.removeAllListeners()
      // },
      hapticsVibrate: function () {
        return Haptics.vibrate()
      },
      hapticsImpact(style) {
        if (Vue.env.deviceInfo.platform != 'web') {
          return Haptics.impact({
            style: style
          })
        }
      },
      hapticsImpactLight: function () {
        return this.hapticsImpact({style: ImpactStyle.Light});
      },
      hapticsImpactMedium: function () {
        return this.hapticsImpact({style: ImpactStyle.Medium});
      },
      hapticsImpactHeavy: function () {
        return this.hapticsImpact({style: ImpactStyle.Heavy});
      },
      async toggleStatusBarColor() {
        if (Vue.env.deviceInfo.platform === 'web') return
        
        await StatusBar.setStyle({
          style: this.isStatusBarLight ? Style.Dark : Style.Light
        })
        this.isStatusBarLight = !this.isStatusBarLight
      },
      // Display content under transparent status bar (Android only)
      async setStatusBarTransparent(Boolean) {
        if (Vue.env.deviceInfo.platform === 'web') return

        await StatusBar.setOverlaysWebView({
          overlay: Boolean
        })
      },
      async setStatusBarColor(mode) {
        if (Vue.env.deviceInfo.platform === 'web') return

        switch (mode) {
        case "dark":
          await StatusBar.setStyle({ style: Style.Dark })
          this.isStatusBarLight = false
          break
        case "light": // "light" is default
        default:
          await StatusBar.setStyle({ style: Style.Light })
          this.isStatusBarLight = true
          break
        }
        // Display content under transparent status bar (Android only)
        // StatusBar.setOverlaysWebView({
        //   overlay: false
        // })
      },
      hideSpinnerDialog: function () {
        if (Vue.env.deviceInfo.platform != 'web') {
          spinnerDialog.hide()
        }
      },
      showSpinnerDialog: function () {
        if (Vue.env.deviceInfo.platform != 'web') {
          spinnerDialog.show()
          // Set spinner dialog fixed - user cannot hide
          // spinnerDialog.show(null, null, true)
        }
      },
      getStatusBarInfo: async function () {
        if (Vue.env.deviceInfo.platform === 'web') {
          return {visible: true}
        } else {
          return StatusBar.getInfo()
        }
      },
      presentToast: async function (message, cssClass, id) {
        const toast = document.createElement('ion-toast')
        // id 받을시 TOAST 중복 SHOW 하지마라.
        if (id) {
          toast.setAttribute("id", id)
          let toastEl = document.querySelectorAll(`[id="${id}"]`)
          if (toastEl.length > 0) return
        }

        toast.message = message
        toast.duration = 2000
        toast.cssClass = cssClass
      
        document.body.appendChild(toast)
        return toast.present()
      },
      getStorageKey: function (key) {
        if ((key.indexOf(`chekt::prod::`) > -1) || (key.indexOf(`chekt::dev::`) > -1)) {
          return key
        }
        if (Vue.env.isProduction) {
          return `chekt::prod::${key}`
        } 
        return `chekt::dev::${key}`
      },
      storageGetKeys: async function () {
        const { keys } = await Preferences.keys()
        return keys
      },
      storageGetItem: async function (key) {
        key = this.getStorageKey(key)
        const { value } = await Preferences.get({ key })
        return value
      },
      storageSetItem: function (key, value) {
        key = this.getStorageKey(key)
        return Preferences.set({ key, value })
      },
      storageRemoveItem: function (key) {
        key = this.getStorageKey(key)
        return Preferences.remove({ key })
      },
      fileWrite: async function (src, fileName, fileType) {
        this.showSpinnerDialog()
        var reg = /[\{\}\[\]\/?.,;:|\)*~`/!^\-_+<>@\#$%&\\\=\(\'\"]/gi
        var fileNameReg = fileName.replace(reg, "-") //특수문자 '-'로 치환
        try {
          if (!src) {
            this.hideSpinnerDialog()
            switch (fileType) {
              case 'video':
                alert("Can't download this video.")
                break;
              case 'audio':
                alert("Can't download this audio.")
                break;
              default:
                alert("Can't download this media.")
                break;
            }
            return
          }
          // GET - base64Url
          const base64Url = await this.getBase64FromObjectURL(src)
          // ACTION - download media
          this.downloadFile(base64Url, fileNameReg, fileType)
        } catch (err) {
          this.hideSpinnerDialog()
          switch (fileType) {
            case 'video':
              alert("Can't download this video.")
              break;
            case 'audio':
              alert("Can't download this audio.")
              break;
            default:
              alert("Can't download this media.")
              break;
          }
          this.$logger.error(err)
        }
        this.hideSpinnerDialog()
      },
      shareMedia: async function (src, fileName, fileType) {
        this.showSpinnerDialog()
        var reg = /[\{\}\[\]\/?.,;:|\)*~`/!^\-_+<>@\#$%&\\\=\(\'\"]/gi
        var fileNameReg = fileName.replace(reg, "-") //특수문자 '-'로 치환
        try {
          if (!src) {
            this.hideSpinnerDialog()
            switch (fileType) {
              case 'video':
                alert("Can't share this video.")
                break;
              case 'audio':
                alert("Can't share this audio.")
                break;
              default:
                alert("Can't share this media.")
                break;
            }
            return
          }
          // GET - base64Url
          const base64Url = await this.getBase64FromObjectURL(src)
          // ACTION - share media
          await this.shareFile(base64Url, fileNameReg, fileType)
        } catch (err) {
          this.hideSpinnerDialog()
          switch (fileType) {
            case 'video':
              alert("Can't share this video.")
              break;
            case 'audio':
              alert("Can't share this audio.")
              break;
            default:
              alert("Can't share this media.")
              break;
          }
          this.$logger.error(err)
        }
        this.hideSpinnerDialog()
      },
      openVideo: function (src) {
        const id = 'chekt-event-video'
        let video = document.getElementById(id)
        if (!video) {
          video = document.createElement('video')
        }
        else {
          video.parentNode.removeChild(video)
          video = document.createElement('video')
        }
        video.id = 'chekt-event-video'
        video.src = src
        video.autoplay = true
        video.controls = true
        video.muted = true
        // video.playsinline = true
        // video.loop = true
        video.style.position = 'absolute'
        video.style.width = '100%'
        video.style.top = '100%'
        // video.style.transform = 'translateY(101%)'
        // video.style.transition = 'transform .3s top .3s'
        video.style.zIndex = '1'
        var callback = () => {
          if (video.webkitExitFullscreen) {
            video.webkitExitFullscreen()
          } else {
            video.exitFullscreen()
          }
          video.removeEventListener('ended',callback,false)
          // video.style.top = '50%'
          // video.style.transform = 'translateY(-50%)'
        }
        video.addEventListener('ended',callback,false)
        document.body.appendChild(video)
        video.load()
        video.play()
        if (video.webkitEnterFullscreen) {
          video.webkitEnterFullscreen()
        } else {
          video.enterFullscreen()
        }
      },
      closeVideo: function () {
        const id = 'chekt-event-video'
        let video = document.getElementById(id)
        if (video.webkitExitFullscreen) {
          video.webkitExitFullscreen()
        } 
        else {
          alert('exitFullscreen')
        }
        video.parentNode.removeChild(video)
      },
      getCodecFromMP4: async function (blob) {
        let enc = new TextDecoder('utf-8')
        let arrBuffer = await new Response(blob).arrayBuffer()
        let byteArray = new Int8Array(arrBuffer)
        let items1 = enc.decode(byteArray).trim().split('moov')
        if (items1.length < 2) return null
        let items2 = items1[1].split('stsd')
        if (items2.length < 2) return null
        let videoBlock0 = items2[1]
        if (videoBlock0.length < 16) return null
        let codec = videoBlock0.slice(12, 16)
        if (codec === 'avc1') codec = 'h264'
        return codec
      },
      downloadObjectURL: function (objectURL, filename) {
        let link = document.createElement('a')
        link.href = objectURL
        link.download = filename + ''
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
      getBase64FromObjectURL: async function (objectURL) {
        const blob = await fetch(objectURL).then(r => r.blob())
        const base64data = await this.readAsDataURL(blob)
        return base64data
      },
      readAsDataURL: function (file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()
          reader.onloadend = () => {
            const base64data = reader.result
            resolve(base64data)
          }
          reader.onerror = reject
          reader.readAsDataURL(file)
        })
      },
      getTimeDiffFromNow: function (timestamp) {
        let now = new Date()
        let diff = Math.round((now.getTime() - timestamp) / 1000)
        let text = null
        let text4 = null
        let textDetail = null
        if (diff < 60) {
          text = '1m'
          text4 = '_0001m'
          textDetail = diff + 's'
        } else if (diff < 60 * 60) {
          let mDiff = Math.round(diff / 60)
          text = mDiff + 'm'
          if (mDiff < 100) text4 = '_00' + mDiff + 'm'
          if (mDiff < 10) text4 = '_000' + mDiff + 'm'
          textDetail = text
        } else if (diff < 60 * 60 * 24) {
          let hDiff = Math.round(diff / 60 / 60)
          text = hDiff + 'h'
          if (hDiff < 100) text4 = '__00' + hDiff + 'h'
          if (hDiff < 10) text4 = '__000' + hDiff + 'h'
          textDetail = text
        } else {
          let dDiff = Math.round(diff / 60 / 60 / 24)
          text = dDiff + 'd'
          if (dDiff < 1000) text4 = '___0' + dDiff + 'd'
          if (dDiff < 100) text4 = '___00' + dDiff + 'd'
          if (dDiff < 10) text4 = '___000' + dDiff + 'd'
          textDetail = text
        }
        return {
          text: text,
          text4: text4,
          textDetail: textDetail,
          diffInSec: diff
        }
      },
      setCookie: function (cname, cvalue, date) {
        let domain = this.getCookieDomainForCurrentDomain()
        if (!date) {
          let now = new Date()
          now.setFullYear(now.getFullYear() + 5)
          date = now.toUTCString()
        }
        document.cookie = encodeURIComponent(cname) + '=' + encodeURIComponent(cvalue) + ';expires=' + date + ';domain=' + domain + ';path=/'
      },
      getCookie: function (cname) {
        let name = encodeURIComponent(cname) + '='
        let cookie = document.cookie
        let ca = cookie.split(';')
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i]
          while (c.charAt(0) === ' ') {
            c = c.substring(1)
          }
          if (c.indexOf(name) === 0) {
            let cookieToReturn = decodeURIComponent(c.substring(name.length, c.length))
            if (cookieToReturn === 'undefined') return undefined
            else if (cookieToReturn === 'null') return null
            else if (cookieToReturn === 'NaN') return NaN
            else return cookieToReturn
          }
        }
        return ''
      },
      deleteCookie: function (cname) {
        this.setCookie(cname, 'meaning_less_string', 'Thu, 01 Jan 1970 00:00:01 GMT')
      },
      getCookieDomainForCurrentDomain: function () {
        let temp = window.location.hostname.split('.')
        if (temp.length > 1) {
          return '.' + temp[+temp.length - 2] + '.' + temp[+temp.length - 1]
        } else {
          return temp[0]
        }
      },
      getClosestNumberFromArray: function (targetNumber, targetArray) {
        let closestNumber = targetArray.reduce((prev, curr) => {
          return (Math.abs(+curr - +targetNumber) < Math.abs(+prev - +targetNumber) ? +curr : +prev)
        })
        return closestNumber
      },
      playTts: function (text) {
        if ('speechSynthesis' in window) {
          let msg = new SpeechSynthesisUtterance(text)
          let voiceName = 'Google US English'
          msg.voice = window['speechSynthesis'].getVoices().filter(voice => { return voice.name === voiceName })[0]
          window['speechSynthesis'].speak(msg)
        } else {
          // this.$logger.warn('TTS is not supported')
        }
      },
      cancelTts: function () {
        if ('speechSynthesis' in window) {
          window['speechSynthesis'].cancel()
        } else {
          // this.$logger.warn('TTS is not supported')
        }
      },
      redirectToUrl: function (url) {
        // similar behavior as an HTTP redirect
        window.location.replace(url)
      },
      decodeJwtToken: function (str) {
        return jwtDecode(str)
      },
      encodeToBase64: function (str) {
        return window.btoa(str)
      },
      decodeFromBase64: function (base64Str) {
        return window.atob(base64Str)
      },
      encodeToBase64Url: function (str) {
        let base64Str = this.encodeToBase64(str)
        var base64Url = base64Str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
        return base64Url
      },
      decodeFromBase64Url: function (base64Url) {
        if (!base64Url) return ''
        if (base64Url.length % 4 === 2) base64Url = base64Url + '=='
        if (base64Url.length % 4 === 3) base64Url = base64Url + '='
        let base64Str = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        let str = this.decodeFromBase64(base64Str)
        return str
      },
      newObject: function (src) {
        return JSON.parse(JSON.stringify(src))
      },
      parseToJSON: function (str) {
        let res = null
        try {
          res = JSON.parse(str)
        }
        catch (error) {
          console.error(error);
        }
        return res
      },
      parseToStringify: function (obj) {
        let res = null
        try {
          res = JSON.stringify(obj)
        }
        catch (error) {
          console.error(error);
        }
        return res
      },
      createUUID4: function () {
        // return uuid of form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
        let uuid = ''
        for (let ii = 0; ii < 32; ii += 1) {
          switch (ii) {
            case 8:
            case 20:
              uuid += '-'
              uuid += (Math.random() * 16 | 0).toString(16)
              break
            case 12:
              uuid += '-'
              uuid += '4'
              break
            case 16:
              uuid += '-'
              uuid += (Math.random() * 4 | 8).toString(16)
              break
            default:
              uuid += (Math.random() * 16 | 0).toString(16)
          }
        }
        return uuid
      },
      copyTextToClipboard: function (text) {
        let textArea = document.createElement('textarea')
        textArea.style.position = 'fixed'
        textArea.style.top = 0
        textArea.style.left = 0
        textArea.style.width = '2em'
        textArea.style.height = '2em'
        textArea.style.padding = 0
        textArea.style.border = 'none'
        textArea.style.outline = 'none'
        textArea.style.boxShadow = 'none'
        textArea.style.background = 'transparent'
        textArea.style.opacity = 0
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
          document.execCommand('copy')
          alert('"' + text + '" is successfully copied to your clipboard.')
        } catch (err) {
          alert('Oops, unable to copy text: ' + err)
        }
        document.body.removeChild(textArea)
      },
      makePhoneCall: function (phoneNumber) {
        window.location.href = 'tel:' + phoneNumber
      },
      sendEmail: function (emailAddress) {
        window.location.href = 'mailto:' + emailAddress
      },
      openMap: function (lat, lng) {
        let url = 'http://maps.apple.com/?q=' + lat + ',' + lng
        this.openNewTab(url)
      },
      openNewTab: function (url) {
        let win = window.open(url, '_blank')
        if (win) {
          // Browser has allowed it to be opened
          win.focus()
        } else {
          // Browser has blocked it
          alert('Please allow popups for this website')
        }
      },
      capitalizeFirstLetter: function (string) {
        string = string.toLowerCase()
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      getTimeStringForIOS: function (timestamp) {
        return moment(timestamp).format('h' + '\uA789' + 'mm' + '\uA789' + 'ss' + 'A')
      },
      getUtcOffset: function (timezone) {
        return momentTimezone().tz(timezone).utcOffset()
      },
      getTodayBeginTimestamp: function (timezoneOffset) {
        let now = new Date()
        // new Date()과 같은기능인데 컴퓨터시간으로 받는게 아닌(컴터시간이 틀어지는경우가 있음)클라우드시간을 받음 - time.js를 만들어야함 (모니터링 포탈보고 만들수있음 user API가 필요해 보임)
        // let now = Vue.time.newDate()
        let timestamp = now.getTime()
        if (timezoneOffset !== undefined) timestamp += timezoneOffset * 60 * 1000 + now.getTimezoneOffset() * 60 * 1000
        let localNow = new Date(timestamp)
        timestamp = Math.floor((new Date((new Date(localNow.setHours(0))).setMinutes(0))).setSeconds(0) / 1000) * 1000
        if (timezoneOffset !== undefined) timestamp -= (now.getTimezoneOffset() * 60 * 1000 + timezoneOffset * 60 * 1000)
        return timestamp
      },
      getNowTimestamp: function (timezoneOffset) {
        let now = new Date()
        // let now = Vue.time.newDate() // 위에 설명있음
        let timestamp = now.getTime()
        if (timezoneOffset !== undefined) timestamp += timezoneOffset * 60 * 1000 + now.getTimezoneOffset() * 60 * 1000
        return timestamp
      },
      getLocalTimestamp: function (timestamp, timezoneOffset) {
        let now = new Date()
        // let now = Vue.time.newDate() // 위에 설명있음
        if (timezoneOffset !== undefined) timestamp += timezoneOffset * 60 * 1000 + now.getTimezoneOffset() * 60 * 1000
        return timestamp
      },
      hasAllKeys: function (obj, keys) {
        let result = false
        if (obj instanceof Object && keys instanceof Array) {
          let undefinedKeys = []
          keys.map(key => {
            if (obj[key] === undefined) undefinedKeys.push(key)
          })
          if (undefinedKeys.length === 0) result = true
          else {
            // console.log('utils.hasAllKeys failed')
            // console.log(undefinedKeys)
          }
        } else {
          // console.log('utils.hasAllKeys failed')
        }
        return result
      },
      getFriendlyTimeFromSec: function (seconds) {
        let text = null
        if (seconds < 60) {
          text = '1m'
        } else if (seconds < 60 * 60) {
          text = Math.round(seconds / 60) + 'm'
        } else if (seconds < 60 * 60 * 24) {
          text = Math.round(seconds / 60 / 60) + 'h'
        } else {
          text = Math.round(seconds / 60 / 60 / 24) + 'd'
        }
        return text
      },
      isValidEmailAddress (email) {
        const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (regexp.test(email)) {
          return true
        } else {
          return false
        }
      },
      isValidMacAddress (macAddress) {
        let regexp = new RegExp('[0-9A-F]{12}')
        if (regexp.test(macAddress)) {
          return true
        } else {
          return false
        }
      },
      convertCamelToNormal (str) {
        return str
        // insert a space before all caps
          .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
          .replace(/^./, function (str) { return str.toUpperCase() })
      },
      getDateTimeTextForTable: function (myDate) {
        let text = ''
        if (myDate) {
          if (isNaN(myDate.getTime())) {
            text = myDate.toString()
          } else {
            let ago = '(' + this.getTimeDiffFromNow(myDate.getTime()).textDetail + ')'
            text = myDate.toISOString().substring(0, 19) + ' ' + ago
          }
        }
        return text
      },
      // debounce(Function fn, Number milliseconds_to_wait, Boolean immediate)
      _debounces: {},
      debounce: function (key, fn, milliseconds_to_wait) {
        if (this._debounces[key] === undefined) this._debounces[key] = []
        let keysToDelete = []
        for (let i = 0; i < this._debounces[key].length; i++) {
          clearTimeout(this._debounces[key][i])
          keysToDelete.push(this._debounces[key][i])
        }
        for (let i = 0; i < keysToDelete.length; i++) {
          let index = this._debounces[key].indexOf(keysToDelete[i])
          if (index > -1) delete this._debounces[key][index]
        }
        // console.log(milliseconds_to_wait + ' ' + key)
        let timeout = setTimeout(()=>{
          fn()
          // console.log(key)
        }, milliseconds_to_wait)
        this._debounces[key].push(timeout)
        return timeout
      },
      isIPv4: function (addr) {
        return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(addr)
      },
      toComparableFWVersion: function (fwVersion) {
        try {
          if (!fwVersion) return

          // Remove text
          let parsingfwVer = fwVersion.replace(/[^0-9\.]+/g, "")
          if (!parsingfwVer) return
  
          // Remove dots
          let parsedfwVer = parsingfwVer.split(".");
  
          if (!(parsedfwVer instanceof Array)) return 
  
          let first = parsedfwVer[0]
          let second = parsedfwVer[1]
          let third = parsedfwVer[2]
          let fourth = parsedfwVer[3]
          
          if (!first) first = '0'
          if (!second) second = '0'
          if (!third) third = '0'
          if (!fourth) fourth = '0'
  
          let firstPad = first.padStart(10, 0)
          let secondPad = second.padStart(10, 0)
          let thirdPad = third.padStart(10, 0)
          let fourthPad = fourth.padStart(10, 0)
  
          return `${firstPad}${secondPad}${thirdPad}${fourthPad}`
        }
        catch (e) {
          let first = '0'
          let second = '0'
          let third = '0'
          let fourth = '0'

          let firstPad = first.padStart(10, 0)
          let secondPad = second.padStart(10, 0)
          let thirdPad = third.padStart(10, 0)
          let fourthPad = fourth.padStart(10, 0)
          return `${firstPad}${secondPad}${thirdPad}${fourthPad}`
        }
      },
      generateSessionID: function (prefix) {
        var min = 10000;
        var max = 99999;
        var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        if (!prefix) prefix = randomNumber
        return `${prefix}-${randomNumber}`;
      },
      isAppVersionHigherThan: function (version) {
        // 기본적으로 문제가 있으면 false로 return
        // 왜냐하면 문제 있을 시 앱 버전이 support하지 않는다고 가정하는게 안전하기 때문에.
        if (Vue.env.deviceInfo.platform === 'web') return true
        if (!Vue.deploy.currentConfig.binaryVersion) return false;

        try {
          const currents = Vue.deploy?.currentConfig?.binaryVersion.split('.').map(Number);
          const compares = version.split('.').map(Number);
          
          const maxLength = Math.max(currents.length, compares.length);
          
          for (let i = 0; i < maxLength; i++) {
              const current = currents[i] || 0; // 해당 부분이 없으면 0으로 간주
              const compare = compares[i] || 0; // 해당 부분이 없으면 0으로 간주
      
              if (current > compare) return true;  // current가 크면 true
              if (current < compare) return false; // compare가 크면 false
          }
          
          return false; // 두 버전이 같으면 false
        } 
        catch (error) {
          return false;
        }
      },
      createAudioContext: function () {
        var AudioContext = window.AudioContext // Default
        || window.webkitAudioContext // Safari and old versions of Chrome
        || false; 

        if (AudioContext) {
          // Do whatever you want using the Web Audio API
          return new AudioContext({ 
            sampleRate: 8000,
            echoCancellation: true,
          })
        } 
        else {
          // Web Audio API is not supported
          // Alert the user
          return alert("Sorry, but the Web Audio API is not supported by your phone. Please, consider upgrading to the latest OS version");
        }
      },
      prepareUnmuteIosAudio: async function () {
        var silenceDataURL = "data:audio/mp3;base64,//MkxAAHiAICWABElBeKPL/RANb2w+yiT1g/gTok//lP/W/l3h8QO/OCdCqCW2Cw//MkxAQHkAIWUAhEmAQXWUOFW2dxPu//9mr60ElY5sseQ+xxesmHKtZr7bsqqX2L//MkxAgFwAYiQAhEAC2hq22d3///9FTV6tA36JdgBJoOGgc+7qvqej5Zu7/7uI9l//MkxBQHAAYi8AhEAO193vt9KGOq+6qcT7hhfN5FTInmwk8RkqKImTM55pRQHQSq//MkxBsGkgoIAABHhTACIJLf99nVI///yuW1uBqWfEu7CgNPWGpUadBmZ////4sL//MkxCMHMAH9iABEmAsKioqKigsLCwtVTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVV//MkxCkECAUYCAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
        FAKE_AUDIO_ELEMENT = document.createElement("audio");
        FAKE_AUDIO_ELEMENT.controls = false;
        FAKE_AUDIO_ELEMENT.preload = "auto";
        FAKE_AUDIO_ELEMENT.loop = false;
        FAKE_AUDIO_ELEMENT.src = silenceDataURL;
      },
      unmuteIosAudio: async function () {
        if (!FAKE_AUDIO_ELEMENT) return
        return await FAKE_AUDIO_ELEMENT.play()
        .then(function(){
          console.log("unmuteIosAudio success")
          FAKE_AUDIO_ELEMENT.pause()
        }, function(reason){
          console.log("unmuteIosAudio failed", reason)
        });
      },
      base64ToByteArray(base64) {
        const byteArray = Buffer.from(base64, 'base64');
        return byteArray;
      },
      initWebrtcBridges(bridges) {
        WEBRTC_BRIDGES = bridges
      },
      getWebrtcBridges() {
        return WEBRTC_BRIDGES || []
      },
      pinchZoom: function (target, wrapper, screen, callback) {
        let scale = 1;
        let start = {};
        let initialTransform = { x: 0, y: 0 };
        let isZooming = false
        let maxZoom = 8
        let minZoom = 0.95
        let that = this
        let dragStart = {};

        // Calculate distance between two fingers
        const distance = (event) => {
            return Math.hypot(event.touches[0].pageX - event.touches[1].pageX, event.touches[0].pageY - event.touches[1].pageY);
        };

        target.style.willChange = `transform`;
        wrapper.style.willChange = `transform`;
        target.style.transition = `transform 120ms`;

        // add handlers
        target.addEventListener('touchstart', e => touchStartHandler(e))
        target.addEventListener('touchmove', e => touchMoveHandler(e))
        target.addEventListener('touchend', e => touchEndHandler(e))
        target.addEventListener('touchcancel', e => touchEndHandler(e))

        function touchStartHandler(e) {
          if (e.touches.length === 2) {
            e.stopPropagation();
            e.preventDefault();

            // 손가락의 중앙 위치를 계산
            const touchCenterX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
            const touchCenterY = (e.touches[0].pageY + e.touches[1].pageY) / 2;

            start.x = touchCenterX;
            start.y = touchCenterY;
            start.distance = distance(e);
            start.scale = scale;
            start.transform = { ...initialTransform };
          }
          else if (e.touches.length === 1 && !isZooming) {

            dragStart.x = e.touches[0].pageX;
            dragStart.y = e.touches[0].pageY;
            dragStart.transform = { ...initialTransform };
          }
        }

        function touchMoveHandler(e) {

          if (e.touches.length === 2) {
            e.stopPropagation();
            e.preventDefault();

            isZooming = true

            const deltaDistance = distance(e);
            scale = start.scale * (deltaDistance / start.distance);
            scale = Math.min(maxZoom, Math.max(minZoom, scale));

            // scale값 전달을 위한 콜백함수 
            callback({scale, isTouchend: false});

            //imageElementScale로 나눠서 확대 시 민감도 통일
            const deltaX = (((e.touches[0].pageX + e.touches[1].pageX) / 2) - start.x) / scale;
            const deltaY = (((e.touches[0].pageY + e.touches[1].pageY) / 2) - start.y) / scale;

            const newTransformX = start.transform.x + deltaX;
            const newTransformY = start.transform.y + deltaY;

            target.style.transform = `translate3d(${newTransformX}px, ${newTransformY}px, 0)`;
            wrapper.style.transform = `scale(${scale})`

            initialTransform = { x: newTransformX, y: newTransformY };
          }
          // 확대된 후 한 손가락으로 움직일 때 동작.
          else if (e.touches.length === 1 && scale > 1) {

            const deltaX = (e.touches[0].pageX - dragStart.x) / scale;
            const deltaY = (e.touches[0].pageY - dragStart.y) / scale;

            const newTransformX = dragStart.transform.x + deltaX;
            const newTransformY = dragStart.transform.y + deltaY;

            target.style.transform = `translate3d(${newTransformX}px, ${newTransformY}px, 0) scale(1)`;
            initialTransform = { x: newTransformX, y: newTransformY };
          }
        }

        function touchEndHandler(e) {
          // e.stopPropagation();
          // e.preventDefault();

          const rect = target.getBoundingClientRect();
          const screenWidth = screen.offsetWidth;
          const screenHeight = screen.offsetHeight;
          const screenLeft = screen.offsetLeft;
          const screenRight = screen.offsetLeft + screenWidth;
          const screenTop = screen.offsetTop;
          const screenBottom = screen.offsetTop + screenHeight;

          // 이미지의 실제 높이를 확대/축소 비율로 조정하여 확대/축소된 이미지의 높이를 계산
          const scaledWidth = rect.width;
          const scaledHeight = rect.height;

          if (scale < 1.1 && isZooming) {
            that.hapticsImpactLight()
            scale = 1
            // scale값 전달을 위한 콜백함수 
            callback({scale, isTouchend: true});
          }

          // 이미지의 확대/축소된 넓이가 화면 넓이보다 작은 경우, 이미지를 화면 중앙에 위치시킵니다.
          if (scaledWidth < screenWidth) {
            initialTransform.x = 0;
          } 
          else {
            if (rect.left > screenLeft) {
              initialTransform.x = (scaledWidth - screenWidth) / (2 * scale);
            } 
            else if (rect.right < screenRight) {
              initialTransform.x = -(scaledWidth - screenWidth) / (2 * scale);
            }
          }

          // 이미지의 확대/축소된 높이가 화면 높이보다 작은 경우, 이미지를 화면 중앙에 위치시킵니다.
          if (scaledHeight < screenHeight) {
              initialTransform.y = 0;
          } 
          else {
            if (rect.top > screenTop) {
              initialTransform.y = (scaledHeight - screenHeight) / (2 * scale);
            } 
            else if (rect.bottom < screenBottom) {
              initialTransform.y = -(scaledHeight - screenHeight) / (2 * scale);
            }
          }

          target.style.transform = `translate3d(${initialTransform.x}px, ${initialTransform.y}px, 0)`;
          wrapper.style.transform = `scale(${scale})`
          isZooming = false
        }
      },
      log(msg) {
        // log in console
        console.log(msg);

        let formatter = new Intl.DateTimeFormat('default', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false // 24시간 형식
        });
        // 밀리초 수동 추가
        const now = new Date()
        const milliseconds = now.getMilliseconds().toString().padStart(3, '0');
        const formattedTime = formatter.format(now) + '.' + milliseconds;

        store.commit('SET_WEBRTC_VIDEO_LOG', `${formattedTime} ${msg}`)
      },
      isNumber(value) {
        return typeof value === 'number' && !isNaN(value) && isFinite(value);
      },
      throttle: function (func, limit) {
        let lastFunc;
        let lastRan;
        return function (...args) {
          const context = this;
          if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
          } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
              if ((Date.now() - lastRan) >= limit) {
                func.apply(context, args);
                lastRan = Date.now();
              }
            }, limit - (Date.now() - lastRan));
          }
        };
      },
    }
    Vue.prototype.$tool = tool
    Vue.tool = tool
  }
}

export default ToolPlugin