import Vue from 'vue'
import api from '@/apis/siteDealer'
import parser from '@/models/siteDealer'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_SITE_DEALER_REQUEST,
  GET_SITE_DEALER_SUCCESS,
  GET_SITE_DEALER_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  siteId: null,
  dealer: null,
  status: {
    getSiteDealer: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  siteDealer: function (state) {
    return state.dealer
  },
  statusGetSiteDealer: function (state) {
    return state.status.getSiteDealer
  },
}

// actions
const actions = {
  getSiteDealer: function ({commit}, {siteId}) {
    commit(GET_SITE_DEALER_REQUEST, {siteId})
    return new Promise((resolve, reject) => {
      api.getSiteDealer({siteId}).then(
        res => {
          let data = res.body[0]
          commit(GET_SITE_DEALER_SUCCESS, {data})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_DEALER_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_DEALER_REQUEST]: function (state, {siteId}) {
    // RESET_STATE - site changed
    if (state.siteId && state.siteId !== siteId) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.status.getSiteDealer = "requested"
  },
  [GET_SITE_DEALER_SUCCESS]: function (state, {data}) {
    if (!(state.dealer instanceof Object)) state.dealer = {}
    const dealerInfo = parser.parseDealerData(data)
    if (dealerInfo.dealerId) {
      state.dealer = dealerInfo
    }
    state.status.getSiteDealer = "successful"
  },
  [GET_SITE_DEALER_FAILURE]: function (state) {
    state.status.getSiteDealer = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
