import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  // CAMERA info
  getCameraCapabilitiesFromProxy: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 
    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/capabilities`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  getCameraStreamCapabilitiesFromProxy: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 
    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/stream/capabilities`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },

  // ONVIF COMMON
  // 1. onvif로 접근할 수 있는 기능들의 리스트 : ptz_supported, audio etc.
  getCameraOnvifProfilesFromProxy: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 
    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/profiles?last=true`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  // 사용 가능한 ONVIF topic list
  getOnvifRequestListFromProxy: function ({siteId, bridgeId}) {
    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/onvif/list_requests`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  // onvif request template
  getOnvifTemplateFromProxy: function ({siteId, bridgeId, templateName}) {
    if (!templateName) templateName = 'SetRelayOutputSettings'
    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/onvif/request_template?request=${templateName}&xml=yes`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },

  // others
  getOnvifCapabilitiesFromProxy: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 
    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/capabilities`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  /** response example
   {
      "errors": null,
      "data": {
          "video_sources": 1,
          "audio_sources": 1,
          "audio_outputs": 1,
          "digital_inputs": 1,
          "relay_outputs": 1,
          "recording": true,
          "ptz_nodes": 0,
          "max_pull_points": 10,
          "max_notification_producers": 10
      }
    }
   */
}