import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  // USE 1 - Get only older events             - targetEventId={{firstEventId}}
  // USE 2 - Get only newer events             - targetEventId={{lastEventId}}, toGetNewEvents=true
  // USE 3 - Get events with time (only older) - targetEventId={{firstEventId}}, stime={{start time}}, etime={{end time}}
  // USE 4 - Get events with time              - stime={{start time}}, etime={{end time}}
  getSiteEvents: function ({siteId, deviceId, stime, etime, eventTypes, eventStatus, targetEventId, toGetNewEvents}) {
    // SET - stime, etime
    if (!stime) stime = 0
    if (!etime) etime = 0
    let apiDetailPath = this.apiPrefix + `/${siteId}/events/stime/${stime}/etime/${etime}`
    // OPTION 1 - limit total event to 50
    let query = `?limit=50`
    // OPTION 2 - category
    if (eventTypes) query += `&filter=device_event.type,${eventTypes}`
    // OPTION 3 - deviceId
    if (deviceId) query += `&filter=device_event.device_id,${deviceId},eq`
    // OPTION 4 - to retrieve ONLY events not yet retrieved
    if (targetEventId) {
      if (toGetNewEvents) {
        query += '&dir=asc&sort=event_id'
        query += `&filter=device_event.event_id,${targetEventId},gt`
      }
      else {
        query += '&dir=desc&sort=event_id'
        query += `&filter=device_event.event_id,${targetEventId},lt`
      }
    }
    else {
      query += '&dir=desc&sort=event_id'
    }
    // OPTION 5 - to retrieve ONLY certain event types [di|netloss|...]
    // if (eventTypes instanceof Array) eventTypes.map(eventType => { query += `&filter=device_event.type,${eventType},eq` })
    // OPTION 6 - to retrieve ONLY certain event status [active|inactive]
    if (eventStatus) query += `&filter=device_event.status,${eventStatus},eq`
    // ACTION
    return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  },
  getSiteEvent: function ({siteId, eventId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/events/${eventId}`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
}
