const baseRoutes = [
  {
    path: '/settings/agreement',
    component: () => import("@/_routes/settings/agreement/Root"),
  },
  {
    path: '/settings/agreement/toc',
    component: () => import("@/_routes/settings/agreement/TermsOfContition")
  },
]

const routes = baseRoutes

export default routes