import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getSiteMembers: function ({siteId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/members`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getSiteMember: function ({siteId, memberId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/members/${memberId}`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  createSiteMember: function ({siteId, email, role = 2, firstName, lastName}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/members`
    let data = {
      email: email,
      role: role, // [0:OWNER,1:ADMIN,2:MANAGER]
      firstname: firstName,
      lastname: lastName,
    }
    return Vue.http.post(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  updateSiteMember: function ({siteId, memberId, role}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/members/${memberId}`
    let data = {
      role: role, // [0:OWNER,1:ADMIN,2:MANAGER]
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  deleteSiteMember: function ({siteId, memberId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/members/${memberId}`
    return Vue.http.delete(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  sendInviteEmailToSiteMember: function ({siteId, memberId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/members/${memberId}/invite`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getMemberRoles: function () {
    let apiDetailPath = this.apiPrefix + `/member_roles`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
}
