export default {
  parseSiteData: function (data) {
    return {
      siteId: data.site_id,
      siteName: data.name,
      armingSource: data.arming_source, // 1: Master Bridge Arming || 2: Individual Bridge Arming || 3: Site Based Arming
      blueprintUrl: data.blueprint_url,
      locationInfo: {
        address: (data.address2 ? data.address2 + ' ' : '') + data.address1,
        timezone: data.timezone ? data.timezone : 'UTC',
        timezoneOffset: data.timezone_offset,
        lat: data.lat,
        lng: data.lng
      },
      dealerInfo: {
        dealerId: data.dealer_id,
      },
      verificationInfo: {
        verificationDuration: data.verification_duration,
      },
      accountInfo: {
        prefix: data.prefix, // account number package
        prefixAccountNumber: data.prefix_account_number, // account number package
        customerAccountNumber: data.customer_account_number, // account number package
        receiverLinePrefix: data.receiver_line_prefix, // account number package
        partitionNumber: data.partition_number,
        virtualZone: data.virtual_zone,
      },
      statusInfo: {
        isDeleted: !!data.is_deleted,
        isMonitoringBlocked: !!data.is_monitoring_block,
        createdDate: data.created ? new Date(data.created) : null,
        deletedDate: data.deleted_time ? new Date(data.deleted_time) : null,
        showArmingNotification: !!data.show_arming_config_enduser_app,
        showEventNotification: !!data.show_event_config_enduser_app,
        showBypassNotification: !!data.show_bypass_config_enduser_app,
        showDeviceConnectionNotification: !!data.show_device_connection_config_enduser_app,
        showCameraRelays: !!data.show_camera_relays_config_enduser_app,
        showTalkdown: !!data.show_talkdown_config_enduser_app,
        armingScheduleEnabled: !!data.arming_schedule_enabled
      },
      planInfo: {
        eventPlanCount: data.event_plan_count,
        isEventEnabled: data.is_event_enabled,
        isLiveEnabled: data.is_live_enabled,
        isPlaybackEnabled: data.is_playback_enabled,
        isTTSEnabled: !!data.is_speaker_enabled,
        isVpnEnabled: data.is_vpn_enabled,
        currServicePlanType: data.service_plan_info?.curr_service_plan_type,
        nextServicePlanType: data.service_plan_info?.next_service_plan_type,
        videoStorageRetentionPeriod: data.service_plan_info?.video_storage_retention_period,
      },
      statistics: {
        totalDevice: data.device_count ? data.device_count : 0,
        totalBridge: data.bridge_count ? data.bridge_count : 0,
        totalCamera: data.camera_count ? data.camera_count : 0,
        totalUser: data.user_count ? data.user_count : 0
      }
    }
  },
}
