import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getSites: function () {
    let apiDetailPath = this.apiPrefix
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getSite: function ({siteId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
}
