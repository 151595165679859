import Vue from 'vue'
import {
  RESET_STATE,
  REQUEST_CHECK_PERMISSION,
} from '@/store/mutation-types'

let initialState = {
  checkPermissionRequest: 0, // just increase counter to notify StoreUpdateManager
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  checkPermissionRequest: function (state) {
    return state.checkPermissionRequest
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [REQUEST_CHECK_PERMISSION]: function (state) {
    state.checkPermissionRequest++
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
