<template>
  <div>

    <ion-content>

      <!-- header -->
      <div class="header">
        <div class="title">Terms of Service</div>
        <div class="sub-title">Please agree to the Terms of Service</div>
      </div>

      <!-- content -->
      <div class="content">
        <div v-on:click="clickTos()" class="item">
          <div class="left">
            <ion-checkbox :checked="!isNotReadAgreement" style="pointer-events: none;"></ion-checkbox>
            <div class="item-title">Terms and Conditions</div>
          </div>
          <div class="right">
            <ion-icon slot="icon-only" name="chevron-forward"></ion-icon>
          </div> 
        </div>
        <div class="general-info">
          Carefully read and understand the CHeKT Terms and Conditions described in the Terms and Conditions agreement above.
        </div>
        <div class="point-info">
          *Terms and Conditions must be agreed to before the services can be used.
        </div>
      </div>

      <!-- button -->
      <ion-button v-on:click="onClickAgree()" :disabled="isNotReadAgreement" class="button">
        Agree and Continue
      </ion-button >


    </ion-content>

  </div>
</template>

<script>
import { modalController } from '@modus/ionic-vue'
import AgreementTocModal from '@/components/modals/AgreementToc'
export default {
  props: {
    store: { type: Object, default: function () { return {} } },
  },
  computed: {
    lastAgreementTosId: function () {
      return this.store.getters.lastAgreementTosId
    },
  },
  data: function () {
    return {
      isNotReadAgreement: true
    }
  },
  watch: {
  },
  created: function () {
    // Listen Emit Data
    this.$on('confirm', (clickedConfirm) => {
      this.isNotReadAgreement = clickedConfirm
    })
  },
  methods: {
    onClickClose: function () {
      let modals = document.getElementsByTagName("ion-modal")
      if (modals.length < 1) {
        return
      }
      modals[modals.length - 1].dismiss()
    },
    clickTos: function () {
      this.showAgreementTocModal()
    },
    showAgreementTocModal: function () {
      modalController.create({
        component: AgreementTocModal,
        // presentingElement: document.querySelector('ion-router-outlet'),
        componentProps: {
          propsData: {
            store: this.store,
          },
          parent: this,
        },
      }).then(m => m.present())
    },
    onClickAgree: async function () {
      try { 
        // API - Update Agreement to agree
        await this.store.dispatch("updateAgreement", this.lastAgreementTosId)
        this.onClickClose()
      } catch (err) {
        alert("The agreement cannot be updated. please try again later")
      }
    },
  }
}
</script>

<style scoped>
ion-content{
  --ion-background-color:#ffffff;
}
.header {
  margin-top: calc(50px + var(--ion-safe-area-top));
  text-align: center;
}
.header .title {
  font-size: 20px;
  font-weight: 500;
}
.header .sub-title {
  margin-top: 5px;
  font-size: 14px;
  color: var(--ion-color-blue-gray-higher);
}

.content {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}

.item {
  border: solid 1px var(--ion-border-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding: 14px 10px;
  border-radius: 10px;
}
.item .left{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.item .right{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.item-title {
  color: var(--ion-color-blue-gray-higher);
  font-weight: 500;
}

.general-info, .point-info {
  font-size: 12px;
  color: var(--ion-color-blue-gray-higher);
}
.general-info {
  margin-top: 10px;
}
.point-info {
  margin-top: 5px;
}


.button {
  position: absolute;
  bottom: 0;
  background-color: var(--ion-color-primary);
  color: white;
  text-align: center;
  width: calc(100% - 40px);
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: calc(20px + var(--ion-safe-area-bottom));
}

</style>
