import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getSiteAllRelayInfo: function ({siteId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/relays`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getSiteRelay: function ({siteId, relayId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/relays/${relayId}`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getSiteAllRelayStatus: function ({siteId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/relays/status`
    let query = `?timeout=3`
    query += `&online=true`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  },
  controlSiteRelay: function ({siteId, relayId, requestedStatus, relayDelayTimes, relayWithSnapshot, relayMode}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/relays/${relayId}`
    let data = {
      status: requestedStatus,
      delay_times: relayDelayTimes,
      with_snapshot:relayWithSnapshot,
      mode: relayMode,
     }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
}
