import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getSiteArmingStatus: function ({siteId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/securities/status`
    let query = `?from=local&timeout=1&sync=1`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  },
  updateSiteArmingStatus: function ({siteId, armingMode}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/arming`
    let data = {
      arming_type: armingMode //? 1 : 2 // [1:ALWAYS ARMED,2:ALWAYS DISARMED]
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
}