import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';

import { FCM } from "@capacitor-community/fcm"

const PushPlugin = {
  install (Vue) {
    let push = {
      token: '',
      fcmToken: '',
      topics: [],
      removeAllListeners: null,
      initiated: false,
      init: async function (userId, f1, f2, f3, f4, f5) {
        if (Vue.env.deviceInfo.platform == 'web') return
        if (!this.initiated) {
          // On success, we should be able to receive notifications
          PushNotifications.addListener('registration', (token) => {
            this.token = token.value
            // alert('Push registration success, token: ' + token.value)
            let topic = ""
            if (Vue.env.isProduction) {
              topic = `env.prod.users.${userId}`
            } else {
              topic = `env.dev.users.${userId}`
            }
            this.subscribeTo(topic)
          })

          // Some issue with our setup and push will not work
          PushNotifications.addListener('registrationError', (error) => {
            alert('Error on registration: ' + Vue.tool.parseToStringify(error))
          })

          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener('pushNotificationReceived', f1)

          // Show us the notification payload if the app is open on our device
          document.addEventListener('pushNotificationReceived', f2)

          // Method called when tapping on a notification
          PushNotifications.addListener('pushNotificationActionPerformed', f3)

          // LocalNotifications
          LocalNotifications.addListener('localNotificationReceived', f4)

          // LocalNotifications
          LocalNotifications.addListener('localNotificationActionPerformed', f5)

          // PREPARE - removeAllListeners
          this.removeAllListeners = function() {
            document.removeEventListener('pushNotificationReceived', f2)
            PushNotifications.removeAllListeners()
            LocalNotifications.removeAllListeners()
          }
        }
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android ~12 will just grant without prompting
        // Android 13~ will prompt user and return if they granted permission or not
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!');
        }

        PushNotifications.register().then(() => { 
        }).catch(err => Vue.tool.presentToast('Can not register push notification' + err))
        // .then( result => {
        //   if (result.granted) {
        //     // Register with Apple / Google to receive push via APNS/FCM
        //     PushNotifications.register().then(() => {
        //     }).catch(err => Vue.tool.presentToast('An unexpected error has occurred' + Vue.tool.parseToStringify(err)))
        //   }
        // })

        this.initiated = true
      },
      getDeliveredNotifications: function () {
        return PushNotifications.getDeliveredNotifications()
      },
      subscribeTo: function (topic) {
        // console.log(`subscribing to topic: ${topic}`)
        if (!topic) {
          // alert(`ERR: subscribed to topic: no topic: ${topic}`)
          return
        } else {
          // alert(`subscribing to topic: ${topic}`)
        }
        // alert('ERR: subscribeTo: ' + JSON.stringify(this.fcm))
        // console.log(this.fcm)
        // console.log(fcm)
        FCM
        .subscribeTo({ topic: topic })
        .then(() => {
          if (this.topics.indexOf(topic) < 0) {
            this.topics.push(topic)
            // alert(`SUCCESS: subscribed to topic: new topic: ${topic}`)
          } else {
            // alert(`SUCCESS: subscribed to topic: existing topic: ${topic}`)
          }
        })
        .catch(err => console.log('ERR: subscribeTo: ' + Vue.tool.parseToStringify(err)))
      },
      unsubscribeFrom: function (topic) {
        FCM
        .unsubscribeFrom({ topic: topic })
        .then(() => {
          if (this.topics.indexOf(topic) > -1) this.topics = this.topics.splice(this.topics.indexOf(topic), 1)
          alert(`unsubscribed from topic: ${topic}`)
        })
        .catch(err => alert('ERR: unsubscribeFrom: ' + Vue.tool.parseToStringify(err)))
      },
      getFCMToken: function () {
        FCM
        .getToken()
        .then(r => {
          alert(`Token ${r.token}`)
          this.fcmToken = r.token
        })
        .catch(err => alert('ERR: getFCMToken: ' + Vue.tool.parseToStringify(err)))
      },
      deleteFCMInstance: function () {
        FCM
        .deleteInstance()
        .then(() => {
          this.topics = []
          this.initiated = false
          if (this.removeAllListeners) {
            this.removeAllListeners()
            this.removeAllListeners = null
          }
          // alert(`FCM Instance deleted`) // *** for testing ***
        })
        .catch(err => alert('ERR: deleteFCMInstance: ' + Vue.tool.parseToStringify(err)))
      },
      createLocalNotification: async function (notification) {
        if (Vue.env.deviceInfo.platform != 'android') return
        // const notifs = await LocalNotifications.schedule({
        await LocalNotifications.schedule({
          notifications: [
            {
              title: notification.title,
              body: notification.body,
              eventType: notification.data.event_type,
              eventStatus: notification.data.event_status,
              deviceId: notification.data.deviceId,
              eventId: notification.data.eventId,
              siteId: notification.data.siteId,
              created: notification.data.created,
              id: 1,
              schedule: { at: new Date(Date.now() + 1000 * 1) },
              sound: null,
              // Android-only: set a custom statusbar icon 
              // smallIcon: "res://ic_launcher",
              attachments: null,
              appIcon:null,
              actionTypeId: "",
              extra: null
            }
          ]
        })
        // alert('scheduled notifications', notifs)
      },
    }
    Vue.prototype.$push = push
    Vue.push = push
  }
}

export default PushPlugin

